import React from "react";
import threeDot from "../img/Frame 179.png";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { useState } from "react";

const DashboardBooks = () => {
  const books = useSelector((state) => state.book.books);
  const [searchedQuery, setsearchedQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [bookPerPage, setBookPerPage] = useState(10);
  const pageVisited = pageNumber * bookPerPage;

  const pageCount = Math.ceil(books.length / bookPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayBook = books.slice(pageVisited, pageVisited + bookPerPage).map((book) => {
      return (
        <tr key={book._id}>
          <td>
            <div className="">
              {/* <img src={img} alt="" className="table-img-set" /> */}
              <img
                src={
                  book.cover
                    ? `${process.env.REACT_APP_BASE_URL}/api/alluploads/${book.publisherId}/${book.cover}`
                    : `${process.env.REACT_APP_BASE_URL}/api/alluploads/default-book.png`
                }
                alt=""
                className="table-img-set"
              />
              <span className="table-book-name ms-2">{book.nameInEnglish}</span>
            </div>
          </td>

          <td>
            {" "}
            <p className="mt-2">{book.authorEnName}</p>{" "}
          </td>

          <td>
            {" "}
            <p className="mt-2">{Math.ceil(book.salePrice)}</p>{" "}
          </td>
        </tr>
      );
    });

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between">
        <p className="fs-5 fw-bold"> Books </p>
        <div className="d-flex justify-content-between box-text-color-set">
          <p> view </p>

          <select
            class="form-select w-75 py-0 ms-2 me-2 box-text-color-set"
            aria-label="Default select example"
          >
            <option selected> Sale </option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>

          <button className="three-dot-button">
            <img src={threeDot} alt="" />
          </button>
        </div>
      </div>

      {/* table */}

      <div className="mt-3">
        <table class="table">
          <thead className="">
            <tr>
              <th scope="col">Book</th>
              <th scope="col">Author</th>
              <th scope="col">Sales</th>
            </tr>
          </thead>

          <tbody className="table-text-set">{displayBook}</tbody>
        </table>
      </div>
      <div className="">
      <ReactPaginate
        previousLabel={"Prev"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"pagination"}
        activeClassName={"actvie-page"}
      />
      </div>
    </div>
  );

};

export default DashboardBooks;
