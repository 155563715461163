import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAlbum, getPublishers, loadMedia, updatePublisher } from "../util/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilePdf,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import ModalImage from "react-modal-image";
import Album from "./Album";
import { Pdf } from "./pdf";
import { Image } from "./Image";
import PublishersAlbum from "./PublishersAlbum";
import AuthorsAlbum from "./AuthorsAlbum";
// import PDFViewer from 'pdf-viewer-reactjs'
// import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function MediaLibrary({ uploadImg, publisher, publisherPhotos, authorsPhotos }) {
  const [loader, setLoader] = useState(false);
  const [uploader, setuploader] = useState(false);
  let files = [];
  const dispatch = useDispatch();
  let images = [];
  let library = {};
  const [urls, setUrls] = useState([]);
  const [progress, setProgress] = useState(0);
  const [upImg, setupImg] = useState(publisher === null? true : false);
  const [mediaLibrary, setmediaLibrary] = useState((publisher || publisherPhotos || authorsPhotos) !== undefined? false : true);
  const [albums, setalbums] = useState(publisher === undefined? false : true);
  const [publisherPhoto, setpublisherPhoto] = useState(publisherPhotos === undefined? false : true);
  const [authorsPhoto, setauthorsPhoto] = useState(authorsPhotos === undefined? false : true);
  const [sortedMedia, setSorting] = useState([]);
  const [open, setOpen] = useState(false);
  const [isAlbumOpen, setisAlbumOpen] = useState(false)
  const [isPublisherFound, setisPublisherFound] = useState(false)

  const media = useSelector((state) => state.media.allmedia);
  const user = useSelector((state) => state.userInfo.currentUser);
  const publishersAlbum = useSelector((state) => state.publisher.publishers);

  

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
    loadMedia(dispatch);
  }, [])

  const handleUpimg = () => {
    setupImg(true);
    setmediaLibrary(false);
    setpublisherPhoto(false)
    setalbums(false);
    setauthorsPhoto(false)
  };
  const handleMediaLibrary = () => {
    setupImg(false);
    setmediaLibrary(true);
    setpublisherPhoto(false)
    setauthorsPhoto(false)
    setalbums(false);
  };
  const handleAlbum = () => {
    setupImg(false);
    setmediaLibrary(false);
    setpublisherPhoto(false)
    setauthorsPhoto(false)
    setalbums(true);
  };
  const handlePublisherPhotos = () => {
    setupImg(false);
    setmediaLibrary(false);
    setalbums(false);
    setpublisherPhoto(true)
    setauthorsPhoto(false)
  };
  const handleAuthorPhotos = () => {
    setupImg(false);
    setmediaLibrary(false);
    setalbums(false);
    setpublisherPhoto(false);
    setauthorsPhoto(true)
  };


  const handleChange = (e) => {
    setuploader(true)
    files = e.target.files;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      library = { fileName: files[i].name, fileType: files[i].type };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/media/add`, library)
        .then(() => {
          console.log("success");
        })
        .catch((e) => {
          console.log("error", e);
        });
      setUrls((prevState) => [...prevState, library.fileName]);
      data.append("file", files[i]);
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/uploads`, data)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => {
        loadMedia(dispatch) 
        setuploader(false)
      });
  };

  const handleUpload = (fileName) => {
    // const imgUrl = `${process.env.REACT_APP_BASE_URL}/api/alluploads/${fileName}`;
    uploadImg(fileName);
  };

  const handleDelete = (imagename) => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/media/${imagename._id}`)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      });

    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/deletemedia/${imagename.fileName}`)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => loadMedia(dispatch));
  };

  const handleClose = () => {
    setOpen(true);
  };

  return (
    <div className="media-page">
      <div className="row">
        <div className="col-lg-2 col-md-3">
          <div className="media-left">
            <h1>Insert Media</h1>
            <ul>
              <li>Create gallery</li>
              <li>Set featured Image</li>
              <li>Insert from url</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-10 col-md-9">
          <div className="media-right">
            <ul className="upload-media-btns">
              <li
                className={upImg ? "active-upload-btn" : ""}
                onClick={handleUpimg}
              >
                Upload Files
              </li>
              <li
                className={mediaLibrary ? "active-upload-btn" : ""}
                onClick={handleMediaLibrary}
              >
                Media Library
              </li>
              <li
                className={albums ? "active-upload-btn" : ""}
                onClick={handleAlbum}
              >
                Albums
              </li>
              <li
                className={publisherPhoto ? "active-upload-btn" : ""}
                onClick={handlePublisherPhotos}
              >
                Publisher Albums
              </li>
              <li
                className={authorsPhoto ? "active-upload-btn" : ""}
                onClick={handleAuthorPhotos}
              >
                Authors Albums
              </li>
            </ul>
            <div
              className="upload-media-body"
              style={upImg ? { display: "block" } : { display: "none" }}
            >
              {/* {urls.length !== 0 ? (
                <div className="image-gallery gallery-2">
                  {urls.map((url) => {
                    return (
                        <div className="single-upload-img">
                            <img src={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${url}`} key={Math.random} alt={url} />
                        </div>
                    )
                  })}
                </div>
              ) : (
                ""
              )} */}
              {uploader?
                <p style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",fontWeight:"600",fontSize:"30px"}}>Uploading...</p>
              :
              <form>
                <h1>Drop files anywhere to upload</h1>
                <p>Or</p>
                <input type="file" multiple onChange={handleChange} />
                <a>Select Files</a>
              </form>
              }
            </div>
            <div
              className="upload-media-body"
              style={mediaLibrary ? { display: "block" } : { display: "none" }}
            >
              {loader?
                <BeatLoader color={"#0D99FF"} loading={loader} size={15}/>
                :
                <div className="image-gallery">
                  {media.map((data)=>{
                      return(
                        <>
                          {
                            data.fileType === "application/pdf"? 
                            <Pdf data={data} handleDelete={handleDelete} handleUpload={handleUpload}/> 
                            : data.entityId === undefined && data.fileFor !== 'publishersPhoto' && data.fileFor !== 'authorsPhoto'?
                            <Image data={data} handleDelete={handleDelete} handleUpload={handleUpload}/>
                            :
                            ""
                          }
                        </>
                      )
                  })}
                </div>
              }
            </div>
            <div
              className="upload-media-body"
              style={albums ? { display: "block" } : { display: "none" }}
            >
              <div className="image-gallery">
                {publishersAlbum.map((folder) => {
                  return <Album key={folder._id} folder={folder} uploadImg={uploadImg} publisherId={publisher}/>
                })}
              </div>
            </div>
            <div
              className="upload-media-body"
              style={publisherPhoto ? { display: "block" } : { display: "none" }}
            >
                <PublishersAlbum uploadImg={uploadImg}/>
            </div>
            <div
              className="upload-media-body"
              style={authorsPhoto ? { display: "block" } : { display: "none" }}
            >
                <AuthorsAlbum uploadImg={uploadImg}/>
            </div>
            {/* <div className="upload-media-footer">
              <form>
                <div className="upload-btn-group">
                  <button className="up-btn" onClick={() => handleUpload()}>
                    Insert Image
                  </button>
                </div>
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
