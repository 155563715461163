import { createSlice } from "@reduxjs/toolkit";

export const adsSlice = createSlice({
  name: "ads",
  initialState: {
    ads: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getadStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getadSuccess: (state, action) => {
      state.isFetching = false;
      state.ads = action.payload;
    },
    getadFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addadStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addadSuccess: (state, action) => {
      state.isFetching = false;
      state.ads.unshift(action.payload);
    },
    addadFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updateadStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateadSuccess: (state, action) => {
      state.isFetching = false;
      state.ads[
        state.ads.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateAd;
    },
    updateadFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deleteadStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteadSuccess: (state, action) => {
      state.isFetching = false;
      state.ads.splice(
        state.ads.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteadFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { getadStart, getadSuccess, getadFailur, addadStart, addadSuccess, addadFailur, updateadStart, updateadSuccess, updateadFailure,deleteadStart, deleteadSuccess, deleteadFailure } =
  adsSlice.actions;

export default adsSlice.reducer;
