import React from 'react'
import ReactPaginate from "react-paginate"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes} from "@fortawesome/free-solid-svg-icons"
import { useState } from "react";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios'
import {useSelector} from 'react-redux'

export default function Subscribers() {
    const [subscribers, setsubscribers] = useState([])
    const [searchedQuery, setsearchedQuery] = useState("")
    const [pageNumber, setPageNumber] = useState(0);
    const [subscriberPerPage, setsubscriberPerPage] = useState(10);
    const pageVisited = pageNumber * subscriberPerPage;
    const currentUser = useSelector((state) => state.userInfo.currentUser)
    const handleSearch = (e) =>{
        setsearchedQuery(e.target.value)
    }
    const handleSorting = () =>{

    }

    const pageCount = Math.ceil(subscribers.length / subscriberPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    const getSubscribers = async () => {
        await axios({method:'get', url:`${process.env.REACT_APP_BASE_URL}/api/subscribe`, headers:{'token': `Bearer ${currentUser?.accessToken}`}}).then((res)=>{
            setsubscribers(res.data)
        })
    }

    useEffect(() => {
        getSubscribers()
    }, [])

    const displaySubscriber = subscribers.filter(subscibe=> {
        if (searchedQuery === "") {
          return subscibe
        }
        else if (subscibe.email?.toLowerCase().includes(searchedQuery.toLowerCase())) {
          return subscibe
        }
      }).slice(pageVisited, pageVisited + subscriberPerPage)
        .map((subscibe) => {
          return (
            <div className="table-row">
                <li>
                    {subscibe.email}
                </li>
            </div>
          );
    });



    return (
        <div className="content-area-inner list-page">
            <div className="container-fluid">
            <div className="breadcumb">
                <Link to="/">Dashboard</Link>
                <FontAwesomeIcon icon={faAngleRight} />
                <Link to="/">Subscribers</Link>
            </div>
            <div className="heading">
                <h1>Subsciber List</h1>
            </div>
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="tab-btns">
                            <div className="tab-btn active">All Items </div>
                            <div className="tab-btn">Drafts (20)</div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                    </div>
                </div>
                <div className="tab-content">
                    <div className="tab-wrap">
                        <div className="theme-card">
                            <div className="theme-card-header">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="searchbar">
                                            <div className="form-group">
                                                <input type="text" placeholder="Search" onChange={handleSearch}/>
                                                <button type="submit" className="btn">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4523 11.4716H12.6623L12.3823 11.2016C13.5823 9.80157 14.2023 7.89157 13.8623 5.86157C13.3923 3.08157 11.0723 0.861573 8.27229 0.521573C4.04229 0.00157288 0.482286 3.56157 1.00229 7.79157C1.34229 10.5916 3.56229 12.9116 6.34229 13.3816C8.37229 13.7216 10.2823 13.1016 11.6823 11.9016L11.9523 12.1816V12.9716L16.2023 17.2216C16.6123 17.6316 17.2823 17.6316 17.6923 17.2216C18.1023 16.8116 18.1023 16.1416 17.6923 15.7316L13.4523 11.4716ZM7.45229 11.4716C4.96229 11.4716 2.95229 9.46157 2.95229 6.97157C2.95229 4.48157 4.96229 2.47157 7.45229 2.47157C9.94229 2.47157 11.9523 4.48157 11.9523 6.97157C11.9523 9.46157 9.94229 11.4716 7.45229 11.4716Z" fill="#1D1D1D"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="list-filter d-flex justify-content-end">
                                            <div className="form-group">
                                                <select className="form-control" onChange={handleSorting}>
                                                    <option value="all">Filter</option>
                                                    <option value="0">Published</option>
                                                    <option value="1">Unpublished</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <select className="form-control" onChange={(e)=>setsubscriberPerPage(e.target.value)}>
                                                    <option value="10">Subscriber per page</option>
                                                    <option value="20">20 per page</option>
                                                    <option value="30">30 per page</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="theme-card-body">
                                <div className="table slider-table">
                                    <ul className="custom-table-header">
                                        <li>Email</li>
                                    </ul>
                                    <ul className="custom-table-body">
                                        {displaySubscriber}
                                    </ul>
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"pagination"}
                                        activeClassName={"actvie-page"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-wrap">
                        {/* <div className="theme-card">
                            Draft
                        </div> */}
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
