import React, { useState } from "react";
// import ApexCharts from 'apexcharts'
// import ReactApexChart from "react-apexcharts";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdmin,
  getAds,
  getAuthor,
  getBlog,
  getBook,
  getCategories,
  getCollection,
  getDraftBooks,
  getMenu,
  getPublishers,
  getSlider,
  getTags,
  loadMedia,
} from "../util/api";
import { updateNotificationSuccess } from "../redux/notificationRedux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import rightArrow from "../img/right-arrow.png";
import threeDot from "../img/Frame 179.png";
import img from "../img/table-product.png";
import publisherLogo from "../img/Random Symboles 0.png";
import {
  LineChart,
  Line,
  ComposedChart,
  BarChart,
  Bar,
  ResponsiveContainer,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Chart from 'react-apexcharts';
import DashboardRevenue from "./DashboardRevenue";
import DashboardPeiChart from "./DashboardPeiChart";
import DashboardLatestOrders from "./DashboardLatestOrders";
import DashboardTopCustomers from "./DashboardTopCustomers";
import DashboardBooks from "./DashboardBooks";
import DashboardBestsellerAuthor from "./DashboardBestsellerAuthor";
import DashboardBestsellerPublisher from "./DashboardBestsellerPublisher";
import { getOrders } from "../util/api";


// import chartImg from "../img/chart-img.png";
// import negativeChartImg from "../img/negative-chart.png";

export default function Dashboard() {
  const dispatch = useDispatch();
  let notification = useSelector((state) => state.notification.notification);
  let user = useSelector((state) => state.userInfo.currentUser);
  const [loginStatus, setloginStatus] = useState(notification.reload);
  const [orders, setorders] = useState([]);
  // const [totalsel , setTotalsel] = useState(Math.ceil(orders.amount))
  // const totalseleShow = parseInt(totalsel) + 1
  // console.log(totalseleShow)

  
  useEffect(() => {
    getOrders().then((res) => {
      setorders(res.data);
    });
  }, []);


  useEffect(() => {
    setTimeout(() => {
      dispatch(updateNotificationSuccess({ sms: "", status: null }));
    }, 2000);
    getBook(dispatch);
    getDraftBooks(dispatch);
    getPublishers(dispatch);
    getAuthor(dispatch);
    getCategories(dispatch);
    loadMedia(dispatch);
    getBlog(dispatch);
    getAdmin(dispatch);
    getSlider(dispatch);
    getTags(dispatch);
    getMenu(dispatch);
    getAds(dispatch);
    getCollection(dispatch);
  }, []);

  // const ads = useSelector((state)=>state.ads.ads);
  // console.log(ads);

  // tiny chart
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];


  return (

    <div className="me-5">

      {notification.status === true ? (
        <div className="notification-bar success">
          <FontAwesomeIcon icon={faCheck} />
          <p>{notification.sms}</p>
        </div>
      ) : notification.status === false ? (
        <div className="notification-bar failed">
          <FontAwesomeIcon icon={faTimes} />
          <p>{notification.sms}</p>
        </div>
      ) : (
        ""
      )}
      {/* {loginStatus === false? (
            <div className="accessModal">
                <div className="accessModalInner">
                    <div className="accessInfo">
                        <h1>Welcome {user?.username}</h1>
                        <p>You successfully loged In</p>
                        <FontAwesomeIcon icon={faCheck} onClick={loginSuccess}/>
                    </div>
                </div>
            </div>
        ) : (
            ""
        )
        } */}

      <div className="dashboard-header-section">

        <p className="dashboard-nav-text mb-2">
          Dashboard
          <span className="ms-2">
            <img src={rightArrow} alt="" />
          </span>
        </p>

        <div className="d-flex justify-content-between">
          <p className="fw-bold fs-2"> Dashboard </p>
          <button className="report-button px-3 py-0 fw-bold">
            Create Reports
          </button>
        </div>
        
      </div>

      {/* header total Sales Order Users Profit show section */}

      <section className="my-5">
        <div className="d-flex justify-content-between">
          <div className="w-100 border me-4">
            <section className="p-2">
              <div className="dashboard-nav-text d-flex justify-content-between">
                <p>Total Sales</p>
                <button className="three-dot-button">
                  <img src={threeDot} alt="" />
                </button>
              </div>
              <div>
                <h5 className="fw-bold my-2">৳12,817.00</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="text-set-for-parseIn-full-line">
                    <span className="parseIn-count-text">+28%</span> from last
                    week
                  </p>

                  {/* <p className="text-set-for-parseIn-full-line"> 

                  <span className="parseIn-count-text-negative">+28 %</span>  from last week</p> */}

                  <div className="chart-div">
                    <LineChart width={100} height={30} data={data}>
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#0D99FF"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="w-100 border me-4">
            <section className="p-2">
              <div className="dashboard-nav-text d-flex justify-content-between">
                <p>Total Order</p>

                <button className="three-dot-button">
                  <img src={threeDot} alt="" />
                </button>
              </div>

              <div>
                <h5 className="fw-bold my-2">{orders.length}</h5>

                <div className="d-flex justify-content-between align-items-center">
                  <p className="text-set-for-parseIn-full-line">
                    <span className="parseIn-count-text">+28%</span> from last
                    week
                  </p>

                  {/* <p className="text-set-for-parseIn-full-line"> 

     <span className="parseIn-count-text-negative">+28 %</span>  from last week</p> */}

                  <div className="chart-div">
                    <LineChart width={100} height={30} data={data}>
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#0D99FF"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="w-100 border me-4">
            <section className="p-2">
              <div className="dashboard-nav-text d-flex justify-content-between">
                <p>Total Users</p>

                <button className="three-dot-button">
                  <img src={threeDot} alt="" />
                </button>
              </div>

              <div>
                <h5 className="fw-bold my-2">50,000</h5>

                <div className="d-flex justify-content-between align-items-center">
                  <p className="text-set-for-parseIn-full-line">
                    <span className="parseIn-count-text">+28%</span> from last
                    week
                  </p>

                  {/* <p className="text-set-for-parseIn-full-line"> 

     <span className="parseIn-count-text-negative">+28 %</span>  from last week</p> */}

                  <div className="chart-div">
                    <LineChart width={100} height={30} data={data}>
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#0D99FF"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="w-100 border">
            <section className="p-2">
              <div className="dashboard-nav-text d-flex justify-content-between">
                <p>Total Profit</p>

                <button className="three-dot-button">
                  <img src={threeDot} alt="" />
                </button>
              </div>

              <div>
                <h5 className="fw-bold my-2">৳ 6,288.00</h5>

                <div className="d-flex justify-content-between align-items-center">
                  {/* <p className="text-set-for-parseIn-full-line">
                    <span className="parseIn-count-text">+28%</span> from last
                    week
                  </p> */}

                  <p className="text-set-for-parseIn-full-line">
                    <span className="parseIn-count-text-negative">-8%</span>{" "}
                    from last week
                  </p>

                  <div className="chart-div">
                    <LineChart width={100} height={30} data={data}>
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#FD517D"
                        strokeWidth={2}
                      />
                    </LineChart>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      {/* chart section */}

      <section className="my-5 me-3">
        <div className="d-flex justify-content-between">
          <div className="col-7 border me-3">
             <DashboardRevenue/>
          </div>
        {/*  pai chart */}
          <div className="col-5 border ">
            <DashboardPeiChart/>
          </div>
        </div>
      </section>
      {/* table section */}

      <section className="d-flex">

        <div className="col border me-3">
          <DashboardBooks/>
        </div>

        <div className="col border me-3">
          <DashboardBestsellerAuthor/>
        </div>

        <div className="col border">
           <DashboardBestsellerPublisher/>
        </div>

      </section>

      {/* latest orders and top customers section */}

      <section className="d-flex justify-content-between mt-5 gap-4 me-4 mb-4">

        <div className="col-8 border ">

           <DashboardLatestOrders/>

        </div>

        <div className="col-4 border">

          <DashboardTopCustomers/>

        </div>

      </section>

    </div>
  );
}
