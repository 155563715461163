import React,{useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes, faCheck} from "@fortawesome/free-solid-svg-icons"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';
import { useDispatch, useSelector } from 'react-redux';
import MediaLibrary from './MediaLibrary';
import { addBook, addTag, getBook, updateBook, updateTags } from '../util/api';
import { useParams } from "react-router";
import { updateNotificationSuccess } from '../redux/notificationRedux';

export default function UpdateBook() {
  const [SingleBook, setSingleBook] = useState({})
  const [slug, setSlug] = useState("");
  const [startDate, setStartDate] = useState()
  const [perOrderDate, setperOrderDate] = useState()
  const [description, setdescription] = useState()
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false)
  const [imgurl, setimgUrl] = useState(null);
  const [lookinside, setlookinside] = useState(null);
  const [langType, setlangType] = useState("")
  const categories = useSelector((state) => state.category.categories);
  const publishers = useSelector((state) => state.publisher.publishers);
  const authors = useSelector((state) => state.author.authors);
  const books = useSelector((state) => state.book.books);
  let tags = useSelector((state)=>state.tag.tags);
  const [duplitags, setduplitags] = useState(tags)
  const navigate = useNavigate()
  const [setCommand, setsetCommand] = useState(null)
  const { id } = useParams();
  const [booktags, setbooktags] = useState([]);
  const [relatedbooks, setrelatedbooks] = useState([])
  const [changePub, setChangePub] = useState(false);
  const [querybook, setquerybook] = useState("")
  const [querytags, setquerytags] = useState("")
  const [queryCategory, setQueryCategory] = useState("")
  const [queryAuthor, setQueryAuthor] = useState("")
  const [queryPublisher, setQueryPublisher] = useState("")
  const [readerDiscount, setreaderDiscount] = useState("")
  let selectedPublisher = ""
  const [discountPrice, setdiscountPrice] = useState("")
  const [discountPercent, setdiscountPercent] = useState("")
  const options = {year: 'numeric', month: 'long', day: 'numeric' };
  let notification = useSelector((state)=> state.notification.notification)


  const getSingleBook = async () => {
    try {
      const res = books.find(e => e._id === id);
      setSingleBook(res);
      setrelatedbooks(res.relatedBooks)
      setbooktags(res.booktags)
      setQueryCategory(res.categoryBnName+" ")
      setQueryPublisher(res.publisherBnName+" ")
      setQueryAuthor(res.authorBnName+" ")
      setdiscountPercent(res.discount)
      setdiscountPrice(res.salePrice)
      setreaderDiscount(res.readerdiscount)
      setimgUrl(res.cover)
      setSlug(res.bookSlug)
      setdescription(res.desc)
      if (res.preOrderDate) {
        setperOrderDate(new Date(res.preOrderDate))
      }
      if (res.year) {
        setStartDate(new Date(res.year?res.year : ""))
      }
    } catch {
      console.log("error");
    }
  };

  useEffect(() => {
    getSingleBook()
  }, [id])

  const handleChange = (e) => {
    setSingleBook((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    setSlug(slugify(SingleBook.nameInEnglish))
  };

  let uploadImg = (childData) =>{
    setModal(!modal)
    if (setCommand === "forLookinside") {
      setlookinside(childData);
      setsetCommand(null)
    }else if (setCommand === "forImg") {
      setimgUrl(childData)
      setsetCommand(null)
    }
    
  }
  
  const specify = (e)=>{
    if (e.target.name === "lookInside") {
      setsetCommand("forLookinside");
      uploadImg();
    }else if (e.target.className === "preview-img" || "w-100") {
      setsetCommand("forImg");
      uploadImg();
      setChangePub(false)
    }
  }

  // const lookInside = (childData) =>{
  //   setsetCommand("forLookInside");
  //   setModal(!modal)
  //   setlookinside(childData)
  // }
  // console.log(setCommand);

  
  const handlePercentage = (e) =>{
    setdiscountPercent(e.target.value)
    setdiscountPrice(SingleBook.coverPrice - ((e.target.value/100)*SingleBook.coverPrice))
  }

  const handlePercentageFromSalePrice = (e) =>{
    setdiscountPrice(e.target.value)
    setdiscountPercent(((SingleBook.coverPrice-e.target.value)/SingleBook.coverPrice)*100)
  }

  const lngtype = (value) => {
    var text = value.replace(/\s/g)
    //Dictionary for Unicode range of the languages
    var langdic = {
      "arabic" : /[\u0600-\u06FF]/,
      "persian" : /[\u0750-\u077F]/,
      "Hebrew" : /[\u0590-\u05FF]/,
      "Syriac" : /[\u0700-\u074F]/,
      "Bengali" : /[\u0980-\u09FF]/,
      "Ethiopic" : /[\u1200-\u137F]/,
      "Greek and Coptic" : /[\u0370-\u03FF]/,
      "Georgian" : /[\u10A0-\u10FF]/,
      "Thai" : /[\u0E00-\u0E7F]/,
      "english" : /^[a-zA-Z]+$/
        //add other languages her
    }  
    //const keys = Object.keys(langdic); //read  keys
    //const keys = Object.values(langdic); //read  values
    const keys = Object.entries(langdic); // read  keys and values from the dictionary
    Object.entries(langdic).forEach(([key, value]) => {  // loop to read all the dictionary items if not true
      if (value.test(text) === true){   //Check Unicode to see which one is true
        return setlangType(key); //print language name if unicode true   
      }
    });
  }

  const cateSearch = (e)=>{
    setQueryCategory(e.target.value)
    lngtype(e.target.value);
  }

  const authorSearch = (event)=>{
    setQueryAuthor(event.target.value)
    lngtype(event.target.value)
  }
  const publisherSearch = (event)=>{
    setQueryPublisher(event.target.value)
    lngtype(event.target.value)
  }

  const handleCategoryChange = (e)=>{
    handleChange(e);
    setQueryCategory(e.target.placeholder);
  }

  const handleAuthorChange = (e)=>{
    handleChange(e);
    setQueryAuthor(e.target.placeholder);
  }

  const handlePublisherChange = (e)=>{
    setChangePub(true)
    handleChange(e);
    setQueryPublisher(e.target.placeholder);
    selectedPublisher = e.target.value
    publishers.map((p) => {
      if (p._id === selectedPublisher) {
        return setreaderDiscount(p.re_discount)
      }
    })
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    setLoader(true);
    setTimeout(() => {
        setLoader(false);
    }, 3000);
    addTag(newTags, dispatch);
    updateTags(updatedTags, dispatch);
    let book = { ...SingleBook};
    if (imgurl) {
        book = { ...SingleBook, cover: imgurl, desc: description, bookSlug:slug, preOrderDate: perOrderDate?.toLocaleDateString(undefined, options), lookInside: lookinside? lookinside : SingleBook.lookInside, booktags: booktags? booktags : SingleBook.booktags, relatedBooks: relatedbooks? relatedbooks : SingleBook.relatedBooks, salePrice: discountPrice, discount: discountPercent};
        updateBook(id, book, dispatch)
        .then((res) => {
          if (res === undefined) {
            dispatch(updateNotificationSuccess({sms:"Book Update Failed",status:false}))
            setTimeout(() => {
              dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);
          } else {
            dispatch(updateNotificationSuccess({sms:"Book Update Success",status:true}))
            setTimeout(() => {
              dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);
            navigate(`/edit-book/${SingleBook._id}`)
          }
            
        });
        
    } else {
        book = { ...SingleBook, cover: imgurl, desc: description? description : SingleBook.desc, bookSlug:slug, preOrderDate: perOrderDate?.toLocaleDateString(undefined, options), lookInside: lookinside? lookinside : SingleBook.lookInside, booktags: booktags? booktags : SingleBook.booktags, relatedBooks: relatedbooks? relatedbooks : SingleBook.relatedBooks, salePrice: discountPrice, discount: discountPercent};
        updateBook(id, book, dispatch)
        .then((res) => {
          if (res === undefined) {
            dispatch(updateNotificationSuccess({sms:"Book Update Failed",status:false}))
            setTimeout(() => {
              dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);
          } else {
            dispatch(updateNotificationSuccess({sms:"Book Update Success",status:true}))
            setTimeout(() => {
              dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);
            navigate(`/edit-book/${SingleBook._id}`)
          }
        });
    }
  }

  const handleRemoveImg = (e) =>{
    e.preventDefault()
    setimgUrl(null);
  }
  
  const addRelatedBooks = (e) => {
        setquerybook(e.target.value);
  };

  const removeRelatedBook = index => {
      setrelatedbooks([...relatedbooks.filter(relatedbook => relatedbooks.indexOf(relatedbook) !== index)]);
  };

  const handleRelatedBook = (e)=>{
    setrelatedbooks(current=> [...current, {bookid : e.target.value, label: e.target.placeholder}])
    setquerybook("")
  }

  const [newTags, setnewTags] = useState([]);
  const addTags = (e) => {
      if (e.key === "Enter") {
        setbooktags(newtag=> [...newtag, {tagName: e.target.value, tagType: "book"}])
        setnewTags(newtags=> [...newtags, {tagname: e.target.value, tagtype: "book"}])
      }else if(e.target.value !== ""){
        setquerytags(e.target.value);
      }
  };
  const removeTag = index => {
      setbooktags([...booktags.filter(booktag => booktags.indexOf(booktag) !== index)]);
      setnewTags([...newTags.filter(newtag => newTags.indexOf(newtag) !== index)]);
  };

  const [updatedTags, setupdatedTags] = useState([])
  const handleaddtags = (e)=>{
    setbooktags(newtag=> [...newtag, {tagName: e.target.placeholder, tagType: "book"}])
    let copyTags = [...tags]
    copyTags.map((tag)=>{
      if (tag.tagname === e.target.placeholder) {
        let count = tag.count+1
        setupdatedTags([...updatedTags, {...tag, count: count}]);
      }else{
        return tag
      }
    })
    setquerytags("")
    // setduplitags([...duplitags.filter(duplitag => duplitag._id !== e.target.value)])
  }

  console.log(SingleBook.printOut);

  return (
    <>
    {notification.status === true?
        <div className='notification-bar success'>
          <FontAwesomeIcon icon={faCheck} />
          <p>{notification.sms}</p>
        </div>
        : notification.status === false?
        <div className='notification-bar failed'>
          <FontAwesomeIcon icon={faTimes} />
          <p>{notification.sms}</p>
        </div>
        :
        ""
      }
    {modal? <div className="floating-media">
      <button className="close-modal" onClick={()=>setModal(!modal)}><FontAwesomeIcon icon={faTimes}/></button>
      <MediaLibrary className="author" uploadImg={uploadImg} publisher={SingleBook.publisherId? SingleBook.publisherId : null} />
    </div> : ""}
    <div className="content-area-inner">
      <div className="container-fluid">
        <div className="pagination">
          <Link to="/">Dashboard</Link>
          <FontAwesomeIcon icon={faAngleRight} />
          <Link to="/">Update Book</Link>
        </div>
        <div className="heading">
          <h1>Update Books</h1>
        </div>
        <form className="theme-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-8">
              <div className="theme-card">
                <div className="theme-card-body">
                    <h4 className="theme-card-heading">Product Information</h4>
                    <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Book Name (বাংলায় লিখুন)</label>
                            <input type="text" tabIndex="1" value={SingleBook.nameInBangla} name="nameInBangla" className="form-control" placeholder="Input Here" required onChange={handleChange} autoComplete="off"s/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Book Name (Write in English)</label>
                            <input type="text" tabIndex="2" value={SingleBook.nameInEnglish} name="nameInEnglish" className="form-control" placeholder="Input Here" required onChange={handleChange} autoComplete="off"s/>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Series Name</label>
                            <input type="text" tabIndex="4" value={SingleBook.series} name="series" className="form-control" placeholder="Input Here" onChange={handleChange} autoComplete="off"s/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Book Category</label>
                            <input type="text" tabIndex="5" name="categoryId" className="form-control" placeholder="Input Here" onChange={cateSearch} value={queryCategory} required autoComplete="off"s/>
                            <FontAwesomeIcon icon={faSearch} />
                            <ul className="custom-live-search">
                              {categories.filter(cate=> cate.categoryType === 'book').filter(cate=>{
                                  if (queryCategory === '') {
                                    return ""
                                  }else if (langType === 'Bengali'? cate.nameinBangla?.toLowerCase().includes(queryCategory.toLowerCase()) : cate.nameinEnglish?.toLowerCase().includes(queryCategory.toLowerCase())){
                                    return cate
                                  }
                                }).map((cate)=>(
                                  <li key={cate._id} className="live-elemennt">
                                    <label htmlFor={cate._id}>{cate.nameinBangla}</label>
                                    <input
                                      id={cate._id}
                                      name="categoryId"
                                      value={cate._id}
                                      placeholder={cate.nameinBangla}
                                      onClick={(e)=>handleCategoryChange(e)}
                                      className="child-input d-block"
                                    />
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Unique URL</label>
                            <input type="text" tabIndex="3" name="bookSlug" value={slug? slug : SingleBook.bookSlug} className="form-control" placeholder="www.bookmark.com.bd/" required onFocus={handleChange} autoComplete="off"s/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Publisher</label>
                            <input type="text" tabIndex="6" name="publisherId" className="form-control" onChange={publisherSearch} value={queryPublisher} placeholder="Input Here" required autoComplete="off"s/>
                            <FontAwesomeIcon icon={faSearch} />
                            <ul className="custom-live-search">
                              {publishers.filter(publi=> publi.status === 0).filter(publi=>{
                                  if (queryPublisher === '') {
                                    return ""
                                  }else if (langType === 'Bengali'? publi.bn_publication?.toLowerCase().includes(queryPublisher.toLowerCase()) : publi.en_publication?.toLowerCase().includes(queryPublisher.toLowerCase())){
                                    return publi
                                  }
                                }).map((pub)=>(
                                  <li key={pub._id} className="live-elemennt">
                                    <label htmlFor={pub._id}>{pub.bn_publication}</label>
                                    <input
                                      id={pub._id}
                                      name="publisherId"
                                      value={pub._id}
                                      placeholder={pub.bn_publication}
                                      onClick={(e)=>handlePublisherChange(e)}
                                      className="child-input d-block"
                                    />
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Author Name</label>
                            <input type="text" tabIndex="7" name="authorId" className="form-control" onChange={authorSearch} value={queryAuthor} placeholder="Input Here" required autoComplete="off"s/>
                            <FontAwesomeIcon icon={faSearch} />
                            <ul className="custom-live-search">
                              {authors.filter(autho=> autho.status === 0).filter(autho=>{
                                  if (queryAuthor === '') {
                                    return ""
                                  }else if (langType === 'Bengali'? autho.bn_author?.toLowerCase().includes(queryAuthor.toLowerCase()) : autho.en_author?.toLowerCase().includes(queryAuthor.toLowerCase())){
                                    return autho
                                  }
                                }).map((auth)=>(
                                  <li key={auth._id} className="live-elemennt">
                                    <label htmlFor={auth._id}>{auth.bn_author}</label>
                                    <input
                                      id={auth._id}
                                      name="authorId"
                                      value={auth._id}
                                      placeholder={auth.bn_author}
                                      onClick={handleAuthorChange}
                                      className="child-input d-block"
                                    />
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className='input-group d-flex align-items-center mb-3' style={{marginTop:"-14px"}}>
                            <label style={{marginLeft:"5px",marginBottom:"0"}}>Display as an editor?</label>
                            <input id='Yes' type="radio" name='authorRole' value="editor" style={{marginLeft:"5px",marginBottom:"0"}} onChange={handleChange} checked={SingleBook.authorRole === 'editor'? true : false} disabled={SingleBook.authorId? false : true}/>
                            <label htmlFor='Yes' style={{marginLeft:"5px",marginBottom:"0"}}>Yes</label>
                            <input id='No' type="radio" name='authorRole' value="writter" style={{marginLeft:"5px",marginBottom:"0"}} onChange={handleChange} checked={SingleBook.authorRole === 'writter'? true: false} disabled={SingleBook.authorId? false : true}/>
                            <label htmlFor='No' style={{marginLeft:"5px",marginBottom:"0"}}>No</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>অনুবাদক / Translator</label>
                            <input type="text" name="translator" tabIndex="8" className="form-control" placeholder="Input Here" value={SingleBook.translator} onChange={handleChange} autoComplete="off"/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label>Select Status</label>
                                    <select className="form-control" tabIndex="9" name="status" type="number" onChange={handleChange} required>
                                    {SingleBook.status === 0 ? (
                                        <option value="0">Active</option>
                                    ) : (
                                        <option value="1">Inactive</option>
                                    )}
                                    <option >Select Status</option>
                                    <option value="0">Published</option>
                                    <option value="1">Unpublished</option>
                                </select>
                                <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                            </div>
                        </div>
                    <div>
                </div>
              </div>
            </div>
              </div>
              <div className="theme-card">
                <div className="theme-card-body">
                  <h4 className="theme-card-heading">Price Information</h4>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Book Cover Price</label>
                        <input type="text" tabIndex="10" value={SingleBook.coverPrice} name="coverPrice" className="form-control" placeholder="BDT" required onChange={handleChange} autoComplete="off"s/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Book Reader Discount</label>
                        <input type="text" tabIndex="11" value={discountPercent} name="discount" className="form-control" placeholder="%" onChange={(e)=>handlePercentage(e)} autoComplete="off"s/>
                        <p>Reader discount %{readerDiscount}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Book Sale Price</label>
                        <input type="text" tabIndex="12" name="salePrice" className="form-control" placeholder="BDT" value={discountPrice} required onChange={handlePercentageFromSalePrice} autoComplete="off"s/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Stock</label>
                        <input type="text" tabIndex="13" value={SingleBook.stock} name="stock" min="0" max="500" className="form-control" placeholder="ex.100" onChange={handleChange} autoComplete="off"s required/>
                        <span>Pcs</span>
                      </div>
                    </div>
                    <div className="col-lg-6 form-group">
                      <label>Book Condition</label>
                      <div className="d-flex align-items-center">
                        <input type="radio" id="new" value="new" tabIndex="14" name="condition" onChange={handleChange} checked={SingleBook.condition === 'new'? true : false}/>
                        <label className="mb-0" htmlFor="new" style={{marginLeft:"3px"}}>New</label>
                        <input type="radio" id="secondhand" tabIndex="15" value="secondhand" name="condition" onChange={handleChange} style={{marginLeft:"18px"}} checked={SingleBook.condition === 'secondhand'? true : false}/>
                        <label className="mb-0" htmlFor="secondhand" style={{marginLeft:"3px"}}>Secondhand</label>
                      </div>
                    </div>
                    <div className="col-lg-6 form-group">
                      <label>Sales Type</label>
                      <div className="d-flex align-items-center">
                        <input type="radio" id="regular" tabIndex="16" value="regular" name="saleType" onChange={handleChange} checked={SingleBook.saleType === 'regular'? true : false}/>
                        <label className="mb-0" htmlFor="regular" style={{marginLeft:"3px"}}>Regular</label>
                        <input type="radio" id="pre-order" value="preorder" name="saleType" onChange={handleChange} style={{marginLeft:"18px"}} checked={SingleBook.saleType === 'preorder'? true : false}/>
                        <label className="mb-0" htmlFor="pre-order" tabIndex="17" style={{marginLeft:"3px"}}>Pre-Order</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Pre-Order Ends</label>
                            <DatePicker tabIndex="18" selected={perOrderDate} onChange={(date:Date) => setperOrderDate(date)} />
                        </div>
                    </div>
                    <div className="col-lg-6 form-group">
                      <label>Is this book printout?</label>
                      <div className="d-flex align-items-center">
                        <input type="radio" id="yes" value="true" tabIndex="19" name="printOut" onChange={handleChange} checked={SingleBook.printOut ==="true"? true : false}/>
                        <label className="mb-0" htmlFor="yes" style={{marginLeft:"3px"}}>Yes</label>
                        <input type="radio" id="no" value="false" tabIndex="20" name="printOut" onChange={handleChange} style={{marginLeft:"18px"}} checked={SingleBook.printOut === "false"? true : false}/>
                        <label className="mb-0" htmlFor="no" style={{marginLeft:"3px"}}>No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="theme-card">
                <div className="theme-card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Description</label>
                        {/* <textarea placeholder="Your desccription here" className="form-control" style={{height:"200px"}}></textarea> */}
                        <CKEditor
                            editor={ ClassicEditor }
                            data={description}
                            onReady={ editor => {
                                
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setdescription(data)
                            } }
                            tabIndex="21"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Flap</label>
                        <textarea className="form-control" tabIndex="22" value={SingleBook.flap} name="flap" style={{height:"135px"}} placeholder="Input Here" onChange={handleChange}></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>First Publication</label>
                        <input type="text" className="form-control" tabIndex="23" value={SingleBook.year} name="year" onChange={handleChange} placeholder="Select Date"/>
                        {/* <DatePicker tabIndex="16" selected={startDate} onChange={(date:Date) => setStartDate(date)} /> */}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Edition</label>
                        <input type="text" tabIndex="24" value={SingleBook.edition} name="edition" className="form-control" placeholder="Input Here" onChange={handleChange} autoComplete="off"s/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Cover Type</label>
                        <select className="form-control" tabIndex="25" name="coverType" onChange={handleChange}>
                          {
                            <option value={SingleBook.coverType} selected>{SingleBook.coverType}</option>
                          }
                          <option value="">Select Cover Type</option>
                          <option value="hardcover">Hardcover</option>
                          <option value="paperback">Paperback</option>
                          <option value="paperbinding">Paperbinding</option>
                        </select>
                        <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Cover Designer</label>
                        <input type="text" tabIndex="26" value={SingleBook.coverDesigner} name="coverDesigner" className="form-control" placeholder="Input Here" onChange={handleChange} autoComplete="off"s/>
                        <FontAwesomeIcon icon={faSearch} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Number of Pages</label>
                        <input type="text" tabIndex="27" value={SingleBook.numberOfPages} name="numberOfPages" className="form-control" placeholder="Input Here" onChange={handleChange} autoComplete="off"s/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Look Inside</label>
                        <input type="url" onClick={(e)=>specify(e)} tabIndex="28" name="lookInside" className="form-control look-inside" placeholder={lookinside === null? `${SingleBook.lookInside?.split(`${process.env.REACT_APP_BASE_URL}/api/alluploads/`)}`: lookinside?.split(`${process.env.REACT_APP_BASE_URL}/api/alluploads/`)} autoComplete="off"/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>ISBN</label>
                        <input type="text" tabIndex="29" value={SingleBook.isbn} name="isbn" className="form-control" placeholder="Input Here" onChange={handleChange} autoComplete="off"s/>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                    <div className="form-group">
                        <label htmlFor="relatedbooks">Related Books</label>
                        <div className="related-books form-control">
                            {relatedbooks?.map((relatedbook, index)=>(
                                <div className="selected-book" key={index}>
                                    <p>{relatedbook?.label}</p>
                                    <FontAwesomeIcon icon={faTimes} onClick={() => removeRelatedBook(index)}  />
                                </div>
                            ))}
                            <input id="relatedbooks" tabIndex="30" type="text" placeholder="Press enter to add Related books" onChange={(e) => addRelatedBooks(e)} value={querybook} autoComplete="off"/>
                            <ul className="custom-live-search">
                              {books.filter(book=> book.status === 0).filter(book=>{
                                  if (querybook === '') {
                                    return ""
                                  }else if (book.nameInBangla?.toLowerCase().includes(querybook.toLowerCase())){
                                    return book
                                  }
                                }).map((book)=>(
                                  <li key={book._id} className="live-elemennt">
                                    <label htmlFor={book._id}>{book.nameInBangla}</label>
                                    <input
                                      id={book._id}
                                      name="bookgoryId"
                                      value={book._id}
                                      placeholder={book.nameInBangla}
                                      onClick={(e)=>handleRelatedBook(e)}
                                      className="child-input d-block"
                                    />
                                  </li>
                                ))}
                            </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-btn">
                  {/* <button className="btn theme-btn-alt">Submit and Add Another</button> */}
                  <button className="btn theme-btn" type="submit">Submit</button>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="theme-card">
                <div className="theme-card-body">
                  {imgurl? <button className="btn close" type="button" onClick={handleRemoveImg}><FontAwesomeIcon icon={faTimes} /></button> : ""}
                  {changePub?
                    <div className="form-group upload-img" name="img" onClick={specify}>
                        <label>
                        <svg width="72" height="57" viewBox="0 0 72 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M68.0057 10.3334V7.00004H60.8858V10.3334H56.3326L52.7727 0.333374H19.1595L15.5995 10.3334H0.366455V57H71.5657V10.3334H68.0057ZM64.4458 50.3334H7.48638V17H20.733L24.293 7.00004H47.6392L51.1992 17H64.4458V50.3334Z" fill="#E1E1E1"/>
                        <path d="M35.9661 17C32.7977 17 29.7004 17.8797 27.066 19.528C24.4315 21.1762 22.3782 23.5189 21.1657 26.2597C19.9532 29.0006 19.636 32.0166 20.2541 34.9264C20.8722 37.8361 22.398 40.5088 24.6384 42.6066C26.8788 44.7044 29.7333 46.133 32.8408 46.7118C35.9484 47.2906 39.1694 46.9935 42.0966 45.8582C45.0239 44.7229 47.5258 42.8003 49.2861 40.3336C51.0464 37.8668 51.986 34.9667 51.986 32C51.9812 28.0231 50.2919 24.2104 47.2887 21.3983C44.2854 18.5862 40.2134 17.0044 35.9661 17ZM35.9661 40.3333C34.2059 40.3333 32.4852 39.8446 31.0216 38.9289C29.558 38.0132 28.4173 36.7117 27.7437 35.189C27.0701 33.6663 26.8938 31.9908 27.2372 30.3742C27.5806 28.7577 28.4283 27.2729 29.6729 26.1074C30.9176 24.942 32.5034 24.1483 34.2298 23.8268C35.9563 23.5052 37.7457 23.6703 39.372 24.301C40.9982 24.9317 42.3882 25.9998 43.3661 27.3702C44.3441 28.7407 44.866 30.3518 44.866 32C44.866 34.2101 43.9284 36.3298 42.2593 37.8926C40.5903 39.4554 38.3265 40.3333 35.9661 40.3333Z" fill="#E1E1E1"/>
                        </svg>
                        Click here to upload Images
                        </label>
                        <div className="preview-img">
                        <img className="w-100"/>
                        </div>
                    </div> : imgurl? 
                    <img src={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${SingleBook.publisherId}/${imgurl}`} name="img" className="w-100" onClick={specify} /> 
                    :
                    <div className="form-group upload-img" name="img" onClick={specify}>
                        <label>
                        <svg width="72" height="57" viewBox="0 0 72 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M68.0057 10.3334V7.00004H60.8858V10.3334H56.3326L52.7727 0.333374H19.1595L15.5995 10.3334H0.366455V57H71.5657V10.3334H68.0057ZM64.4458 50.3334H7.48638V17H20.733L24.293 7.00004H47.6392L51.1992 17H64.4458V50.3334Z" fill="#E1E1E1"/>
                        <path d="M35.9661 17C32.7977 17 29.7004 17.8797 27.066 19.528C24.4315 21.1762 22.3782 23.5189 21.1657 26.2597C19.9532 29.0006 19.636 32.0166 20.2541 34.9264C20.8722 37.8361 22.398 40.5088 24.6384 42.6066C26.8788 44.7044 29.7333 46.133 32.8408 46.7118C35.9484 47.2906 39.1694 46.9935 42.0966 45.8582C45.0239 44.7229 47.5258 42.8003 49.2861 40.3336C51.0464 37.8668 51.986 34.9667 51.986 32C51.9812 28.0231 50.2919 24.2104 47.2887 21.3983C44.2854 18.5862 40.2134 17.0044 35.9661 17ZM35.9661 40.3333C34.2059 40.3333 32.4852 39.8446 31.0216 38.9289C29.558 38.0132 28.4173 36.7117 27.7437 35.189C27.0701 33.6663 26.8938 31.9908 27.2372 30.3742C27.5806 28.7577 28.4283 27.2729 29.6729 26.1074C30.9176 24.942 32.5034 24.1483 34.2298 23.8268C35.9563 23.5052 37.7457 23.6703 39.372 24.301C40.9982 24.9317 42.3882 25.9998 43.3661 27.3702C44.3441 28.7407 44.866 30.3518 44.866 32C44.866 34.2101 43.9284 36.3298 42.2593 37.8926C40.5903 39.4554 38.3265 40.3333 35.9661 40.3333Z" fill="#E1E1E1"/>
                        </svg>
                        Click here to upload Images
                        </label>
                        <div className="preview-img">
                        <img className="w-100"/>
                        </div>
                    </div>
                  }
                  <span><b>Album :</b>{
                    publishers.map((pub)=> {
                      if (pub._id === SingleBook.publisherId) {
                        return pub.bn_publication;
                      }
                    })
                  }</span>
                </div>
              </div>
              <div className="theme-card">
                <div className="theme-card-body">
                  <div className="form-group upload-img">
                    <label>
                    <svg width="72" height="64" viewBox="0 0 72 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.366455 0V53.3333H71.5657V0H0.366455ZM64.4458 46.6667H7.48638V6.66667H64.4458V46.6667Z" fill="#E1E1E1"/>
                      <path d="M25.2861 10.78V42.5534L53.5629 26.6667L25.2861 10.78ZM32.4061 22.5534L39.7289 26.6667L32.4061 30.78V22.5534Z" fill="#E1E1E1"/>
                      <path d="M53.7659 56.6666H18.1663V63.3333H53.7659V56.6666Z" fill="#E1E1E1"/>
                    </svg>

                    Click here to upload Videos
                    </label>
                    <div className="preview-img">
                      <img className="w-100"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="theme-card">
                <div className="theme-card-body">
                  <div className="form-group">
                    <label>Tags</label>
                    <div className="related-books form-control">
                      {booktags?.map((tag, index)=>(
                        <div className="selected-book" key={index}>
                            <p>{tag.tagName}</p>
                            <FontAwesomeIcon icon={faTimes}  onClick={()=> removeTag(index)}/>
                        </div>
                      ))}
                      <input type="text" tabIndex="31" className="form-control" placeholder="Press Enter To Add Tag" onKeyUp={addTags} />
                      <ul className="custom-live-search">
                        {duplitags.filter(tag=> tag.tagtype === "book").filter(tag=>{
                            if (querytags === '') {
                              return ""
                            }else if (tag.tagname?.toLowerCase().includes(querytags.toLowerCase())){
                              return tag
                            }
                          }).map((tag)=>{
                            return(
                              <li key={tag._id} className="live-elemennt">
                                <label htmlFor={tag._id}>{tag.tagname}</label>
                                <input
                                  id={tag._id}
                                  name="bookgoryId"
                                  value={tag._id}
                                  placeholder={tag.tagname}
                                  onClick={(e)=>handleaddtags(e)}
                                  className="child-input d-block"
                                />
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>SKU</label>
                    <input type="text" tabIndex="32" value={SingleBook.bookSlug} name="sku" className="form-control" placeholder="" onChange={handleChange} autoComplete="off"s/>
                  </div>
                  <div className="form-group">
                    <label>Reading Age</label>
                    <select className="form-control" name="readingAge" onChange={handleChange} tabIndex="33">
                      {SingleBook.readingAge? <option value={SingleBook.readingAge} selected>{SingleBook.readingAge}+</option> : ""}
                      <option value="">Select Reading Age</option>
                      <option value="3">3+</option>
                      <option value="6">6+</option>
                      <option value="12">12+</option>
                    </select>
                    <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                  </div>
                  <div className="form-group">
                    <label>Language</label>
                    <select className="form-control" name="language" onChange={handleChange} tabIndex="34">
                      {SingleBook.language === 'bangla' ? (
                        <option value="bangla" selected>Bangla</option>
                      ) : SingleBook.language === 'english'? (
                        <option value="english" selected>English</option>
                      ) : 
                        SingleBook.language === 'bangla' ?(
                        <option value="urdu" selected>Urdu</option>
                      ): ""}
                      <option value="">Select Language</option>
                      <option value="bangla">Bangla</option>
                      <option value="english">English</option>
                      <option value="urdu">Urdu</option>
                    </select>
                    <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                  </div>
                  <div className="form-group">
                    <label>Dimension</label>
                    <input className="form-control" type="text" name="dimension" placeholder="e.g 20X14X4 cm" value={SingleBook.dimension} onChange={handleChange} tabIndex="35"/>
                  </div>
                  <div className="form-group">
                    <label>Weight</label>
                    <input className="form-control" type="text" name="weight" placeholder="e.g gm" value={SingleBook.weight} onChange={handleChange} tabIndex="36"/>
                  </div>
                  <div className="form-group">
                    <label>Country</label>
                    <input className="form-control" type="text" name="country" placeholder="e.g Bangladesh"  value={SingleBook.country} onChange={handleChange} tabIndex="37"/>
                  </div>
                  <div className="form-group">
                    <label>Color</label>
                    <input className="form-control" type="text" name="color" placeholder="e.g red"  value={SingleBook.color} onChange={handleChange} tabIndex="38"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </>
  )
}
