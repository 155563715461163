import {configureStore,combineReducers} from "@reduxjs/toolkit"
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./authRedux"
import bookReducer from "./bookRedux"
import publisherReducer from "./publisherRedux"
import authorReducer from "./authorRedux"
import categoryReducer from "./categoryRedux"
import mediaReducer from "./mediaRedux"
import blogReducer from "./blogRedux"
import adminReducer from "./adminRedux"
import sliderReducer from "./sliderRedux"
import tagReducer from "./tagRedux"
import menuReducer from "./menuRedux"
import collectionReducer from "./collectionRedux"
import adsReducer from "./adsRedux"
import draftBooksReducer from "./draftBooksRedux"
import notificationReducer from "./notificationRedux"

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const rootReducer = combineReducers({ userInfo: authReducer, book: bookReducer, publisher: publisherReducer, author: authorReducer, category: categoryReducer, media: mediaReducer, blog: blogReducer, admin: adminReducer, slider: sliderReducer, tag: tagReducer, menu:menuReducer, collection:collectionReducer, ads:adsReducer, draftBooks: draftBooksReducer, notification: notificationReducer});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export let persistor = persistStore(store);