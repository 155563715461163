import React, { useEffect } from 'react'
import { Link,useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addRole, getActions, updateRole } from '../util/api'
import { useState } from 'react'
import { updateNotificationSuccess } from '../redux/notificationRedux';
import { userRequest } from '../util/requestMethod'

export default function UpdateRole() {
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()
    let notification = useSelector((state)=> state.notification.notification)
    const [actions, setactions] = useState([])
    const [checked, setChecked] = useState([]);
    const [singleRole, setsingleRole] = useState({});
    const {id} = useParams()

    const getSingleRole = async () => {
        try {
          const res = await userRequest.get(`${process.env.REACT_APP_BASE_URL}/api/roles/find/${id}`)
          setChecked(res.data.actions)
          setsingleRole(res.data)
        } catch {
          console.log("error");
        }
      };

    const handleSubmit = (e) =>{
        e.preventDefault()
        let role = { ...singleRole,actions: checked};
        updateRole(role, id)
        .then((res) => {
            if (res === undefined) {
                dispatch(updateNotificationSuccess({sms:"Role update Failed",status:false}))
                setTimeout(() => {
                dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
            } else {
                dispatch(updateNotificationSuccess({sms:"Role update Success",status:true}))
                navigate(`/edit-role/${id}`)
            }
        });
    }

    const handleChange = (e) => {
        setsingleRole((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        });
    };

    useEffect(() => {
        getActions().then((res)=>{
            setactions(res.data)
        })
        getSingleRole()
    }, [])

    const handleChecked = (e) =>{
        var updatedList = [...checked];
        if (e.target.checked) {
            updatedList = [...checked, e.target.value];
        } else {
            updatedList.splice(checked.indexOf(e.target.value), 1);
        }
        setChecked(updatedList);
    }

    console.log(checked);

    return (
        <>
        {/* {notification.status === true?
            <div className='notification-bar success'>
              <FontAwesomeIcon icon={faCheck} />
              <p>{notification.sms}</p>
            </div>
            : notification.status === false?
            <div className='notification-bar failed'>
              <FontAwesomeIcon icon={faTimes} />
              <p>{notification.sms}</p>
            </div>
            :
            ""
          } */}
        <div className="content-area-inner">
            <div className="container-fluid">
                <div className="pagination">
                    <Link to="/">Dashboard</Link>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <Link to="/">Update Role</Link>
                </div>
                <div className="heading">
                    <h1>Update Role</h1>
                </div>
                <form className="theme-form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme-card">
                                <div className="theme-card-body">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Role name</label>
                                                <input type="text" tabIndex="1" name="name" value={singleRole.name} className="form-control" placeholder="Input Here" onChange={handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Select Status</label>
                                                <select type="number" tabIndex="4" className="form-control" name="status" onChange={handleChange} required>
                                                    {singleRole.status === true ? (
                                                        <option value="true">Active</option>
                                                    ) : (
                                                        <option value="false">Inactive</option>
                                                    )}
                                                    <option value="">Select Status</option>
                                                    <option value="true">Active</option>
                                                    <option value="false">Inactive</option>
                                                </select>
                                                <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="form-group">
                                                <label>Selected Actions</label>
                                                <ul>
                                                    {checked?.map((action)=>(
                                                        <div className='collectionBook'>
                                                            <input type="checkbox" id={action} value={action} onChange={handleChecked} checked/>
                                                            <label htmlFor={action}>{action}</label>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className="form-group">
                                                <label>Select Actions</label>
                                                <ul>
                                                    {actions?.map((action)=>(
                                                        <div className='collectionBook'>
                                                            <input type="checkbox" id={action.userdefinedname} value={action.actionname} onChange={handleChecked}/>
                                                            <label htmlFor={action.userdefinedname}>{action.userdefinedname}</label>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-btn">
                                <button className="btn theme-btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
      )
}
