import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes} from "@fortawesome/free-solid-svg-icons"
import slugify from 'react-slugify';
import { useDispatch,useSelector } from 'react-redux';
import MediaLibrary from './MediaLibrary';
// import { createBrowserHistory } from "history";
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { getHomepageSetting, updateHomepageSetting } from '../util/api';
import { useParams } from "react-router";

export default function UpdateSection() {
  // const history = createBrowserHistory({ forceRefresh: true });
  const [inputs, setInputs] = useState({});
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false)
  const [imgurl, setimgUrl] = useState(null);
  const navigate = useNavigate()
  const { id } = useParams();
  const [sectionData, setsectionData] = useState({})
  const categories = useSelector((state) => state.category.categories);
  const collections = useSelector((state) => state.collection.collections);
  const ads = useSelector((state) => state.ads.ads);
  const [allSection, setallSection] = useState([])
  const [settingId, setsettingId] = useState()

  const handleSectionChange = (e) => {
    setsectionData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const uploadImg = (childData) =>{
    setModal(!modal)
    setimgUrl(childData)
  }

  const geHomepageInfo = async () => {
    try {
      getHomepageSetting()
      .then((res)=>{
        let section = res.data[0].section.find(e => e._id === id)
        setsectionData(section)
        setallSection(res.data[0].section)
        setsettingId(res.data[0]._id)
      })
    } catch (error) {
      const res = "Something went wrong";
    }
  };

  useEffect(() => {
    geHomepageInfo()
  }, [id])

  let allSectionData = allSection;
  const addingSectionToArray = async (id) =>{
    allSectionData[
      allSectionData.findIndex((item) => item._id === id)
    ] = sectionData
  }


  const handleSubmit = (e) =>{
    e.preventDefault()
    addingSectionToArray(sectionData._id)
    .then(()=>{
      updateHomepageSetting(settingId, {section:allSectionData})
      .then(() => navigate('/edithomepage'));
    })
  }

  
  return (
    <>
    {modal? <div className="floating-media">
      <button className="close-modal" onClick={()=>setModal(!modal)}><FontAwesomeIcon icon={faTimes}/></button>
      <MediaLibrary className="author" uploadImg={uploadImg}/>
    </div> : ""}
    <div className="content-area-inner">
      <div className="container-fluid">
        <div className="pagination">
          <Link to="/">Dashboard</Link>
          <FontAwesomeIcon icon={faAngleRight} />
          <Link to="/">Update Section</Link>
        </div>
        <div className="heading">
          <h1>Update Section</h1>
        </div>
          <div className="row">
            <div className="col-lg-6">
              <form className="theme-form" onSubmit={handleSubmit}>
                <div className="theme-card">
                  <div className="theme-card-body">
                    <h4 className="theme-card-heading">Homepage Sections </h4>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Select Section Type</label>
                          <select name="sectiontype" className="form-control" onChange={handleSectionChange}>
                            {sectionData.sectiontype? <option value={sectionData.sectiontype}>{sectionData.sectiontype}</option> : <option value="">Select Category/Collection/Ads</option>}
                            <option value="collection">Collection</option>
                            <option value="category">Category</option>
                            <option value="ads">Ads</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Select Section</label>
                          <select name="sectionid" type="number" className="form-control" onChange={handleSectionChange}>
                            {
                              sectionData.sectiontype === 'collection'? (
                                collections.filter(collection => collection.status === 0 && collection._id === sectionData.sectionid).map((collection)=>{
                                  return (<option key={collection._id} value={collection._id}>{collection.title}</option>)
                                })
                              ) : sectionData.sectiontype === 'category'? (
                                categories.filter(category => category.status === 0 && category._id === sectionData.sectionid).map((category)=>{
                                  return (<option key={category._id} value={category._id}>{category.nameinBangla}</option>)
                                })
                              ) : (
                                ads.filter(ad => ad.status === 0 && ad._id === sectionData.sectionid).map((ad)=>{
                                  return (<option key={ad._id} value={ad._id}>{ad.title}</option>)
                                })
                              ) 
                            }
                            {
                              sectionData.sectiontype === 'collection'? (
                                collections.filter(collection => collection.status === 0).map((collection)=>{
                                  return (<option key={collection._id} value={collection._id}>{collection.title}</option>)
                                })
                              ) : sectionData.sectiontype === 'category'? (
                                categories.filter(category => category.status === 0).map((category)=>{
                                  return (<option key={category._id} value={category._id}>{category.nameinBangla}</option>)
                                })
                              ) : (
                                ads.filter(ad => ad.status === 0).map((ad)=>{
                                  return (<option key={ad._id} value={ad._id}>{ad.title}</option>)
                                })
                              )
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Number Of Item</label>
                          <input name="numberofitem" className="form-control" value={sectionData.numberofitem} onChange={handleSectionChange}/>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Select Template</label>
                          <select name="template" className="form-control" onChange={handleSectionChange}>
                            {sectionData.template? <option value={sectionData.template}>{sectionData.template}</option> : <option value="">Select template</option>}
                            <option value="slider">Slider Section</option>
                            <option value="simple">Simple Section</option>
                            <option value="collection">Collection Section</option>
                            <option value="adhalfwidth">Ad Hald Width</option>
                            <option value="adfullwidth">Ad full Witdh</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>List Order</label>
                          <input name="listorder" className="form-control" value={sectionData.listorder} onChange={handleSectionChange}/>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Select Status</label>
                          <select name="status" className="form-control" onChange={handleSectionChange}>
                            {sectionData.status === 0 ? (
                                <option value="0">Active</option>
                            ) : (
                                <option value="1">Inactive</option>
                            )}
                            <option value="">Select status</option>
                            <option value="0">Active</option>
                            <option value="1">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="group-btn">
                        <button className="btn theme-btn" type="submit">Update</button>
                        <button className="btn theme-btn-alt">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
      </div>
    </div>
    </>
  )
}
