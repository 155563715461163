import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;
const userInfo = JSON.parse(localStorage.getItem("persist:root"))?.userInfo;
const currentUser = userInfo && JSON.parse(userInfo).currentUser;
const TOKEN = currentUser?.accessToken;

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: { 'Acess-Control-Allow-Origin':'*' },
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}`,'Acess-Control-Allow-Origin':'*' },
});
