import React from 'react'
import { useEffect } from 'react';
import { userRequest } from '../util/requestMethod'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faCheck, faAngleDown, faKey, faTimes,faFileExcel, faEdit} from "@fortawesome/free-solid-svg-icons"
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactPaginate from 'react-paginate';
import { getReaders, updatePassordForReader, updateReader } from '../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationSuccess } from '../redux/notificationRedux';

export function Readers() {
    const dispatch = useDispatch()
    const [readers, setreaders] = useState([])
    const [readerPerPage, setreaderPerPage] = useState(10)
    const [loader, setLoader] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [searchedQuery, setsearchedQuery] = useState("");
    const pageVisited = pageNumber * readerPerPage;
    const [isModalOpen, setisModalOpen] = useState(false);
    let notification = useSelector((state)=> state.notification.notification)
    const [dob, setdob] = useState({
        month:undefined,
        date:undefined,
        year:undefined
      });
    

    useEffect(() => {
        getReaders().then((res)=>{
            setreaders(res.data)
        })
    }, [dispatch])

    const handleSearch = (e) =>{
        setsearchedQuery(e.target.value)
    }

    const handleSorting = () =>{

    }

    const handleUpdateStatus = (e, id) => {
        setLoader(true);
        let update = {"status": e.target.value}
        updateReader(update, id).then((res)=>{
            if (res === undefined) {
                dispatch(updateNotificationSuccess({sms:"User Update Failed",status:false}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                setLoader(false)
            } else {
                dispatch(updateNotificationSuccess({sms:"User Update Success",status:true}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                getReaders().then((res)=>{
                    setreaders(res.data)
                })
                setLoader(false)
            }
        })
    }

    const generatePassword = () =>{
        const randomPassword =
        Math.random().toString(26).slice(-6) + Math.random().toString(26).slice(-6);
        return randomPassword;
    }

    const handleAccess = (e, email) =>{
        e.preventDefault()
        let userInfo = {
          email: email,
          password:generatePassword()
        }
        updatePassordForReader(userInfo).then((res)=>{
          if (res === undefined) {
            dispatch(updateNotificationSuccess({sms:"Reset Password Failed",status:false}))
            setTimeout(() => {
                dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);
            setLoader(false)
          } else {
            dispatch(updateNotificationSuccess({sms:"Reset Password Success",status:true}))
            setTimeout(() => {
                dispatch(updateNotificationSuccess({sms:"",status:null}))
            }, 2000);
            getReaders().then((res)=>{
                setreaders(res.data)
            })
            setLoader(false)
          }
        })
    };

    const handleUpdateReaderInfo = () =>{
        setisModalOpen(true)
    }

    const closeModal = () =>{
        setisModalOpen(false)
    }

    const displayReaders = readers.filter(reader=> {
        if (searchedQuery === "") {
          return reader
        }
        else if (reader.fullname.toLowerCase().includes(searchedQuery.toLowerCase())) {
          return reader
        }
      }).slice(pageVisited, pageVisited + readerPerPage)
        .map((reader) => {
          return (
            <div className="table-row" key={reader._id}>
                <li>
                    <img src={reader.readerPhoto? `${process.env.REACT_APP_BASE_URL}/api/readersMedia/${reader._id}/${reader.readerPhoto}` : `${process.env.REACT_APP_BASE_URL}/api/alluploads/Default-male-writter.png`} />
                    {reader.username}
                </li>
                <li>
                  {reader.fullname}
                </li>
                <li>
                    {reader.phone}
                </li>
                <li>
                    {reader.email}
                </li>
                <li>{reader.city}</li>
                <li>{`${reader.address1}, ${reader.address2}`}</li>
                <li>
                    <select className='form-control' onChange={(e)=>handleUpdateStatus(e,reader._id)}>
                        <option value="" >Select Status</option>
                        <option value="0" >Active</option>
                        <option value="1" >Hold</option>
                    </select>
                    <p className={reader.status === 0? "status success" : "status pending"}>{reader.status === 0? "Active" : "Hold"}</p>
                    <a className="access" style={{marginLeft:"8px"}} onClick={(e)=>handleAccess(e,reader.email)}>
                        <FontAwesomeIcon icon={faKey} />
                    </a>
                    <a className="access" style={{marginLeft:"8px"}} onClick={(e)=>handleUpdateReaderInfo(e)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </a>
                </li>
            </div>
          );
        });


    const pageCount = Math.ceil(readers.length / readerPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    const dates = () => {
        let dates31 = []
        for (var i=1; i <=31; i++) {
            dates31.push(<option value={i}>{i}</option>)
        }
        return dates31
    }
    const years = () => {
        let year = []
        for (var i=1; i <=70; i++) {
            year.push(<option value={1951+i}>{1951+i}</option>)
        }
        return year
    }

    const handleDatePicker = (e) =>{
        setdob((prev)=>{
          return {...prev, [e.target.name]:e.target.value}
        })
      }

    return (
        <>
        {isModalOpen === true? (
            <div className="accessModal">
                <div className="accessModalInner">
                    <FontAwesomeIcon icon={faTimes} onClick={()=>closeModal()}/>
                    <div className="accessInfo" style={{maxWidth:"700px"}}>
                        <h1>Moinul</h1>
                        <form>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" value="" placeholder='Fullname' className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" value="" placeholder='Username' className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="email" value="" placeholder='Email' className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" value="" placeholder='Phone' className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <select type="number" className="form-control" onChange={handleDatePicker} name="month">
                                            {dob.month !== undefined? <option value={dob.month}>{dob.month}</option> : <option value="">mm</option>}
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            </select>
                                            <select className="form-control" onChange={handleDatePicker} name="date">
                                            {dob.date !== undefined? <option value={dob.date}>{dob.date}</option> : <option value="">dd</option>}
                                            {dates()}
                                            </select>
                                            <select className="form-control" onChange={handleDatePicker} name="year">
                                            {dob.year !== undefined? <option value={dob.year}>{dob.year}</option> : <option value="">yyyy</option>}
                                            {years()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn theme-btn">Send Confirmation</button>
                        </form>
                    </div>
                </div>
            </div>
        ) : (
            ""
        )
        }
        {notification.status === true?
            <div className='notification-bar success'>
            <FontAwesomeIcon icon={faCheck} />
            <p>{notification.sms}</p>
            </div>
            : notification.status === false?
            <div className='notification-bar failed'>
            <FontAwesomeIcon icon={faTimes} />
            <p>{notification.sms}</p>
            </div>
            :
            ""
        }
        <div className="content-area-inner list-page">
            <div className="container-fluid">
                <div className="breadcumb">
                <Link to="/">Dashboard</Link>
                <FontAwesomeIcon icon={faAngleRight} />
                <Link to="/">Reader Lists</Link>
                </div>
                <div className="heading">
                <h1>Reader List</h1>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="tab-btns">
                                <div className="tab-btn active">All Items ({readers.length})</div>
                                <div className="tab-btn">Drafts (0)</div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="group-btn">
                                
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-wrap">
                            <div className="theme-card">
                                <div className="theme-card-header">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="searchbar">
                                                <div className="form-group">
                                                    <input type="text" placeholder="Search" onChange={(e)=>handleSearch(e)}/>
                                                    <button type="submit" className="btn">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4523 11.4716H12.6623L12.3823 11.2016C13.5823 9.80157 14.2023 7.89157 13.8623 5.86157C13.3923 3.08157 11.0723 0.861573 8.27229 0.521573C4.04229 0.00157288 0.482286 3.56157 1.00229 7.79157C1.34229 10.5916 3.56229 12.9116 6.34229 13.3816C8.37229 13.7216 10.2823 13.1016 11.6823 11.9016L11.9523 12.1816V12.9716L16.2023 17.2216C16.6123 17.6316 17.2823 17.6316 17.6923 17.2216C18.1023 16.8116 18.1023 16.1416 17.6923 15.7316L13.4523 11.4716ZM7.45229 11.4716C4.96229 11.4716 2.95229 9.46157 2.95229 6.97157C2.95229 4.48157 4.96229 2.47157 7.45229 2.47157C9.94229 2.47157 11.9523 4.48157 11.9523 6.97157C11.9523 9.46157 9.94229 11.4716 7.45229 11.4716Z" fill="#1D1D1D"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="list-filter d-flex justify-content-end">
                                                <div className="form-group">
                                                    <select className="form-control" onChange={handleSorting}>
                                                        <option value="filter">Filter</option>
                                                        <option value="pricehightolow">Price High To Low </option>
                                                        <option value="pricelowtohigh">Price Low To High </option>
                                                        <option value="stock">Stock</option>
                                                        <option value="sold">Sold</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <select type="number" className="form-control" onChange={(e)=>{setreaderPerPage(e.target.value)}}>
                                                        <option value="10">Book per page</option>
                                                        <option value="20">20 per page</option>
                                                        <option value="30">30 per page</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loader? 
                                <BeatLoader color={"#0D99FF"} loading={loader} size={15}/>
                                :
                                <div className="theme-card-body">
                                    <div className="table order-table">
                                        <ul className="custom-table-header">
                                            <li>Username</li>
                                            <li>Full Name</li>
                                            <li>Phone</li>
                                            <li>Email</li>
                                            <li>City</li>
                                            <li>Address</li>
                                            <li>Action</li>
                                        </ul>
                                        <ul className="custom-table-body">
                                            {displayReaders}
                                        </ul>
                                        <ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"pagination"}
                                            activeClassName={"actvie-page"}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="tab-wrap">
                            {/* <div className="theme-card">
                                Draft
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
