import Sidebar from './components/Sidebar';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AddBook from './components/AddBook';
import AddCategory from './components/AddCategory';
import Navbar from './components/Navbar';
import BookList from './components/BookList';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import {isAuthenticated, signout} from "./util/auth"
import {useSelector, useDispatch} from "react-redux"
import CategoryList from './components/CategoryList';
import AddAuthor from './components/AddAuthor';
import AuthorList from './components/AuthorList';
import AddPublisher from './components/AddPublisher';
import PublisherList from './components/PublisherList';
import SiteSetting from './components/SiteSetting';
import AddSlider from './components/AddSlider';
import SliderList from './components/SliderList';
import AddPost from './components/AddPost';
import BlogList from './components/BlogList';
import PendingReviews from './components/PendingReviews';
import PublishedReview from './components/PublishedReview';
import AddLibrary from './components/AddLibrary';
import LibraryList from './components/LibraryList';
import CreateAdmin from './components/CreateAdmin';
import { logout } from './redux/authRedux';
import { useEffect, useState } from 'react';
import AdminList from './components/AdminList';
import UpdateCategory from './components/UpdateCategory';
import UpdateAuthor from './components/UpdateAuthor';
import UpdatePublisher from './components/UpdatePublisher';
import UpdateBook from './components/UpdateBook';
import ChangePassword from './components/ChangePassword';
import MenuSettings from './components/MenuSettings';
import HomepageSetting from './components/HomepageSetting';
import AddCollection from './components/AddCollection';
import CollectionList from './components/CollectionList';
import UpdateCollection from './components/UpdateCollection';
import AddAds from './components/AddAds';
import Adslist from './components/Adslist';
import UpdateSection from './components/UpdateSection';
import MediaLibrary from './components/MediaLibrary';
import DraftBooks from './components/DraftBooks';
import UpdateAdmin from './components/UpdateAdmin';
import Updateads from './components/Updateads';
import UpdateSlider from './components/UpdateSlider';
import { Orders } from './components/Orders';
import { Readers } from './components/Readers';
import ActivityLogs from './components/ActivityLogs';
import { AddPage } from './components/AddPage';
import PageList from './components/PageList';
import { UpdatePage } from './components/UpdatePage';
import Post from './components/Post';
import AuthorClaim from './components/AuthorClaim';
import SmsGateway from './components/SmsGateway';
import ForgotPassword from './components/ForgotPassword';
import Subscribers from './components/Subscribers';
import UpdateLibrary from './components/UpdateLibrary';
import AddRole from './components/AddRole';
import AddAction from './components/AddAction';
import ActionList from './components/ActionList';
import RoleList from './components/RoleList';
import UpdateRole from './components/UpdateRole';
import Comments from './components/Comments';


function App() {
  const dispatch = useDispatch()
  const adminUser = useSelector((state) => state.userInfo.currentUser)


  // window.onbeforeunload = () => {
  //   signout()
  // }

  var delay = 60000;
  setTimeout(function () {
    isAuthenticated()? console.log("Connected") : dispatch(logout());
  }, delay);


  const sessionexpire = () =>{
    isAuthenticated()? console.log("Connected") : dispatch(logout());
  }

  useEffect(() => {
    sessionexpire()
  }, [])
  

  // let tags = useSelector((state)=>state.collection.collections);
  // console.log(tags);


  return (
    <BrowserRouter>
      <div className="App hero-area">
        {adminUser? 
        <>
          <Sidebar/>
          <div className="content-area">
            <Navbar/>
            <Routes>
              <Route exact path="/dashboard" element={<Dashboard/>}/>
              <Route path="/addbook" element={<AddBook/>}/>
              <Route exact path="/booklist" element={<BookList/>}/>
              <Route exact path="/categorylist" element={<CategoryList/>}/>
              <Route exact path="/addcategory" element={<AddCategory/>}/>
              <Route exact path="/login" element={<Login/>}/>
              <Route exact path="/addauthor" element={<AddAuthor/>}/>
              <Route exact path="/authorlist" element={<AuthorList/>}/>
              <Route exact path="/addpublisher" element={<AddPublisher/>}/>
              <Route exact path="/publisherlist" element={<PublisherList/>}/>
              <Route exact path="/sitesetting" element={<SiteSetting/>}/>
              <Route exact path="/addslider" element={<AddSlider/>}/>
              <Route exact path="/sliderlist" element={<SliderList/>}/>
              <Route exact path="/edit-slider/:id" element={<UpdateSlider/>}/>
              {/* <Route exact path="/addblog" element={<AddPost/>}/>
              <Route exact path="/bloglist" element={<BlogList/>}/> */}
              <Route exact path="/pendingreviews" element={<PendingReviews/>}/>
              <Route exact path="/publishedreviews" element={<PublishedReview/>}/>
              {/* <Route exact path="/addlibrary" element={<AddLibrary/>}/> */}
              <Route exact path="/librarylist" element={<LibraryList/>}/>
              <Route exact path="/createadmin" element={<CreateAdmin/>}/>
              <Route exact path="/adminlist" element={<AdminList/>}/>
              <Route exact path="/edit-category/:id" element={<UpdateCategory/>}/>
              <Route exact path="/edit-author/:id" element={<UpdateAuthor/>}/>
              <Route exact path="/edit-publisher/:id" element={<UpdatePublisher/>}/>
              <Route exact path="/edit-book/:id" element={<UpdateBook/>}/>
              <Route exact path="/edit-collection/:id" element={<UpdateCollection/>}/>
              <Route exact path="/changepassword" element={<ChangePassword/>} />
              <Route exact path="/menusettings" element={<MenuSettings/>} />
              <Route exact path="/edithomepage" element={<HomepageSetting/>} />
              <Route exact path="/edit-section/:id" element={<UpdateSection/>} />
              <Route exact path="/addcollection" element={<AddCollection/>} />
              <Route exact path="/collectionlist" element={<CollectionList/>} />
              <Route exact path="/addad" element={<AddAds/>} />
              <Route exact path="/addlist" element={<Adslist/>} />
              <Route exact path="/edit-ads/:id" element={<Updateads/>} />
              <Route exact path="/media" element={<MediaLibrary/>} />
              <Route exact path="/draftbook" element={<DraftBooks/>} />
              <Route exact path="/updateadmin/:id" element={<UpdateAdmin/>} />
              <Route exact path="/orders" element={<Orders/>} />
              <Route exact path="/readers" element={<Readers/>} />
              <Route exact path="/activities" element={<ActivityLogs/>} />
              <Route exact path="/createpage" element={<AddPage/>} />
              <Route exact path="/pagelist" element={<PageList/>} />
              <Route exact path="/edit-page/:id" element={<UpdatePage/>} />
              <Route exact path="/blogs" element={<Post/>} />
              <Route exact path="/authorclaim" element={<AuthorClaim/>} />
              <Route exact path="/smsgateway" element={<SmsGateway/>} />
              <Route exact path="/forgotpassword" element={<ForgotPassword/>} />
              <Route exact path="/subscribers" element={<Subscribers/>} />
              <Route exact path="/addlibrary" element={<AddLibrary/>} />
              <Route exact path="/librarylist" element={<LibraryList/>} />
              <Route exact path="/edit-library/:id" element={<UpdateLibrary/>} />
              <Route exact path="/addrole" element={<AddRole/>} />
              <Route exact path="/addaction" element={<AddAction/>} />
              <Route exact path="/actionlist" element={<ActionList/>} />
              <Route exact path="/rolelist" element={<RoleList/>} />
              <Route exact path="/edit-role/:id" element={<UpdateRole/>} />
              <Route exact path="/comments" element={<Comments/>} />
            </Routes>
          </div>
        </> : <Login/>}
      </div>
    </BrowserRouter>
  );
}

export default App;
