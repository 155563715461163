import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addAction } from '../util/api'
import { updateNotificationSuccess } from '../redux/notificationRedux';

export default function AddAction() {
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()
    let notification = useSelector((state)=> state.notification.notification)

    const handleSubmit = (e) =>{
        e.preventDefault()
        let action = { ...inputs};
        addAction(action)
        .then((res) => {
            if (res === undefined) {
                dispatch(updateNotificationSuccess({sms:"Action Added Failed",status:false}))
                setTimeout(() => {
                dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
            } else {
                dispatch(updateNotificationSuccess({sms:"Action Added Success",status:true}))
                navigate('/actionlist')
            }
        });
            
    }

    const handleChange = (e) =>{
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    }

    return (
        <>
        {/* {notification.status === true?
            <div className='notification-bar success'>
              <FontAwesomeIcon icon={faCheck} />
              <p>{notification.sms}</p>
            </div>
            : notification.status === false?
            <div className='notification-bar failed'>
              <FontAwesomeIcon icon={faTimes} />
              <p>{notification.sms}</p>
            </div>
            :
            ""
          } */}
        <div className="content-area-inner">
            <div className="container-fluid">
                <div className="pagination">
                    <Link to="/">Dashboard</Link>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <Link to="/">Add Action</Link>
                </div>
                <div className="heading">
                    <h1>Add Action</h1>
                </div>
                <form className="theme-form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme-card">
                                <div className="theme-card-body">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label>User Defined Name</label>
                                                <input type="text" tabIndex="1" name="userdefinedname" className="form-control" placeholder="Input Here" onChange={handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label>Action Name</label>
                                                <input type="text" tabIndex="1" name="actionname" className="form-control" placeholder="Input Here" onChange={handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label>Select Status</label>
                                                <select type="number" tabIndex="4" className="form-control" name="status" onChange={handleChange} required>
                                                    <option value="">Select Status</option>
                                                    <option value="true">Active</option>
                                                    <option value="false">Inactive</option>
                                                </select>
                                                <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-btn">
                                <button className="btn theme-btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
      )
}
