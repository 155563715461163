import React from 'react'
import ModalImage from "react-modal-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes
} from "@fortawesome/free-solid-svg-icons";

export function Image({data, handleDelete, handleUpload}) {


    return (
        <div className="single-upload-img" key={data._id}>
            {/* <img src={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${data.fileName}`} key={Math.random} /> */}
            {/* <LazyLoadImage
                    src={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${data.fileName}`}
                    effect="blur"
                    /> */}
            <ModalImage
                small={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${data.fileName}`}
                large={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${data.fileName}`}
                alt="Library Gallery"
            />
            <div className="upload-btn-group">
                <button
                className="up-btn"
                onClick={() => handleUpload(data.fileName)}
                >
                Insert Image
                </button>
            </div>
            <button
                className="delete-btn"
                onClick={() => handleDelete(data)}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
    )
}
