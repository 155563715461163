import React from 'react'
import { useState, useEffect } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilePdf,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { createAlbum, getPublishers, loadMedia, updatePublisher } from "../util/api";
import axios from 'axios';
import ModalImage from "react-modal-image";
import { SinglePublisherPhoto } from './SinglePublisherPhoto';
import { SingleAuthorPhoto } from './SingleAuthorPhoto';

export default function AuthorsAlbum({uploadImg}) {
  const [loader, setLoader] = useState(false);
  const [uploader, setuploader] = useState(false);
  let files = [];
  const dispatch = useDispatch();
  let images = [];
  let library = {};
  const [urls, setUrls] = useState([]);

  const media = useSelector((state) => state.media.allmedia);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false)
    }, 2000);
    loadMedia(dispatch);
  }, [])

  const handleChange = (e) =>{
    setuploader(true);
    files = e.target.files;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      library = { fileName: files[i].name, fileType: files[i].type, fileFor: "authorsPhoto" };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/media/add`, library)
        .then(() => {
          console.log("success");
        })
        .catch((e) => {
          console.log("error", e);
        });
      setUrls((prevState) => [...prevState, library.fileName]);
      data.append("file", files[i]);
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/authorsphoto`, data)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => {
        loadMedia(dispatch)
        setuploader(false)
      });
  }
  const handleDelete = (imagename) => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/media/${imagename._id}`)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      });

    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/deletemedia/authorsphoto/${imagename.fileName}`)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => loadMedia(dispatch));
  }

  const handleUpload = (fileName) => {
    uploadImg(fileName);
  }
  

  return (
    <div className="image-gallery publisherPhotos">
      <div className="single-upload-img">
        {uploader?
          <p style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",border:"1px solid #0d99ff",borderRadius:"5px",fontWeight:"600",color:"#0d99ff"}}>Uploading</p>
          :
          <form>
            <h1>Drop Files Here To Upload</h1>
            <p>Or</p>
            <input type="file" multiple onChange={handleChange} />
            <a>Select Files</a>
          </form>
        }
      </div>
      {media.map((data)=>{
          if (data.fileFor === 'authorsPhoto') {
              return <SingleAuthorPhoto data={data} handleDelete={handleDelete} handleUpload={handleUpload} />
          }else{

          }
        })
      }
    </div>
  )
}
