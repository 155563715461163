import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes, faCheck} from "@fortawesome/free-solid-svg-icons"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import slugify from 'react-slugify';
import {useDispatch,useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { addLibrary } from '../util/api';
import {updateNotificationSuccess} from '../redux/notificationRedux'
import MediaLibrary from './MediaLibrary';

export default function AddLibrary() {
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false)
    const [imgurl, setimgUrl] = useState(null);
    const [slug, setSlug] = useState("");
    const [description, setdescription] = useState("");
    const [startDate, setStartDate] = useState("");
    const navigate = useNavigate()

    const uploadImg = (childData) =>{
        setModal(!modal)
        setimgUrl(childData)
    }

    const handleChange = (e) => {
        setInputs((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
        });
        setSlug(slugify(inputs.nameInEnglish))
    };
    const handleClick = (e) => {
        e.preventDefault();
        setLoader(true);
        setTimeout(() => {
        setLoader(false);
        }, 3000);
        let library = { ...inputs };
        if (imgurl) {
            library = { ...inputs, logo: imgurl, librarySlug:slug, established: startDate, description:description };
            addLibrary(library).then((res) => {
                if (res.status === 201) {
                    dispatch(updateNotificationSuccess({sms:"Library Added Success",status:true}))
                    setTimeout(() => {
                        dispatch(updateNotificationSuccess({sms:"",status:null}))
                    }, 2000);
                } else {
                    dispatch(updateNotificationSuccess({sms:"Library Added Failed",status:false}))
                    setTimeout(() => {
                        dispatch(updateNotificationSuccess({sms:"",status:null}))
                    }, 2000);
                }
                navigate("/librarylist")
            });
        } else {
            library = { ...inputs, librarySlug:slug, established: startDate, description:description };
            addLibrary(library).then((res) => {
                if (res.status === 201) {
                    dispatch(updateNotificationSuccess({sms:"Library Added Success",status:true}))
                    setTimeout(() => {
                        dispatch(updateNotificationSuccess({sms:"",status:null}))
                    }, 2000);
                } else {
                    dispatch(updateNotificationSuccess({sms:"Library Added Failed",status:false}))
                    setTimeout(() => {
                        dispatch(updateNotificationSuccess({sms:"",status:null}))
                    }, 2000);
                }
                navigate("/librarylist")
            });
        }
    };
    let notification = useSelector((state)=> state.notification.notification)
    const handleRemoveImg = (e) =>{
        e.preventDefault()
        setimgUrl(null);
    }
  return (
    <>
    {modal? <div className="floating-media">
      <button className="close-modal" onClick={()=>setModal(!modal)}><FontAwesomeIcon icon={faTimes}/></button>
      <MediaLibrary className="author" uploadImg={uploadImg}/>
    </div> : ""}
    {notification.status === true?
        <div className='notification-bar success'>
          <FontAwesomeIcon icon={faCheck} />
          <p>{notification.sms}</p>
        </div>
        : notification.status === false?
        <div className='notification-bar failed'>
          <FontAwesomeIcon icon={faTimes} />
          <p>{notification.sms}</p>
        </div>
        :
        ""
      }
    <div className="content-area-inner">
        <div className="container-fluid">
            <div className="pagination">
                <Link to="/">Dashboard</Link>
                <FontAwesomeIcon icon={faAngleRight} />
                <Link to="/">Add Library</Link>
            </div>
            <div className="heading">
                <h1>Library Details</h1>
            </div>
            <form className="theme-form" onSubmit={handleClick}>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="theme-card">
                            <div className="theme-card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Library Name (বাংলায় লিখুন)</label>
                                            <input type="text" name='name' className="form-control" onChange={handleChange} placeholder="Input Here" required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Library Name (Write in English)</label>
                                            <input type="text" name='nameInEnglish' className="form-control" onChange={handleChange} placeholder="Input Here" required/>
                                            <p>Press Tab for slug</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input type="email" name='email' className="form-control" onChange={handleChange} placeholder="Input Here" required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Slug</label>
                                            <input type="text" value={slug} className="form-control" placeholder="Input Here"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Owner Name</label>
                                            <input type="text" name='owner' className="form-control" onChange={handleChange} placeholder="Input Here" required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input type="text" name='address' className="form-control" onChange={handleChange} placeholder="Input Here" required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Established Date</label>
                                            {/* <input type="number" className="form-control" placeholder="Select Date"/> */}
                                            <DatePicker selected={startDate} onChange={(date:Date) => setStartDate(date)} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Select Member Type</label>
                                                <select className="form-control" name='memberType' onChange={handleChange}>
                                                    <option >Select</option>
                                                    <option value="general">General</option>
                                                    <option value="vip">Vip</option>
                                                </select>
                                            <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Select Access Type</label>
                                                <select className="form-control" name='accessType' onChange={handleChange}>
                                                    <option >Select</option>
                                                    <option value="public">Public</option>
                                                    <option value="private">Private</option>
                                                </select>
                                            <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Do you have internet access</label>
                                                <select className="form-control" name='internetAccess' onChange={handleChange}>
                                                    <option >Select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Do you have archieve</label>
                                                <select className="form-control" name='archieve' onChange={handleChange}>
                                                    <option >Select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Do you have printers</label>
                                                <select className="form-control" name='hasPrinter' onChange={handleChange}>
                                                    <option >Select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Do you have photocopier</label>
                                                <select className="form-control" name='hasPhotocopier' onChange={handleChange}>
                                                    <option >Select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Do you grant book issue</label>
                                                <select className="form-control" name='bookIssue' onChange={handleChange}>
                                                    <option >Select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>How much book do you have</label>
                                            <input type="number" name='bookCount' className="form-control" onChange={handleChange} placeholder="Input Here"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>How much member do you have</label>
                                            <input type="number" name='memberCount' className="form-control" onChange={handleChange} placeholder="Input Here"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>How much seat do you have</label>
                                            <input type="number" name='seatCount' className="form-control" onChange={handleChange} placeholder="Input Here"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Enter map embaded code</label>
                                            <input type="text" name='map' className="form-control" onChange={handleChange} placeholder="Input Here"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Enter mobile no</label>
                                            <input type="text" name='mobile' className="form-control" onChange={handleChange} placeholder="Input Here"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Select Status</label>
                                                <select className="form-control" name='status' onChange={handleChange} required>
                                                    <option >Select</option>
                                                    <option value="0">Active</option>
                                                    <option value="1">Inactive</option>
                                                </select>
                                            <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            {/* <textarea placeholder="Your desccription here" className="form-control" style={{height:"200px"}}></textarea> */}
                                            <CKEditor
                                                editor={ ClassicEditor }
                                                data="<p>Your description here</p>"
                                                onReady={ editor => {
                                                    console.log( 'Editor is ready to use!', editor );
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    setdescription(data)
                                                } }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group-btn">
                            <button className="btn theme-btn" type='submit'>Submit</button>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="theme-card">
                            <div className="theme-card-body">
                                {imgurl? <button className="btn close" onClick={handleRemoveImg}><FontAwesomeIcon icon={faTimes} /></button> : ""}
                                {imgurl? <img src={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${imgurl}`} className="w-100" onClick={uploadImg} /> : <div className="form-group upload-img" onClick={uploadImg}>
                                    <label>
                                    <svg width="72" height="57" viewBox="0 0 72 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M68.0057 10.3334V7.00004H60.8858V10.3334H56.3326L52.7727 0.333374H19.1595L15.5995 10.3334H0.366455V57H71.5657V10.3334H68.0057ZM64.4458 50.3334H7.48638V17H20.733L24.293 7.00004H47.6392L51.1992 17H64.4458V50.3334Z" fill="#E1E1E1"/>
                                    <path d="M35.9661 17C32.7977 17 29.7004 17.8797 27.066 19.528C24.4315 21.1762 22.3782 23.5189 21.1657 26.2597C19.9532 29.0006 19.636 32.0166 20.2541 34.9264C20.8722 37.8361 22.398 40.5088 24.6384 42.6066C26.8788 44.7044 29.7333 46.133 32.8408 46.7118C35.9484 47.2906 39.1694 46.9935 42.0966 45.8582C45.0239 44.7229 47.5258 42.8003 49.2861 40.3336C51.0464 37.8668 51.986 34.9667 51.986 32C51.9812 28.0231 50.2919 24.2104 47.2887 21.3983C44.2854 18.5862 40.2134 17.0044 35.9661 17ZM35.9661 40.3333C34.2059 40.3333 32.4852 39.8446 31.0216 38.9289C29.558 38.0132 28.4173 36.7117 27.7437 35.189C27.0701 33.6663 26.8938 31.9908 27.2372 30.3742C27.5806 28.7577 28.4283 27.2729 29.6729 26.1074C30.9176 24.942 32.5034 24.1483 34.2298 23.8268C35.9563 23.5052 37.7457 23.6703 39.372 24.301C40.9982 24.9317 42.3882 25.9998 43.3661 27.3702C44.3441 28.7407 44.866 30.3518 44.866 32C44.866 34.2101 43.9284 36.3298 42.2593 37.8926C40.5903 39.4554 38.3265 40.3333 35.9661 40.3333Z" fill="#E1E1E1"/>
                                    </svg>
                                    Click here to upload Images
                                    </label>
                                    <div className="preview-img">
                                    <img className="w-100"/>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    </>
  )
}
