import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes, faPlusCircle, faCheck} from "@fortawesome/free-solid-svg-icons"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import slugify from 'react-slugify';
import { useDispatch,useSelector } from 'react-redux';
import MediaLibrary from './MediaLibrary';
// import { createBrowserHistory } from "history";
import { useNavigate } from 'react-router-dom';
import { addCollection, addPublisher } from '../util/api';
import SingleCollectionBook from './SingleCollectionBook';
import { updateNotificationSuccess } from '../redux/notificationRedux';

export default function AddCollection() {
    // const history = createBrowserHistory({ forceRefresh: true });
    const [inputs, setInputs] = useState({});
    const [slug, setSlug] = useState("");
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false)
    const [imgurl, setimgUrl] = useState(null);
    const [langType, setlangType] = useState("")
    const navigate = useNavigate()
    const books = useSelector((state) => state.book.books);
    const publishers = useSelector((state) => state.publisher.publishers);
    const authors = useSelector((state) => state.author.authors);
    const categories = useSelector((state) => state.category.categories);
    const tags = useSelector((state) => state.tag.tags);
    const [queryPublisher, setQueryPublisher] = useState("");
    const [queryAuthor, setQueryAuthor] = useState("");
    const [queryCategory, setqueryCategory] = useState("");
    const [queryTags, setqueryTags] = useState("");
    const [filteredProducts, setfilteredProducts] = useState(books)
    let notification = useSelector((state)=> state.notification.notification)

    const lngtype = (value) => {
        var text = value.replace(/\s/g)
        //Dictionary for Unicode range of the languages
        var langdic = {
          "arabic" : /[\u0600-\u06FF]/,
          "persian" : /[\u0750-\u077F]/,
          "Hebrew" : /[\u0590-\u05FF]/,
          "Syriac" : /[\u0700-\u074F]/,
          "Bengali" : /[\u0980-\u09FF]/,
          "Ethiopic" : /[\u1200-\u137F]/,
          "Greek and Coptic" : /[\u0370-\u03FF]/,
          "Georgian" : /[\u10A0-\u10FF]/,
          "Thai" : /[\u0E00-\u0E7F]/,
          "english" : /^[a-zA-Z]+$/
            //add other languages her
        }  
        //const keys = Object.keys(langdic); //read  keys
        //const keys = Object.values(langdic); //read  values
        const keys = Object.entries(langdic); // read  keys and values from the dictionary
        Object.entries(langdic).forEach(([key, value]) => {  // loop to read all the dictionary items if not true
          if (value.test(text) === true){   //Check Unicode to see which one is true
            return setlangType(key); //print language name if unicode true   
          }
        });
    }

    const cateSearch = (e)=>{
        setqueryCategory(e.target.value)
        lngtype(e.target.value);
    }
    const publisherSearch = (e)=>{
        setQueryPublisher(e.target.value)
        lngtype(e.target.value);
    }
    const authorSearch = (e)=>{
        setQueryAuthor(e.target.value)
        lngtype(e.target.value);
    }
    const tagSearch = (e)=>{
        setqueryTags(e.target.value)
        // lngtype(e.target.value);
    }

    let newbooks = []

    const filterSalesType = (e) =>{
        let salesType = e.target.value;
        if (salesType) {
          books.map((e)=>{
            if (e.saleType === salesType) {
                newbooks = [...newbooks, e]
            }
          })
          setfilteredProducts(newbooks)
        }else{
          setfilteredProducts(books);
        }
    }
    const filterCategoryType = (e) =>{
        let categoryId = e.target.value;
        setqueryCategory(e.target.placeholder)
        if (categoryId) {
          books.map((e)=>{
            if (e.categoryId === categoryId) {
                newbooks = [...newbooks, e]
            }
          })
          setfilteredProducts(newbooks)
        }else{
          setfilteredProducts(books);
        }
    }
    const filterPublisherType = (e) =>{
        let publisherId = e.target.value;
        setQueryPublisher(e.target.placeholder)
        if (publisherId) {
          filteredProducts.map((e)=>{
            if (e.publisherId === publisherId) {
                newbooks = [...newbooks, e]
            }
          })
          setfilteredProducts(newbooks)
        }else{
          setfilteredProducts(filteredProducts);
        }
    }
    const filterAuthorType = (e) =>{
        let authorId = e.target.value;
        setQueryAuthor(e.target.placeholder)
        if (authorId) {
          filteredProducts.map((e)=>{
            if (e.authorId === authorId) {
                newbooks = [...newbooks, e]
            }
          })
          setfilteredProducts(newbooks)
        }else{
          setfilteredProducts(filteredProducts);
        }
    }
    const filterTagType = (e) =>{
        let tagname = e.target.placeholder;
        setqueryTags(e.target.placeholder)
        if (tagname) {
          filteredProducts.map((e)=>{
            e.booktags.map((tag)=>{
                if (tag.tagName === tagname) {
                    newbooks = [...newbooks, e]
                }
            })
          })
          setfilteredProducts(newbooks)
        }else{
          setfilteredProducts(filteredProducts);
        }
    }

    const reset = (e) =>{
        setfilteredProducts(books)
        setQueryAuthor("")
        setQueryPublisher("") 
        setqueryCategory("")
        setqueryTags("")
    }

    const handleChange = (e) => {
        setInputs((prev) => {
          return { ...prev, [e.target.name]: e.target.value };
        });
        setSlug(slugify(inputs.title, {locale: 'Bn'}));
    };

    const uploadImg = (childData) =>{
        setModal(!modal)
        setimgUrl(childData)
    }

    const [collectionBooks, setcollectionBooks] = useState([])
    const handleCheck = (event) => {
        var updatedList = [...collectionBooks];
        if (event.target.checked) {
            updatedList = [...collectionBooks, event.target.value];
        } else {
            updatedList.splice(collectionBooks.indexOf(event.target.value), 1);
        }
        setcollectionBooks(updatedList);
    };

    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 3000);
        let collection = { ...inputs};
        if (imgurl) {
            collection = { ...inputs, image: imgurl, books:collectionBooks, slug:slug};
            addCollection(collection, dispatch)
            .then((res) => {
                if (res.response?.status === 401) {
                    if (res.response?.data === "Collection already exists") {
                        dispatch(updateNotificationSuccess({sms:"Slug already exist",status:false}))
                        setTimeout(() => {
                            dispatch(updateNotificationSuccess({sms:"",status:null}))
                        }, 2000);
                    } else {
                        dispatch(updateNotificationSuccess({sms:"Collection Added Failed",status:false}))
                        setTimeout(() => {
                            dispatch(updateNotificationSuccess({sms:"",status:null}))
                        }, 2000);
                    }
                } else {
                    dispatch(updateNotificationSuccess({sms:"Collection Added Success",status:true}))
                    navigate('/collectionlist')
                }
            });
            
        } else {
            collection = { ...inputs, image: "", books:collectionBooks, slug:slug};
            addCollection(collection, dispatch)
            .then((res) => {
                if (res.response?.status === 401) {
                    if (res.response?.data === "Collection already exists") {
                        dispatch(updateNotificationSuccess({sms:"Slug already exist",status:false}))
                        setTimeout(() => {
                            dispatch(updateNotificationSuccess({sms:"",status:null}))
                        }, 2000);
                    } else {
                        dispatch(updateNotificationSuccess({sms:"Collection Added Failed",status:false}))
                        setTimeout(() => {
                            dispatch(updateNotificationSuccess({sms:"",status:null}))
                        }, 2000);
                    }
                } else {
                    dispatch(updateNotificationSuccess({sms:"Collection Added Success",status:true}))
                    navigate('/collectionlist')
                }
            });
        }
    }

    const handleRemoveImg = (e) =>{
        e.preventDefault()
        setimgUrl(null);
    }


  return (
    <>
    {notification.status === true?
        <div className='notification-bar success'>
          <FontAwesomeIcon icon={faCheck} />
          <p>{notification.sms}</p>
        </div>
        : notification.status === false?
        <div className='notification-bar failed'>
          <FontAwesomeIcon icon={faTimes} />
          <p>{notification.sms}</p>
        </div>
        :
        ""
      }
    {modal? <div className="floating-media">
      <button className="close-modal" onClick={()=>setModal(!modal)}><FontAwesomeIcon icon={faTimes}/></button>
      <MediaLibrary className="author" uploadImg={uploadImg}/>
    </div> : ""}
    <div className="content-area-inner">
        <div className="container-fluid">
            <div className="pagination">
                <Link to="/">Dashboard</Link>
                <FontAwesomeIcon icon={faAngleRight} />
                <Link to="/">Add Collection</Link>
            </div>
            <div className="heading">
                <h1>Add Collection</h1>
            </div>
            <form className="theme-form" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme-card">
                            <div className="theme-card-body">
                                <div className="row">
                                    <div className='col-lg-9'>
                                        <div className='row'>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Collection Name (Write in Bangla)</label>
                                                    <input type="text" tabIndex="1" name="titleInBangla" className="form-control" placeholder="Input Here" onChange={handleChange} required/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Collection Name (Write in English)</label>
                                                    <input type="text" tabIndex="2" name="title" className="form-control" placeholder="Input Here" onChange={handleChange} required/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Collection slug</label>
                                                    <input type="text" tabIndex="3" name="slug" className="form-control" value={slug} placeholder="Input Here" onFocus={handleChange} required/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Select Status</label>
                                                    <select type="number" tabIndex="4" className="form-control" name="status" onChange={handleChange} required>
                                                        <option value="">Select Status</option>
                                                        <option value="0">Active</option>
                                                        <option value="1">Inactive</option>
                                                    </select>
                                                    <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    <div className='col-lg-3'>
                                        <div className="theme-card">
                                            <div className="theme-card-body">
                                                {imgurl? <button className="btn close" onClick={handleRemoveImg}><FontAwesomeIcon icon={faTimes} /></button> : ""}
                                                {imgurl? <img src={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${imgurl}`} className="w-100" onClick={uploadImg} /> : <div className="form-group upload-img" onClick={uploadImg}>
                                                    <label>
                                                    <svg width="72" height="57" viewBox="0 0 72 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M68.0057 10.3334V7.00004H60.8858V10.3334H56.3326L52.7727 0.333374H19.1595L15.5995 10.3334H0.366455V57H71.5657V10.3334H68.0057ZM64.4458 50.3334H7.48638V17H20.733L24.293 7.00004H47.6392L51.1992 17H64.4458V50.3334Z" fill="#E1E1E1"/>
                                                    <path d="M35.9661 17C32.7977 17 29.7004 17.8797 27.066 19.528C24.4315 21.1762 22.3782 23.5189 21.1657 26.2597C19.9532 29.0006 19.636 32.0166 20.2541 34.9264C20.8722 37.8361 22.398 40.5088 24.6384 42.6066C26.8788 44.7044 29.7333 46.133 32.8408 46.7118C35.9484 47.2906 39.1694 46.9935 42.0966 45.8582C45.0239 44.7229 47.5258 42.8003 49.2861 40.3336C51.0464 37.8668 51.986 34.9667 51.986 32C51.9812 28.0231 50.2919 24.2104 47.2887 21.3983C44.2854 18.5862 40.2134 17.0044 35.9661 17ZM35.9661 40.3333C34.2059 40.3333 32.4852 39.8446 31.0216 38.9289C29.558 38.0132 28.4173 36.7117 27.7437 35.189C27.0701 33.6663 26.8938 31.9908 27.2372 30.3742C27.5806 28.7577 28.4283 27.2729 29.6729 26.1074C30.9176 24.942 32.5034 24.1483 34.2298 23.8268C35.9563 23.5052 37.7457 23.6703 39.372 24.301C40.9982 24.9317 42.3882 25.9998 43.3661 27.3702C44.3441 28.7407 44.866 30.3518 44.866 32C44.866 34.2101 43.9284 36.3298 42.2593 37.8926C40.5903 39.4554 38.3265 40.3333 35.9661 40.3333Z" fill="#E1E1E1"/>
                                                    </svg>
                                                    Click here to upload Images
                                                    </label>
                                                    <div className="preview-img">
                                                    <img className="w-100"/>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <div className='row'>
                                                <div className='col-lg-3'>
                                                    <label>Select Collection Books</label>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <div className='d-flex'>
                                                        <div className='collection-filter-option'>
                                                            <input type="text" placeholder='Search Category' value={queryCategory} onChange={cateSearch}/>
                                                            <FontAwesomeIcon icon={faSearch} />
                                                            <ul>
                                                                {categories.filter(cate=> cate.categoryType === 'book').filter(cate=>{
                                                                    if (queryCategory === '') {
                                                                        return ""
                                                                    }else if (langType === 'Bengali'? cate.nameinBangla?.toLowerCase().includes(queryCategory.toLowerCase()) : cate.nameinEnglish?.toLowerCase().includes(queryCategory.toLowerCase())){
                                                                        return cate
                                                                    }
                                                                }).map((cate)=>(
                                                                    <li>
                                                                        <label htmlFor={cate._id}>{cate.nameinBangla}</label>
                                                                        <input
                                                                            id={cate._id}
                                                                            name="categoryId"
                                                                            value={cate._id}
                                                                            placeholder={cate.nameinBangla}
                                                                            onClick={(e)=>filterCategoryType(e)}
                                                                            className="child-input d-block"
                                                                        />
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className='collection-filter-option'>
                                                            <input type="text" placeholder='Search Publisher' value={queryPublisher} onChange={publisherSearch}/>
                                                            <FontAwesomeIcon icon={faSearch} />
                                                            <ul>
                                                                {publishers.filter(publisher=> publisher.status === 0).filter(publisher=>{
                                                                    if (queryPublisher === '') {
                                                                        return ""
                                                                    }else if (langType === 'Bengali'? publisher.bn_publication?.toLowerCase().includes(queryPublisher.toLowerCase()) : publisher.en_publication?.toLowerCase().includes(queryPublisher.toLowerCase())){
                                                                        return publisher
                                                                    }
                                                                }).map((publisher)=>(
                                                                    <li>
                                                                        <label htmlFor={publisher._id}>{publisher.bn_publication}</label>
                                                                        <input
                                                                            id={publisher._id}
                                                                            name="publisherId"
                                                                            value={publisher._id}
                                                                            placeholder={publisher.bn_publication}
                                                                            onClick={(e)=>filterPublisherType(e)}
                                                                            className="child-input d-block"
                                                                        />
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className='collection-filter-option'>
                                                            <input type="text" placeholder='Search Author' value={queryAuthor} onChange={authorSearch}/>
                                                            <FontAwesomeIcon icon={faSearch} />
                                                            <ul>
                                                                {authors.filter(author=> author.status === 0).filter(author=>{
                                                                    if (queryAuthor === '') {
                                                                        return ""
                                                                    }else if (langType === 'Bengali'? author.bn_author?.toLowerCase().includes(queryAuthor.toLowerCase()) : author.en_author?.toLowerCase().includes(queryAuthor.toLowerCase())){
                                                                        return author
                                                                    }
                                                                }).map((author)=>(
                                                                    <li>
                                                                        <label htmlFor={author._id}>{author.bn_author}</label>
                                                                        <input
                                                                            id={author._id}
                                                                            name="AuthorId"
                                                                            value={author._id}
                                                                            placeholder={author.bn_author}
                                                                            onClick={(e)=>filterAuthorType(e)}
                                                                            className="child-input d-block"
                                                                        />
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className='collection-filter-option'>
                                                            <input type="text" placeholder='Search Tags' value={queryTags} onChange={tagSearch}/>
                                                            <FontAwesomeIcon icon={faSearch} />
                                                            <ul>
                                                                {tags.filter(tag=>{
                                                                    if (queryTags === '') {
                                                                        return ""
                                                                    }else if (tag.tagname?.toLowerCase().includes(queryTags.toLowerCase())){
                                                                        return tag
                                                                    }
                                                                }).map((tag)=>(
                                                                    <li>
                                                                        <label htmlFor={tag._id}>{tag.tagname}</label>
                                                                        <input
                                                                            id={tag._id}
                                                                            name="tagId"
                                                                            value={tag._id}
                                                                            placeholder={tag.tagname}
                                                                            onClick={(e)=>filterTagType(e)}
                                                                            className="child-input d-block"
                                                                        />
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className='collection-filter-option'>
                                                            <select onChange={filterSalesType}>
                                                                <option value="">Sale Type</option>
                                                                <option value="regular">Regular</option>
                                                                <option value="preorder">Pre-Order</option>
                                                            </select>
                                                        </div>
                                                        <button className='btn theme-btn' type='button' onClick={reset}>Reset</button>
                                                            
                                                    </div>
                                                </div>
                                            </div>

                                            <ul>
                                                {
                                                    filteredProducts.filter(book => book.status === 0).map((book)=>(
                                                        // <SingleCollectionBook book={book} handleAddBook={handleAddBook(book._id)}/>
                                                        <div className='collectionBook' key={book._id}>
                                                            <input type="checkbox" id={book._id} value={book._id} onChange={handleCheck}/>
                                                            <label htmlFor={book._id}>{book.nameInBangla}</label>
                                                        </div>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group-btn">
                            <button className="btn theme-btn">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    </>
  )
}
