import React, { useEffect, useState } from "react";
import img from '../img/Bohubrihi-Humayun.png'
import threeDot from "../img/Frame 179.png";
import { getOrders } from "../util/api";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";



const DashboardTopCustomers = () => {

  const [orders, setorders] = useState([]);
  const [orderPerPage, setorderPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * orderPerPage;

  const books = useSelector((state)=>state.book.books)
  const currentUser = useSelector((state)=>state.userInfo.currentUser)


  useEffect(() => {
    getOrders().then((res) => {
      setorders(res.data);
    });
  }, [currentUser]);

  const displayOrder = orders
    .slice(pageVisited, pageVisited + orderPerPage).sort((a,b) =>  a > b ? -1 : 1)
    .map((order) => {
      let date = new Date(order.createdAt);
      return (
       <>
            {
              order.products.map((product)=>(      
              books.map((book)=>(
                  book._id === product.productId? 
                      // <li className='single-ordered-product'>
                      //     <div className='order-img'>
                              
                      //     </div>
                      //     <div className='order-info'>
                      //         <h5>{book.nameInBangla} - {book.authorBnName}</h5>
                      //         <p>Publisher: <b>{book.publisherBnName}</b></p>
                      //         <p>Quantity: <b>{product.quantity}</b></p>
                      //     </div>
                      //     <div className='order-price-info'>
                      //         <p>Cover Price : <b>৳{book.coverPrice}</b></p>
                      //         <p>Sale Price : <b>৳{product.price}</b></p>
                      //         <p>Discount : <b>{Math.ceil(book.discount)}%</b></p>
                      //     </div>
                      // </li>

                      // table 

                      <tr >
              <td>
                <div className="">
                   <img src={book.cover? `${process.env.REACT_APP_BASE_URL}/api/alluploads/${book.publisherId}/${book.cover}` : `${process.env.REACT_APP_BASE_URL}/api/alluploads/default-book.png`} className="table-img-set" alt='' />
                  <span className="table-book-name ms-2">{book.nameInEnglish}</span>
                </div>
              </td>

              <td>
                {" "}
                <p className="mt-2"> {book.authorEnName} </p>{" "}
              </td>

              <td>
                {" "}
                <p className="mt-2"> {Math.ceil(order.amount)} </p>{" "}
              </td>
            </tr>
                  :
                  ""
              ))
          ))
      }
      </>

        // <tr key={order._id}>
        //   <th scope="row">{order.transaction_id}</th>
        //   <td>{order.reader.fullname}</td>
        //   <td> <span className="ms-3">{order.products[0].quantity}</span> </td>
        //   <td>{order.amount}</td>
        //   <td>{date.toDateString()}</td>
        //   <td>{order.address.city}</td>
        //   <td>
        //     {order.deliverystatus === "Shipped" ? (
        //       <p className="Shipped-text-set text-center py-1 fw-bold">
        //         Shipped
        //       </p>
        //     ) : order.deliverystatus === "Cancel" ? (
        //       <p className="text-center canceled-text-set  py-1 fw-bold">
        //         Canceled
        //       </p>
        //     ) : order.deliverystatus === "ReadyToShip" ? (
        //       <p className="Shipped-text-set text-center py-1 fw-bold">
        //         Processing
        //       </p>
        //     ) : order.deliverystatus === "Delivered" ? (
        //       <p className="Shipped-text-set text-center py-1 fw-bold">
        //         Delivered
        //       </p>
        //     ) : (
        //       <p className="text-center pending-text-set py-1 fw-bold">
        //         Pending
        //       </p>
        //     )}
        //   </td>
        // </tr>

      );

    });

  const pageCount = Math.ceil(orders.length / orderPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between">
        <p className="fs-5 fw-bold"> Top Customers </p>
        <button className="three-dot-button">
          <img src={threeDot} alt="" />
        </button>
      </div>

      {/* table */}

      <div className="mt-3">
        <table class="table">
          <thead className="border-set">
            <tr>
              <th scope="col">Book</th>
              <th scope="col">Author</th>
              <th scope="col">Sales</th>
            </tr>
          </thead>

          <tbody className="table-text-set">
            {displayOrder}

            
            
             {/* <tr>
              <td>
                <div className="">
                  <img src={img} alt="" className="table-img-set" />
                  <span className="table-book-name ms-2">Holud Himu Kal</span>
                </div>
              </td>

              <td>
                {" "}
                <p className="mt-2"> Kazi Najrul Islam </p>{" "}
              </td>

              <td>
                {" "}
                <p className="mt-2"> 70 </p>{" "}
              </td>
            </tr>

            <tr className="event-table-row">
              <td>
                <div className="">
                  <img src={img} alt="" className="table-img-set" />
                  <span className="table-book-name ms-2">Holud Himu Kal</span>
                </div>
              </td>

              <td>
                {" "}
                <p className="mt-2"> Kazi Najrul Islam </p>{" "}
              </td>

              <td>
                {" "}
                <p className="mt-2"> 70 </p>{" "}
              </td>
            </tr>

            <tr>
              <td>
                <div className="">
                  <img src={img} alt="" className="table-img-set" />
                  <span className="table-book-name ms-2">Holud Himu Kal</span>
                </div>
              </td>

              <td>
                {" "}
                <p className="mt-2"> Kazi Najrul Islam </p>{" "}
              </td>
              <td>
                {" "}
                <p className="mt-2"> 70 </p>{" "}
              </td>
            </tr> */}

            

          </tbody>
        </table>
      </div>

      <div>
       <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination"}
          activeClassName={"actvie-page"}
        />
      </div>

    </div>
  );
};

export default DashboardTopCustomers;
