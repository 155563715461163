import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faList,
  faAngleDown,
  faUser,
  faUsers,
  faBlog,
  faCommentAlt,
  faGear,
  faGears,
  faCrown,
  faPhotoFilm,
  faFileInvoiceDollar,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import booksvg from "../img/book.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import jwt_decode from "jwt-decode"

export default function MenuItem({ data }) {
  const [open, setopen] = useState(false);
  // let user = useSelector((state)=>state.userInfo.currentUser);
  // console.log(user)
  return (
    <li className={open ? "active" : ""}>
      {data.submenu ? (
        <div className="menu-item" onClick={() => setopen(!open)}>
          {/* <FontAwesomeIcon icon={data.icon} /> */}
          {/* <img src={data.icon} /> */}
          {data.title}
        </div>
      ) : (
        <Link to={data.link ? data.link : "/"} className="menu-item" onClick={() => setopen(!open)}>
          {/* <FontAwesomeIcon icon={data.icon} /> */}
          {/* <img src={data.icon} /> */}
          {data.title}
        </Link>
      )}
      {data.submenu ? (
        <ul className={open ? "submenu show" : "submenu"}>
          {data.submenu?.map((sub, index) => (
            <li className="menu-item" key={index}>
              <Link to={sub.link? sub.link : "/"} >{sub.title}</Link>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      {data.submenu ? <FontAwesomeIcon icon={faAngleDown} /> : ""}
    </li>
  );
}
