import React from 'react'
import { useState } from 'react'
import { addMenu } from '../util/api'
import { useSelector, useDispatch } from 'react-redux';
import SingleMenuItem from './SingleMenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteMenu } from '../util/api';

export default function Submenu({menuu, page}) {
  const [submenuItem, setsubmenuItem] = useState(null)
  const dispatch = useDispatch()
  const menues = useSelector((state)=> state.menu.menues)
  const [isSubmenuFound, setisSubmenuFound] = useState(null)

  const handleSubmenuitemAdd = (e) => {
    e.preventDefault();
    addMenu({item:submenuItem, type:"submenuitem", parentId:menuu._id}, dispatch)
    setsubmenuItem("")
  }

//   const removeItem = (id) => {
//     menues.filter((menu) => {
//       if (menu.parentId === id) {
//         return setisSubmenuFound(true)
//       }
//     });
//   };

  const removeItemConfirm = (id) => {
    deleteMenu(id, dispatch)
    setisSubmenuFound(null)
  };

  const removeItemDenied = () =>{
    setisSubmenuFound(null)
  }

  return (
    <>
    {isSubmenuFound === true ? (
          <div className="dialougeBox">
            <div className="dialougeBoxInner">
              <p>This submenu has multiple item.First change them then delete it</p>
              <button className="btn theme-btn" onClick={removeItemDenied}>Ok</button>
            </div>
          </div>
      ) : isSubmenuFound === false ? (
        <div className="dialougeBox">
          <div className="dialougeBoxInner">
            <p>Are you sure? You want to delete it?</p>
            <button className="btn theme-btn" onClick={()=>removeItemConfirm(menuu._id)}>Yes</button>
            <button className="btn theme-btn" onClick={removeItemDenied}>No</button>
          </div>
        </div>
      ) : (
        ""
    )}
    <div className="theme-card-body">
        <form className="theme-form mb-5" onSubmit={handleSubmenuitemAdd}>
            <div className="row"> 
                <div className="col-lg-6 d-flex align-items-center">
                    <h4 className="theme-card-heading mb-0">Add item to:<b>{menuu.item}</b> <FontAwesomeIcon icon={faTrash} color="red" onClick={()=>removeItemConfirm(menuu._id)} /></h4>
                </div>
                <div className="col-lg-6">
                    <div className="form-gropup d-flex">
                        <input type="text" className="form-control" placeholder="Enter submenu item" value={submenuItem} onChange={(e)=>setsubmenuItem(e.target.value)} />
                        <button type="submit" className="btn theme-btn mb-0" style={{borderRadius:"3px"}}>Add</button>
                    </div>
                </div>
            </div>
        </form>
        <ul className="table-header">
            <li>Status</li>
            <li>Name</li>
            <li>Route Type</li>
            <li>Route</li>
            <li>Menu type</li>
            <li>Menu place</li>
            <li>Parent Menu</li>
            <li>Action</li>
        </ul>
        <ul className="table-body">
            {menues.filter(menu => menu.type === 'submenuitem' && menu.parentId === menuu._id).map(menu => (
                <li key={menu._id} className="menuItem"><SingleMenuItem menu={menu} page={page}/></li>
            ))}
        </ul>
    </div>
    </>
  )
}
