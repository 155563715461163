import { createSlice } from "@reduxjs/toolkit";

export const authorSlice = createSlice({
  name: "author",
  initialState: {
    authors: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    // //get all
    getAuthorStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAuthorSuccess: (state, action) => {
      state.isFetching = false;
      state.authors = action.payload;
    },
    getAuthorFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addAuthorStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAuthorSuccess: (state, action) => {
      state.isFetching = false;
      state.authors.unshift(action.payload);
    },
    addAuthorFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updateAuthorStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAuthorSuccess: (state, action) => {
      state.isFetching = false;
      state.authors[
        state.authors.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateAuthor;
    },
    updateAuthorFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deleteAuthorStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAuthorSuccess: (state, action) => {
      state.isFetching = false;
      state.authors.splice(
        state.authors.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteAuthorFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAuthorStart,
  getAuthorSuccess,
  getAuthorFailur,
  addAuthorStart,
  addAuthorSuccess,
  addAuthorFailur,
  updateAuthorStart,
  updateAuthorSuccess,
  updateAuthorFailure,
  deleteAuthorStart,
  deleteAuthorSuccess,
  deleteAuthorFailure,
} = authorSlice.actions;

export default authorSlice.reducer;
