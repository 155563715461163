import React, { useState } from "react";
import threeDot from "../img/Frame 179.png";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

const DashboardBestsellerPublisher = () => {
  const publishers = useSelector((state) => state.publisher.publishers);
  const [pageNumber, setPageNumber] = useState(0);
  const [publisherPerPage, setpublisherPerPage] = useState(11);
  const pageVisited = pageNumber * publisherPerPage;

  const pageCount = Math.ceil(publishers.length / publisherPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between">
        <p className="fs-5 fw-bold"> Bestseller Publisher </p>

        <div className="d-flex justify-content-between box-text-color-set">
          <select
            class="form-select w-75 py-0 ms-2 me-2 box-text-color-set"
            aria-label="Default select example"
          >
            <option selected> JAN 21 </option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>

          <button className="three-dot-button">
            <img src={threeDot} alt="" />
          </button>
        </div>
      </div>

      {/* table */}

      <div className="mt-3">
        <table class="table ">
          <thead className="">
            <tr>
              <th scope="col">Publishers</th>
              <th scope="col">Total Books</th>
              <th scope="col">Sales</th>
            </tr>
          </thead>

          <tbody className="table-text-set">
            {publishers &&
              publishers
                .slice(pageVisited, pageVisited + publisherPerPage)
                .map((publisher) => {
                  const { en_publication, _id } = publisher;
                  return (
                    <tr key={_id}>
                      <td>
                        <div className="">
                          <img
                            src={
                              publisher.photo
                                ? `${process.env.REACT_APP_BASE_URL}/api/alluploads/publishersphoto/${publisher.photo}`
                                : `${process.env.REACT_APP_BASE_URL}/api/alluploads/default-publisher.jpg`
                            }
                            alt=""
                            className="table-img-set"
                          />
                          <span className="table-book-name ms-2">
                            {en_publication}
                          </span>
                        </div>
                      </td>

                      <td>
                        <p className="mt-2">500</p>
                      </td>

                      <td>
                        <p className="mt-2">7000</p>
                      </td>
                    </tr>
                  );
                })}

          </tbody>
        </table>
      </div>

      <div className="">
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination"}
          activeClassName={"actvie-page"}
        />
      </div>

    </div>
  );
};

export default DashboardBestsellerPublisher;
