import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes, faCheck} from "@fortawesome/free-solid-svg-icons"
import demopublihser from "../img/publisher-icon.png"
import ReactPaginate from "react-paginate"
import { getActivityLogs } from '../util/api';
import BeatLoader from 'react-spinners/BeatLoader';
import { userRequest } from '../util/requestMethod';

export default function ActivityLogs() {
  const [searchedQuery, setsearchedQuery] = useState("")
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemPerPage, setitemPerPage] = useState(10);
  const pageVisited = pageNumber * itemPerPage;
  const [activityLog, setactivityLog] = useState([])

  useEffect(() => {
    setLoader(true);
    getActivityLogs().then((res)=>{
      setactivityLog(res.data);
      setLoader(false)
    })
  }, []);

  const displayLogs = activityLog?.slice(pageVisited, pageVisited + itemPerPage)
    .map((item) => {
      let logtime = new Date(item.createdAt)
      return (
        <div className="table-row" key={item._id}>
            <li>{item.reader.fullname}</li>
            <li>{logtime?.toLocaleString()}</li>
            <li>{item.acivityType}</li>
        </div>
      );
    });

    const pageCount = Math.ceil(activityLog.length / itemPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

  return (
    <div className="content-area-inner list-page">
      <div className="container-fluid">
        <div className="breadcumb">
          <Link to="/">Dashboard</Link>
          <FontAwesomeIcon icon={faAngleRight} />
          <Link to="/">Activities Log</Link>
        </div>
        <div className="heading">
          <h1>Activities Log</h1>
        </div>
        <div className="col-lg-12">
            <div className="row">
                <div className="col-lg-8">
                    <div className="tab-btns">
                        <div className="tab-btn active">All Items ({activityLog.length})</div>
                    </div>
                </div>
                <div className="col-lg-4">
                </div>
            </div>
            <div className="tab-content">
                <div className="tab-wrap">
                    <div className="theme-card">
                        <div className="theme-card-header">
                            {/* <div className="row">
                                <div className="col-lg-8">
                                    <div className="searchbar">
                                        <div className="form-group">
                                            <input type="text" placeholder="Search" onChange={(e)=>handleSearch(e)}/>
                                            <button type="submit" className="btn">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4523 11.4716H12.6623L12.3823 11.2016C13.5823 9.80157 14.2023 7.89157 13.8623 5.86157C13.3923 3.08157 11.0723 0.861573 8.27229 0.521573C4.04229 0.00157288 0.482286 3.56157 1.00229 7.79157C1.34229 10.5916 3.56229 12.9116 6.34229 13.3816C8.37229 13.7216 10.2823 13.1016 11.6823 11.9016L11.9523 12.1816V12.9716L16.2023 17.2216C16.6123 17.6316 17.2823 17.6316 17.6923 17.2216C18.1023 16.8116 18.1023 16.1416 17.6923 15.7316L13.4523 11.4716ZM7.45229 11.4716C4.96229 11.4716 2.95229 9.46157 2.95229 6.97157C2.95229 4.48157 4.96229 2.47157 7.45229 2.47157C9.94229 2.47157 11.9523 4.48157 11.9523 6.97157C11.9523 9.46157 9.94229 11.4716 7.45229 11.4716Z" fill="#1D1D1D"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="list-filter d-flex justify-content-end">
                                        <div className="form-group">
                                            <select className="form-control" onChange={handleSorting}>
                                                <option value="filter">Filter</option>
                                                <option value="pricehightolow">Price High To Low </option>
                                                <option value="pricelowtohigh">Price Low To High </option>
                                                <option value="stock">Stock</option>
                                                <option value="sold">Sold</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <select type="number" className="form-control" onChange={(e)=>{setitemPerPage(e.target.value)}}>
                                                <option value="10">Book per page</option>
                                                <option value="20">20 per page</option>
                                                <option value="30">30 per page</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {loader? 
                          <BeatLoader color={"#0D99FF"} loading={loader} size={15}/>
                          :
                          <div className="theme-card-body">
                              <div className="table log-table">
                                  <ul className="custom-table-header">
                                      <li>Reader Name</li>
                                      <li>Activity time</li>
                                      <li>Activity Type</li>
                                  </ul>
                                  <ul className="custom-table-body">
                                      {displayLogs}
                                  </ul>
                                  <ReactPaginate
                                      previousLabel={"Prev"}
                                      nextLabel={"Next"}
                                      pageCount={pageCount}
                                      onPageChange={changePage}
                                      containerClassName={"pagination"}
                                      activeClassName={"actvie-page"}
                                  />
                              </div>
                          </div>
                        }
                    </div>
                </div>
                <div className="tab-wrap">
                    {/* <div className="theme-card">
                        Draft
                    </div> */}
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
