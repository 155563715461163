import { createSlice } from "@reduxjs/toolkit";

export const draftSlice = createSlice({
  name: "draftBooks",
  initialState: {
    allDrafts: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getDraftStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getDraftSuccess: (state, action) => {
      state.isFetching = false;
      state.allDrafts = action.payload;
    },
    getDraftFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  }
})

export const { getDraftStart, getDraftSuccess, getDraftFailur } =
  draftSlice.actions;

export default draftSlice.reducer;
