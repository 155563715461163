import React from 'react';
import {
  LineChart,
  Line,
  ComposedChart,
  BarChart,
  Bar,
  ResponsiveContainer,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import threeDot from "../img/Frame 179.png";





const DashboardRevenue = () => {

  const data1 = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <div className="p-2">
              <div className="d-flex justify-content-between">
                <p className="fw-bold">Revenue</p>
                <div className="d-flex justify-content-between">
                  <button className="three-dot-button px-2 revenue-button-text me-2">
                    Weekly
                  </button>
                  <button className="three-dot-button px-2 revenue-button-text me-2">
                    Monthly
                  </button>
                  <button className="three-dot-button px-2 revenue-button-text">
                    Yearly
                  </button>
                </div>
              </div>

              <div className="mt-4">
              {/* <ResponsiveContainer width="100%" height="100%" > */}
                      
              <ComposedChart
                  width={650}
                  height={400}
                  data={data1}
                  margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 10,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="name" scale="band" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="uv"
                    barSize={20}
                    fill="#0D99FF"
                    borderRadius={20}
                  />
                  <BarChart/>
                </ComposedChart>

              {/* </ResponsiveContainer> */}
                
                
              </div>
            </div>
  );
};

export default DashboardRevenue;