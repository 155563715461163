import React from "react";
import threeDot from "../img/Frame 179.png";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { useState } from "react";

const DashboardBestsellerAuthor = () => {

  const authors = useSelector((state) => state.author.authors);
  const [pageNumber, setPageNumber] = useState(0);
  const [authorPerPage, setauthorPerPage] = useState(11);
  const pageVisited = pageNumber * authorPerPage;

  const pageCount = Math.ceil(authors.length / authorPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayAuthors = authors
    .slice(pageVisited, pageVisited + authorPerPage)
    .map((author) => {
      return (
        <tr key={author._id}>
          <td>
            <div className="">
              <img
                src={
                  author.photo
                    ? `${process.env.REACT_APP_BASE_URL}/api/alluploads/authorsphoto/${author.photo}`
                    : `${process.env.REACT_APP_BASE_URL}/api/alluploads/Default-male-writter.png`
                }
                alt=""
                className="table-img-set"
              />
              <span className="table-book-name ms-2">
                {author.en_author}
              </span>
            </div>
          </td>
          <td>
            <p className="mt-2">300</p>
          </td>
        </tr>
      );
    });

  return (
    
    <div className="p-2">
      
      <div className="d-flex justify-content-between">
        <p className="fs-5 fw-bold"> Bestseller Author </p>

        <div className="d-flex justify-content-between box-text-color-set">
          <select
            class="form-select w-75 py-0 ms-2 me-2 box-text-color-set"
            aria-label="Default select example"
          >
            <option selected> JAN 21 </option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>

          <button className="three-dot-button">
            <img src={threeDot} alt="" />
          </button>
      
        </div>

      </div>

      {/* table */}

      <div className="mt-3">
        <table class="table">
          <thead className="">
            <tr>
              <th scope="col">Author</th>
              <th scope="col">Sales</th>
            </tr>
          </thead>
          <tbody className="table-text-set">{displayAuthors}</tbody>
        </table>
      </div>

      <div className="">
      <ReactPaginate
        previousLabel={"Prev"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"pagination"}
        activeClassName={"actvie-page"}
      />
      </div>

    </div>
  );
};

export default DashboardBestsellerAuthor;
