import { createSlice } from "@reduxjs/toolkit";


const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menues: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getMenuStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getMenuSuccess: (state, action) => {
      state.isFetching = false;
      state.menues = action.payload;
    },
    getMenuFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addMenuStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addMenuSuccess: (state, action) => {
      state.isFetching = false;
      state.menues.push(action.payload);
    },
    addMenuFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updateMenuStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateMenuSuccess: (state, action) => {
      state.isFetching = false;
      state.menues[
        state.menues.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateMenu;
    },
    updateMenuFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deleteMenuStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteMenuSuccess: (state, action) => {
      state.isFetching = false;
      state.menues.splice(
        state.menues.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteMenuFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getMenuStart,
  getMenuSuccess,
  getMenuFailur,
  addMenuStart,
  addMenuSuccess,
  addMenuFailur,
  updateMenuStart,
  updateMenuSuccess,
  updateMenuFailure,
  deleteMenuStart,
  deleteMenuSuccess,
  deleteMenuFailure,
} = menuSlice.actions;

export default menuSlice.reducer;
