import React from 'react'
import { useState, useEffect } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilePdf,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { createAlbum, getPublishers, loadMedia, updatePublisher } from "../util/api";
import axios from 'axios';
import ModalImage from "react-modal-image";

export default function Album({folder, uploadImg, publisherId}) {
  const dispatch = useDispatch();
  let files = [];
  let library = {};
  const [uploader, setuploader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [upImg, setupImg] = useState(publisherId === folder._id? false : true);
  const [mediaLibrary, setmediaLibrary] = useState(publisherId === folder._id? true : false);
  const [isAlbumOpen, setisAlbumOpen] = useState(publisherId === folder._id? true : false)
  const [urls, setUrls] = useState([]);
  const media = useSelector((state) => state.media.allmedia);


    const handleUpimg = () => {
        setupImg(true);
        setmediaLibrary(false);
    };
    const handleMediaLibrary = () => {
        setupImg(false);
        setmediaLibrary(true);
    };

    const handleOpenAlbum = (e) =>{
        e.preventDefault();
        setisAlbumOpen(true);
    }

    const updateAlbumStatus = (e, data) => {
        e.preventDefault();
        let publisher = { ...data, isAlbumCreated: 0 };
        createAlbum({ _id: data._id });
        updatePublisher(data._id, publisher, dispatch).then(() =>
            getPublishers(dispatch)
        );
    };

  const handleChange = (e) => {
    setuploader(true)
    files = e.target.files;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      library = { fileName: files[i].name, fileType: files[i].type, entityId:folder._id };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/media/add`, library)
        .then(() => {
          console.log("success");
        })
        .catch((e) => {
          console.log("error", e);
        });
      setUrls((prevState) => [...prevState, library.fileName]);
      data.append("file", files[i]);
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/publisher/uploads/${folder._id}`, data)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => {
        loadMedia(dispatch)
        setuploader(false)
      });
  }

  const handleDelete = (imagename) => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/media/${imagename._id}`)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      });

    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/deletemedia/${folder._id}/${imagename.fileName}`)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => loadMedia(dispatch));
  };

  const handleUpload = (fileName) => {
    // const imgUrl = `${process.env.REACT_APP_BASE_URL}/api/alluploads/${folder._id}/${fileName}`;
    uploadImg(fileName);
  };

  return (
    <div className="single-upload-img" key={folder._id}>
        {folder.isAlbumCreated === 0 ? (
        <a className="activated-album">
            <FontAwesomeIcon
                fontSize="60"
                style={{ marginBottom: "10px" }}
                color="#0D99FF"
                icon={faFolder}
                onClick={(e)=>handleOpenAlbum(e)}
            />
                {folder.en_publication}
                {isAlbumOpen === true? (
                <div className="album">
                    <button className="close-modal" style={{top:"15px",right:"15px"}} onClick={()=>setisAlbumOpen(false)}><FontAwesomeIcon icon={faTimes}/></button>
                    <div className="album-inner">
                        <div className="media-right">
                            <ul className="upload-media-btns">
                            <li
                                className={upImg ? "active-upload-btn" : ""}
                                onClick={handleUpimg}
                            >
                                Upload Files
                            </li>
                            <li
                                className={mediaLibrary ? "active-upload-btn" : ""}
                                onClick={handleMediaLibrary}
                            >
                                Media Library
                            </li>
                            </ul>
                            <div
                              className="upload-media-body"
                              style={upImg ? { display: "block" } : { display: "none" }}
                            >
                            {/* {urls.length !== 0 ? (
                                <div className="image-gallery gallery-2">
                                {urls.map((url) => {
                                    return (
                                        <div className="single-upload-img">
                                            <img src={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${url}`} key={Math.random} alt={url} />
                                        </div>
                                    )
                                })}
                                </div>
                            ) : (
                                ""
                            )} */}
                              {uploader?
                                 <p style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center",fontWeight:"600",fontSize:"30px"}}>Uploading...</p>
                                :
                                <form>
                                    <h1>Drop files anywhere to upload</h1>
                                    <p>Or</p>
                                    <input type="file" multiple onChange={handleChange} />
                                    <a>Select Files</a>
                                </form>
                              }
                            </div>
                            <div
                            className="upload-media-body"
                            style={mediaLibrary ? { display: "block" } : { display: "none" }}
                            >
                            <div className="image-gallery">
                            {media.filter((media)=> media.entityId === folder._id).map((data)=>(
                                <div className="single-upload-img" key={data._id}>
                                    <ModalImage
                                        small={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${folder._id}/${data.fileName}`}
                                        large={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${folder._id}/${data.fileName}`}
                                        alt="Library Gallery"
                                    />
                                    <div className="upload-btn-group">
                                        <button
                                            className="up-btn"
                                            onClick={() => handleUpload(data.fileName)}
                                        >
                                            Insert Image
                                        </button>
                                    </div>
                                    <button
                                    className="delete-btn"
                                    onClick={() => handleDelete(data)}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                            ))}
                                <BeatLoader color={"#f04a3f"} loading={loader} size={15} />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            ): (
            ""
            )}
        </a>
        ) : (
        <a className="inactivated-album">
            <FontAwesomeIcon
            fontSize="60"
            style={{ marginBottom: "10px" }}
            color="#0D99FF"
            icon={faFolder}
            />
            {folder.en_publication}{" "}
            <p>
            No album for this publihser.
            <br />
            <br />
            <button onClick={(e) => updateAlbumStatus(e, folder)}>
                Create One
            </button>
            </p>
        </a>
        )}
    </div>
  )
}
