import React from 'react'
import { useEffect } from 'react';
import { userRequest } from '../util/requestMethod'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faCheck, faAngleDown, faKey, faTimes,faFileExcel, faEdit} from "@fortawesome/free-solid-svg-icons"
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactPaginate from 'react-paginate';
import { getReadersClaim, updatePassordForReader, updateReader } from '../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationSuccess } from '../redux/notificationRedux';
import axios from 'axios'

export default function AuthorClaim() {
    const dispatch = useDispatch()
    const [readers, setreaders] = useState([])
    const [successModal, setsuccessModal] = useState(false)
    const [readerPerPage, setreaderPerPage] = useState(10)
    const [loader, setLoader] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [searchedQuery, setsearchedQuery] = useState("");
    const pageVisited = pageNumber * readerPerPage;
    const [isModalOpen, setisModalOpen] = useState(false);
    let notification = useSelector((state)=> state.notification.notification)
    let currentUser = useSelector((state)=> state.userInfo.currentUser)
    const [dob, setdob] = useState({
        month:undefined,
        date:undefined,
        year:undefined
      });

    const [smsGatewayInfo, setsmsGatewayInfo] = useState({})
    
    const getSMSGatewaySettings = async ()=>{
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/smsgateway/`, {headers :{"token":`Bearer ${currentUser.accessToken}`}}).then((res)=>{
            setsmsGatewayInfo(res.data)
        })
    }

    useEffect(() => {
        getReadersClaim().then((res)=>{
            const authorCalimer = res.data?.filter(claim=> claim.authorVerification?.authorId)
            setreaders(authorCalimer)
        })
        getSMSGatewaySettings()
    }, [dispatch])

    const handleSearch = (e) =>{
        setsearchedQuery(e.target.value)
    }


    const authorVerification = async (verification,readerId) => {
        let res;  
        try {
            res = await userRequest.post(`/author/mailto/${readerId}`,verification);
            return res;
        } catch (err) {
            return res;
        }
    };

    const handleconfirmation = async (readerId, authorId, authorPhone) => {
        const randomcode = Math.random().toString(26).slice(-6) + Math.random().toString(26).slice(-6);
        let verificationurl = `${process.env.REACT_APP_BASE_URL}/authorvarification?id=${authorId}&code=${randomcode}`
        let verification = {
            "authorId": authorId,
            "verficationcode": randomcode,
            "url": verificationurl
        }
        const res = await axios.get(`https://bulksmsbd.net/api/smsapi?api_key=${smsGatewayInfo.apikey}&type=text&number=${authorPhone}&senderid=${smsGatewayInfo.sender}&message=Your author verification url for Bookmark ECOM is  ${verificationurl}.`,{headers : {'Acess-Control-Allow-Origin':'*'}})
            if (res.data?.response_code === 202) {
                console.log(res.data);
            }else{
                console.log(res);
            }
        authorVerification(verification, readerId).then((res) => {
            if (res.data === "Verification Failed") {
                getReadersClaim().then((res)=>{
                    const authorCalimer = res.data?.filter(claim=> claim.authorVerification?.authorId)
                    setreaders(authorCalimer)
                })
            } else {
                setsuccessModal(true)
                getReadersClaim().then((res)=>{
                    const authorCalimer = res.data?.filter(claim=> claim.authorVerification?.authorId)
                    setreaders(authorCalimer)
                })
            }
        })
      }

    const displayReaders = readers.filter(reader=> {
        if (searchedQuery === "") {
          return reader
        }
        else if (reader.fullname.toLowerCase().includes(searchedQuery.toLowerCase())) {
          return reader
        }
      }).slice(pageVisited, pageVisited + readerPerPage)
        .map((reader) => {
          return (
            <div className="table-row" key={reader._id}>
                <li>
                    <img src={reader.readerPhoto? `${process.env.REACT_APP_BASE_URL}/api/readersMedia/${reader._id}/${reader.readerPhoto}` : `${process.env.REACT_APP_BASE_URL}/api/alluploads/Default-male-writter.png`} />
                    {reader.fullname}
                </li>
                <li>
                    {reader.phone}
                </li>
                <li>
                    {reader.email}
                </li>
                <li>
                    {reader.authorEnName}
                </li>
                <li>{reader.authorEmail}</li>
                <li>{reader.authorPhone}</li>
                <li>
                    {reader.authorVerification.claimStatus === "success"?
                        <p className="status success">Approved</p>
                        : 
                        reader.authorVerification.claimStatus === "pending"?
                            reader.authorEmail && reader.authorPhone?
                                <a className="access" style={{marginLeft:"8px"}} onClick={(e)=>handleconfirmation(reader?._id, reader?.authorId, reader.authorPhone)}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </a>
                                :
                                ""
                        : ""
                    }
                </li>
            </div>
          );
        });


    const pageCount = Math.ceil(readers.length / readerPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    const dates = () => {
        let dates31 = []
        for (var i=1; i <=31; i++) {
            dates31.push(<option value={i}>{i}</option>)
        }
        return dates31
    }
    const years = () => {
        let year = []
        for (var i=1; i <=70; i++) {
            year.push(<option value={1951+i}>{1951+i}</option>)
        }
        return year
    }

    const handleDatePicker = (e) =>{
        setdob((prev)=>{
          return {...prev, [e.target.name]:e.target.value}
        })
      }

    return (
        <>
        {notification.status === true?
            <div className='notification-bar success'>
            <FontAwesomeIcon icon={faCheck} />
            <p>{notification.sms}</p>
            </div>
            : notification.status === false?
            <div className='notification-bar failed'>
            <FontAwesomeIcon icon={faTimes} />
            <p>{notification.sms}</p>
            </div>
            :
            ""
        }
        <div className="content-area-inner list-page">
            {successModal? (
                <div className="dialougeBox">
                    <div className="dialougeBoxInner">
                        <p>Author verification mail sent successfully.</p>
                        <FontAwesomeIcon icon={faTimes} className='cross-icon' onClick={()=>setsuccessModal(false)} />
                    </div>
                </div>
            ) : ""}
            <div className="container-fluid">
                <div className="breadcumb">
                <Link to="/">Dashboard</Link>
                <FontAwesomeIcon icon={faAngleRight} />
                <Link to="/">Claim Lists</Link>
                </div>
                <div className="heading">
                <h1>Author Claim List</h1>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="tab-btns">
                                <div className="tab-btn active">All Items ({readers.length})</div>
                                <div className="tab-btn">Drafts (0)</div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="group-btn">
                                
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-wrap">
                            <div className="theme-card">
                                <div className="theme-card-header">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="searchbar">
                                                <div className="form-group">
                                                    <input type="text" placeholder="Search" onChange={(e)=>handleSearch(e)}/>
                                                    <button type="submit" className="btn">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4523 11.4716H12.6623L12.3823 11.2016C13.5823 9.80157 14.2023 7.89157 13.8623 5.86157C13.3923 3.08157 11.0723 0.861573 8.27229 0.521573C4.04229 0.00157288 0.482286 3.56157 1.00229 7.79157C1.34229 10.5916 3.56229 12.9116 6.34229 13.3816C8.37229 13.7216 10.2823 13.1016 11.6823 11.9016L11.9523 12.1816V12.9716L16.2023 17.2216C16.6123 17.6316 17.2823 17.6316 17.6923 17.2216C18.1023 16.8116 18.1023 16.1416 17.6923 15.7316L13.4523 11.4716ZM7.45229 11.4716C4.96229 11.4716 2.95229 9.46157 2.95229 6.97157C2.95229 4.48157 4.96229 2.47157 7.45229 2.47157C9.94229 2.47157 11.9523 4.48157 11.9523 6.97157C11.9523 9.46157 9.94229 11.4716 7.45229 11.4716Z" fill="#1D1D1D"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            
                                        </div>
                                    </div>
                                </div>
                                {loader? 
                                <BeatLoader color={"#0D99FF"} loading={loader} size={15}/>
                                :
                                <div className="theme-card-body">
                                    <div className="table order-table">
                                        <ul className="custom-table-header">
                                            <li>Reader Name</li>
                                            <li>Phone</li>
                                            <li>Email</li>
                                            <li>Author Name</li>
                                            <li>Email</li>
                                            <li>Phone</li>
                                            <li>Action</li>
                                        </ul>
                                        <ul className="custom-table-body">
                                            {displayReaders}
                                        </ul>
                                        <ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"pagination"}
                                            activeClassName={"actvie-page"}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="tab-wrap">
                            {/* <div className="theme-card">
                                Draft
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
