import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilePdf,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";

export function Pdf({data, handleUpload, handleDelete}) {
    

    return (
        <div className="single-upload-img" key={data._id}>
            <a
                href={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${data.fileName}`}
                target="_blank"
                style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontSize: "12px",
                textAlign: "center",
                color: "#000000",
                }}
            >
                <FontAwesomeIcon
                fontSize="60"
                style={{ marginBottom: "10px" }}
                color="#0D99FF"
                icon={faFilePdf}
                />
                {data.fileName}
            </a>
            <div className="upload-btn-group">
                <button
                    className="up-btn"
                    onClick={() => handleUpload(data.fileName)}
                    >
                    Insert Image
                </button>
            </div>
            <button
                className="delete-btn"
                onClick={() => handleDelete(data)}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
    )
}
