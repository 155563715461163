import { publicRequest, userRequest } from "./requestMethod";
import {loginStart, loginSuccess, loginFailure, logout} from "../redux/authRedux"
import { addBookFailur, addBookStart, addBookSuccess, deleteBookFailure, deleteBookStart, deleteBookSuccess, getBookFailur, getBookStart, getBookSuccess, updateBookFailure, updateBookStart, updateBookSuccess } from "../redux/bookRedux"
import { getPublisherStart, getPublisherSuccess, getPublisherFailur, addPublisherStart, addPublisherSuccess, addPublisherFailur, updatePublisherStart, updatePublisherSuccess, updatePublisherFailure,deletePublisherStart, deletePublisherSuccess, deletePublisherFailure } from "../redux/publisherRedux"
import { addAuthorFailur, addAuthorStart, addAuthorSuccess, updateAuthorFailure, updateAuthorStart, updateAuthorSuccess,deleteAuthorStart,deleteAuthorSuccess,deleteAuthorFailure, getAuthorStart, getAuthorSuccess, getAuthorFailur, } from "../redux/authorRedux";
import {addCategoryStart,addCategorySuccess,addCategoryFailur,updateCategoryStart,updateCategorySuccess,updateCategoryFailure,deleteCategoryStart,deleteCategorySuccess,deleteCategoryFailure, getCategoryStart, getCategorySuccess, getCategoryFailur} from "../redux/categoryRedux";
import { deleteMediaStart, getMediaFailur, getMediaStart, getMediaSuccess } from "../redux/mediaRedux";
import { addBlogFailur, addBlogStart, addBlogSuccess, deleteBlogFailure, deleteBlogStart, deleteBlogSuccess, getBlogFailur, getBlogStart, getBlogSuccess, updateBlogFailure, updateBlogStart, updateBlogSuccess } from "../redux/blogRedux";
import { getAdminStart, getAdminSuccess, getAdminFailur, addAdminStart, addAdminSuccess, addAdminFailur, updateAdminStart, updateAdminSuccess, updateAdminFailure, deleteAdminStart, deleteAdminSuccess, deleteAdminFailure } from "../redux/adminRedux";
import { getSliderStart, getSliderSuccess, getSliderFailur, addSliderStart, addSliderSuccess, addSliderFailur, updateSliderStart, updateSliderSuccess, updateSliderFailure,deleteSliderStart, deleteSliderSuccess, deleteSliderFailure } from "../redux/sliderRedux"
import { addtagFailur, addtagStart, addtagSuccess, gettagFailur, gettagStart, gettagSuccess } from "../redux/tagRedux";
import { changeFailur, changeStart, changeSuccess } from "../redux/passwordRedux";
import { addMenuFailur, addMenuStart, addMenuSuccess, deleteMenuFailure, deleteMenuStart, deleteMenuSuccess, getMenuFailur, getMenuStart, getMenuSuccess, updateMenuFailure, updateMenuStart, updateMenuSuccess } from "../redux/menuRedux";
import { addcollectionFailur, addcollectionStart, addcollectionSuccess, deletecollectionFailure, deletecollectionStart, deletecollectionSuccess, getcollectionFailur, getcollectionStart, getcollectionSuccess, updatecollectionFailure, updatecollectionStart, updatecollectionSuccess } from "../redux/collectionRedux";
import { addadFailur, addadStart, addadSuccess, deleteadFailure, deleteadStart, deleteadSuccess, getadFailur, getadStart, getadSuccess, updateadFailure, updateadStart, updateadSuccess } from "../redux/adsRedux";
import { getDraftFailur, getDraftStart, getDraftSuccess } from "../redux/draftBooksRedux";
// site setting
export const updateSetting = async (id, siteSetting) => {
  try {
    const res = await userRequest.put(`/siteSetting/editsite`, siteSetting);
  } catch (err) {
    console.log("something went wrong");
  }
};

export const addAction = async (action) => {
  let res;
  try {
    res = await userRequest.post(`/actions/add`, action);
    return res;
  } catch (err) {
    return res;
  }
};

export const getActions = async () => {
  let res;
  try {
    res = await userRequest.get(`/actions/`);
    return res;
  } catch (err) {
    return res;
  }
};

export const addRole = async (role) => {
  let res;
  try {
    res = await userRequest.post(`/roles/add`, role);
    return res;
  } catch (err) {
    return res;
  }
};

export const getroles = async () => {
  let res;
  try {
    res = await userRequest.get(`/roles/`);
    return res;
  } catch (err) {
    return res;
  }
};

export const updateRole = async (role,id) => {
  let res;
  try {
    res = await userRequest.put(`/roles/${id}`, role);
    return res;
  } catch (err) {
    return res;
  }
};

export const deleteRole = async (id) => {
  let res;
  try {
    res = await userRequest.delete(`/roles/${id}`);
    return res;
  } catch (err) {
    return res;
  }
};

export const deleteAction = async (id) => {
  let res;
  try {
    res = await userRequest.delete(`/actions/${id}`);
    return res;
  } catch (err) {
    return res;
  }
};

export const getLibrary = async () => {
  let res;
  try {
    res = await publicRequest.get("/library");
    return res
  } catch (err) {
    return res
  }
};

export const getLibraryById = async (id) => {
  let res;
  try {
    res = await publicRequest.get(`/library/find/${id}`);
    return res
  } catch (err) {
    return res
  }
};

export const addLibrary = async (library) => {
  let res;
  try {
    res = await userRequest.post(`/library/addlibrary`, library);
    return res;
  } catch (err) {
    return res;
  }
};

export const updateLibrary = async (id, library) => {
  let res;
  try {
    res = await userRequest.put(`/library/editlibrary?id=${id}`, library);
    return res;
  } catch (err) {
    return res;
  }
};

export const deleteLibrary = async (id, dispatch) => {
  let res;
  try {
    res = await userRequest.delete(`/library/deletelibrary?id=${id}`);
    return res;
  } catch (err) {
    return res;
  }
};

export const updateSmsGateway = async (id, smsGateway) => {
  try {
    const res = await userRequest.put(`/smsgateway/editsmsinfo?id=${id}`, smsGateway);
  } catch (err) {
    console.log("something went wrong");
  }
};

//Channge password
export const changePassword = async (dispatch, passwordDetails) => {
  dispatch(changeStart());
  try {
    const res = await publicRequest.post("/auth/changepassword", passwordDetails);
    dispatch(changeSuccess(res.data));
  } catch (err) {
    dispatch(changeFailur());
  }
};

// login
export const login = async (dispatch, user) => {
    dispatch(loginStart())
    try {
      const res = await publicRequest.post("/auth/login", user);
      dispatch(loginSuccess(res.data))
      return res.data;
    } catch (err) {
      dispatch(loginFailure());
    }
};

// login
export const updateAdmin = async (dispatch, user, id) => {
  try {
    const res = await userRequest.put(`/user/${id}`, user);
    dispatch(loginSuccess(res.data))
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


// Get Activity logs
export const getActivityLogs = async () => {
  let res;
  try {
    res = await userRequest.get("/activitylog/alllog");
    return res
  } catch (err) {
    return res
  }
};

// Get Menu
export const getMenu = async (dispatch) => {
  dispatch(getMenuStart());
  try {
    const res = await publicRequest.get("/menues");
    dispatch(getMenuSuccess(res.data));
  } catch (err) {
    dispatch(getMenuFailur());
  }
};

// Add Menu
export const addMenu = async (menu, dispatch) => {
  dispatch(addMenuStart());
  try {
    const res = await userRequest.post(`/menues/addmenu`, menu);
    dispatch(addMenuSuccess(res.data));
  } catch (err) {
    dispatch(addMenuFailur());
  }
};

export const updateMenu = async (id, menu, dispatch) => {
  dispatch(updateMenuStart());
  try {
    const res = await userRequest.put(`/menues/editmenu?id=${id}`, menu);
    const updateMenu = res.data;
    dispatch(updateMenuSuccess({ id, updateMenu }));
  } catch (err) {
    dispatch(updateMenuFailure());
  }
};

// Delete Menu
export const deleteMenu = async (id, dispatch) => {
  dispatch(deleteMenuStart());
  try {
    await userRequest.delete(`/menues/deletemenu?id=${id}`);
    dispatch(deleteMenuSuccess(id));
  } catch (err) {
    dispatch(deleteMenuFailure());
  }
};

// Draft books
export const getDraftBooks = async (dispatch) => {
  dispatch(getDraftStart());
  try {
    const res = await userRequest.get("/products/draftbooks");
    dispatch(getDraftSuccess(res.data));
  } catch (err) {
    dispatch(getDraftFailur());
  }
};

// Get books
export const getBook = async (dispatch) => {
  dispatch(getBookStart());
  try {
    const res = await publicRequest.get("/products");
    dispatch(getBookSuccess(res.data));
  } catch (err) {
    dispatch(getBookFailur());
  }
};

// Add book
export const addBook = async (book, dispatch) => {
  dispatch(addBookStart());
  let res;
  try {
    res = await userRequest.post(`/products/addbook`, book);
    dispatch(addBookSuccess(res.data));
    return res
  } catch (err) {
    dispatch(addBookFailur());
    return res = err
  }
};

export const updateBook = async (id, book, dispatch) => {
  dispatch(updateBookStart());
  let res;
  try {
    res = await userRequest.put(`/products/${id}`, book);
    const updateBook = res.data;
    dispatch(updateBookSuccess({ id, updateBook }));
    return res
  } catch (err) {
    dispatch(updateBookFailure());
    return res
  }
};

// Delete book
export const deleteBook = async (id, dispatch) => {
  dispatch(deleteBookStart());
  let res;
  try {
    res = await userRequest.delete(`/products/${id}`);
    dispatch(deleteBookSuccess(id));
    return res;
  } catch (err) {
    dispatch(deleteBookFailure());
    return res;
  }
};

// Create album for publishers
export const createAlbum = async (id) => {
  try {
    const res = await userRequest.post("/publisher/createalbum", id);
  } catch (err) {
    console.log(err);
  }
};

// Get publishers
export const getPublishers = async (dispatch) => {
  dispatch(getPublisherStart());
  try {
    const res = await publicRequest.get("/publisher");
    dispatch(getPublisherSuccess(res.data));
  } catch (err) {
    dispatch(getPublisherFailur());
  }
};

// Add publishers
export const addPublisher = async (publisher, dispatch) => {
  dispatch(addPublisherStart());
  let res;
  try {
    res = await userRequest.post(`/publisher/addpublisher`, publisher);
    dispatch(addPublisherSuccess(res.data));
    return res
  } catch (err) {
    dispatch(addPublisherFailur());
    return res = err
  }
};

// Update publisher
export const updatePublisher = async (id, publisher, dispatch) => {
  dispatch(updatePublisherStart());
  let res;
  try {
    res = await userRequest.put(`/publisher/editpublisher?id=${id}`, publisher);
    const updatePublisher = res.data;
    dispatch(updatePublisherSuccess({ id, updatePublisher }));
    return res
  } catch (err) {
    dispatch(updatePublisherFailure());
    return res
  }
};

// Delete publisher
export const deletePublisher = async (id, dispatch) => {
  dispatch(deletePublisherStart());
  let res;
  try {
    res = await userRequest.delete(`/publisher/deletepublisher?id=${id}`);
    dispatch(deletePublisherSuccess(id));
    return res
  } catch (err) {
    dispatch(deletePublisherFailure());
    return res
  }
};

// Mailto publisher
export const mailtoPublisher = async (id, publisher) => {
  
  try {
    await userRequest.post(`/publisher/mailto/${id}`,publisher);
  } catch (err) {
    
  }
};

// Get authors
export const getAuthor = async (dispatch) => {
  dispatch(getAuthorStart());
  try {
    const res = await publicRequest.get("/author");
    dispatch(getAuthorSuccess(res.data));
  } catch (err) {
    dispatch(getAuthorFailur());
  }
};

// Get orders

export const getOrders = async () => {
  let res;
  try {
    res = await userRequest.get("/orders/allorder");
    return res
  } catch (err) {
    return res
  }
};

// Update Orders

export const updateOrder = async (update,id) => {
  let res;
  try {
    res = await userRequest.put(`/orders/editorder?id=${id}`, update);
    return res
  } catch (err) {
    return res
  }
};

// get readers

export const getReaders = async () => {
  try {
    const res = await userRequest.get("/readers?all=true");
    return res
  } catch (err) {
  }
};

export const getReadersClaim = async () => {
  try {
    const res = await userRequest.get("/readers");
    return res
  } catch (err) {
  }
};

// update reader
export const updateReader = async (update,id) => {
  let res;
  try {
    res = await userRequest.put(`/readers/${id}`, update);
    return res
  } catch (err) {
    return res
  }
};

// Forgot password for reader

export const updatePassordForReader = async (userInfo) => {
  let res;
  try {
    res = await userRequest.post(`/auth/mailto`, userInfo);
    return res
  } catch (error) {
    return res
  }
}

// Add author
export const addAuthor = async (author, dispatch) => {
  dispatch(addAuthorStart());
  let res;
  try {
    res = await userRequest.post(`/author/addauthor`, author);
    dispatch(addAuthorSuccess(res.data));
    return res
  } catch (err) {
    dispatch(addAuthorFailur());
    return res = err
  }
};

// Update author
export const updateAuthor = async (id, author, dispatch) => {
  dispatch(updateAuthorStart());
  let res;
  try {
    res = await userRequest.put(`/author/${id}`, author);
    const updateAuthor = res.data;
    dispatch(updateAuthorSuccess({ id, updateAuthor }));
    return res
  } catch (err) {
    dispatch(updateAuthorFailure());
    return res
  }
};

// Delete author
export const deleteAuthor = async (id, dispatch) => {
  dispatch(deleteAuthorStart());
  let res;
  try {
    res = await userRequest.delete(`/author/deleteauthor?id=${id}`);
    dispatch(deleteAuthorSuccess(id));
    return res
  } catch (err) {
    dispatch(deleteAuthorFailure());
    return res
  }
};

// Get categories
export const getCategories = async (dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await publicRequest.get("/category");
    dispatch(getCategorySuccess(res.data));
  } catch (err) {
    dispatch(getCategoryFailur());
  }
};

// Add category
export const addCategory = async (category, dispatch) => {
  dispatch(addCategoryStart());
  let res;
  try {
    res = await userRequest.post(`/category/addcategory`, category);
    dispatch(addCategorySuccess(res.data));
    return res
  } catch (err) {
    dispatch(addCategoryFailur());
    return res = err
  }
};

// Update Category
export const updateCategory = async (id, category, dispatch) => {
  dispatch(updateCategoryStart());
  let res;
  try {
    res = await userRequest.put(`/category/editcategory?id=${id}`, category);
    const updateCategory = res.data;
    dispatch(updateCategorySuccess({ id, updateCategory }));
    return res
  } catch (err) {
    dispatch(updateCategoryFailure());
    return res
  }
};

// Delete Category
export const deleteCategory = async (id, dispatch) => {
  dispatch(deleteCategoryStart());
  let res;
  try {
    res = await userRequest.delete(`/category/deletecategory?id=${id}`);
    dispatch(deleteCategorySuccess(id));
    return res
  } catch (err) {
    dispatch(deleteCategoryFailure());
    return res
  }
};

// Load media
export const loadMedia = async (dispatch) => {
  dispatch(getMediaStart());
  try {
    const res = await publicRequest.get("/media");
    dispatch(getMediaSuccess(res.data));
  } catch (err) {
    dispatch(getMediaFailur());
  }
};

// Get blog
export const getBlog = async (dispatch) => {
  dispatch(getBlogStart());
  try {
    const res = await publicRequest.get("/blog");
    dispatch(getBlogSuccess(res.data));
  } catch (err) {
    dispatch(getBlogFailur());
  }
};

// Add blog
export const addBlog = async (blog, dispatch) => {
  dispatch(addBlogStart());
  try {
    const res = await userRequest.post(`/blog/add`, blog);
    dispatch(addBlogSuccess(res.data));
  } catch (err) {
    dispatch(addBlogFailur());
  }
};

// Update blog
export const updateBlog = async (id, blog, dispatch) => {
  dispatch(updateBlogStart());
  try {
    const res = await userRequest.put(`/blog/${id}`, blog);
    const updateBlog = res.data;
    dispatch(updateBlogSuccess({ id, updateBlog }));
  } catch (err) {
    dispatch(updateBlogFailure());
  }
};

// Delete blog
export const deleteBlog = async (id, dispatch) => {
  dispatch(deleteBlogStart());
  try {
    await userRequest.delete(`/blog/${id}`);
    dispatch(deleteBlogSuccess(id));
  } catch (err) {
    dispatch(deleteBlogFailure());
  }
};

// Get admin
export const getAdmin = async (dispatch) => {
  dispatch(getAdminStart);
  try {
    const res = await userRequest.get("/user");
    dispatch(getAdminSuccess(res.data));
  } catch (err) {
    dispatch(getAdminFailur);
  }
};

// Add admin
export const addAdmin = async (user, dispatch) => {
  dispatch(addAdminStart());
  let res;
  try {
    res = await userRequest.post(`/auth/register`, user);
    dispatch(addAdminSuccess(res.data));
    return res
  } catch (err) {
    dispatch(addAdminFailur());
    return res
  }
};

// Delete admin
export const deleteAdmin = async (id, dispatch) => {
  // dispatch(deleteAdminStart());
  let res;
  try {
    res = await userRequest.delete(`/user/deleteuser?id=${id}`);
    // dispatch(deleteAdminSuccess(id));
    return res
  } catch (err) {
    // dispatch(deleteAdminFailure());
    return res
  }
};

// Get Slider
export const getSlider = async (dispatch) => {
  dispatch(getSliderStart());
  try {
    const res = await publicRequest.get("/sliders");
    dispatch(getSliderSuccess(res.data));
  } catch (err) {
    dispatch(getSliderFailur());
  }
};

// Add Slider
export const addSlider = async (slider, dispatch) => {
  dispatch(addSliderStart());
  try {
    const res = await userRequest.post(`/sliders/addslider`, slider);
    dispatch(addSliderSuccess(res.data));
  } catch (err) {
    dispatch(addSliderFailur());
  }
};

// Update Slider
export const updateSlider = async (id, slider, dispatch) => {
  dispatch(updateSliderStart());
  try {
    const res = await userRequest.put(`/sliders/editslider?id=${id}`, slider);
    const updateSlider = res.data;
    dispatch(updateSliderSuccess({ id, updateSlider }));
  } catch (err) {
    dispatch(updateSliderFailure());
  }
};

// Delete slider
export const deleteSlider = async (id, dispatch) => {
  dispatch(deleteSliderStart());
  try {
    await userRequest.delete(`/sliders/deleteslider?id=${id}`);
    dispatch(deleteSliderSuccess(id));
  } catch (err) {
    dispatch(deleteSliderFailure());
  }
};

// Get Tags
export const getTags = async (dispatch) => {
  dispatch(gettagStart());
  try {
    const res = await publicRequest.get("/tag");
    dispatch(gettagSuccess(res.data));
  } catch (err) {
    dispatch(gettagFailur());
  }
};

// Add Tag
export const addTag = async (tags, dispatch) => {
  dispatch(addtagStart());
  try {
    const res = await userRequest.post(`/tag/add`, tags);
    dispatch(addtagSuccess(res.data));
  } catch (err) {
    dispatch(addtagFailur());
  }
};

// Update Tags
export const updateTags = async (updateTags, dispatch) => {
  
  try {
    const res = await userRequest.post(`/tag/edit-tag`, updateTags);
    getTags(dispatch);
  } catch (err) {
    // console.log(err);
  }
};

//Get Collections
export const getCollection = async (dispatch) => {
  dispatch(getcollectionStart());
  try {
    const res = await publicRequest.get("/collection");
    dispatch(getcollectionSuccess(res.data));
    return res
  } catch (err) {
    dispatch(getcollectionFailur());
  }
};

// Add collection
export const addCollection = async (collection, dispatch) => {
  dispatch(addcollectionStart());
  let res;
  try {
    res = await userRequest.post(`/collection/addcollection`, collection);
    dispatch(addcollectionSuccess(res.data));
    return res
  } catch (err) {
    dispatch(addcollectionFailur());
    return res = err
  }
};

// Update collection
export const updateCollection = async (id, collection, dispatch) => {
  dispatch(updatecollectionStart());
  let res;
  try {
    res = await userRequest.put(`/collection/editcollection?id=${id}`, collection);
    const updateCollection = res.data;
    dispatch(updatecollectionSuccess({ id, updateCollection }));
    return res
  } catch (err) {
    dispatch(updatecollectionFailure());
    return res
  }
};

// Delete collection
export const deleteCollection = async (id, dispatch) => {
  dispatch(deletecollectionStart());
  let res;
  try {
    res = await userRequest.delete(`/collection/deletecollection?id=${id}`);
    dispatch(deletecollectionSuccess(id));
    return res
  } catch (err) {
    dispatch(deletecollectionFailure());
    return res
  }
};

// homepage setting
export const getHomepageSetting = async () => {
  try {
    const res = await userRequest.get("/homepagesetting");
    return res
  } catch (err) {

  }
};

export const updateHomepageSetting = async (id, siteSetting) => {
  try {
    const res = await userRequest.put(`/homepagesetting/edithomepage?id=${id}`, siteSetting);
  } catch (err) {
    const res = {status:"Failed"};
    return res
  }
};


// Get ads
export const getAds = async (dispatch) => {
  dispatch(getadStart());
  try {
    const res = await publicRequest.get("/ads");
    dispatch(getadSuccess(res.data));
    return res
  } catch (err) {
    dispatch(getadFailur());
  }
};

// Add Ads
export const addAds = async (ads, dispatch) => {
  dispatch(addadStart());
  let res;
  try {
    res = await userRequest.post(`/ads/addad`, ads);
    dispatch(addadSuccess(res.data));
    return res
  } catch (err) {
    dispatch(addadFailur());
    return res
  }
};

// Update ads
export const updateads = async (id, ad, dispatch) => {
  dispatch(updateadStart());
  let res;
  try {
    res = await userRequest.put(`/ads/editad?id=${id}`, ad);
    const updateAd = res.data;
    dispatch(updateadSuccess({ id, updateAd }));
    return res
  } catch (err) {
    dispatch(updateadFailure());
    return res
  }
};

// Delete ads
export const deleteAds = async (id, dispatch) => {
  dispatch(deleteadStart());
  let res;
  try {
    res = await userRequest.delete(`/ads/deletead?id=${id}`);
    dispatch(deleteadSuccess(id));
    return res
  } catch (err) {
    dispatch(deleteadFailure());
    console.log(res);
    return res
  }
};

export const updatereview = async (id, review, dispatch) => {
  let res;
  // dispatch(updateBookReviewStart());
  try {
    const res = await userRequest.put(`/bookReview/${id}`, review);
    const updateReview = res.data;
    // dispatch(updateBookReviewSuccess({ id, updateReview }));
    return res
  } catch (err) {
    // dispatch(updateBookReviewFailure());
    return res
  }
};

export const reviewMail = async (review) => {
  let res;
  try {
    const res = await userRequest.post(`/author/mailto/author/sendreview`, review);
    const updateReview = res.data;
    return res
  } catch (err) {
    return res
  }
};


// Get Page
export const getPages = async () => {
  let res;
  try {
    res = await userRequest.get(`/page/`);
    return res
  } catch (err) {
    console.log(err);
    return res
  }
};

// Get Single Page
export const getSinglePage = async (id) => {
  let res;
  try {
    res = await userRequest.get(`/page/find/${id}`);
    return res
  } catch (err) {
    console.log(err);
    return res
  }
};

// Add Page
export const addPage = async (page) => {
  let res;
  try {
    res = await userRequest.post(`/page/addpage`, page);
    return res
  } catch (err) {
    console.log(err);
    return res = err
  }
};

// Update page
export const updatePage = async (id, page ) => {
  let res;
  try {
    res = await userRequest.put(`/page/editpage?id=${id}`, page);
    const updatepage = res.data;
    return res
  } catch (err) {
    console.log(err);
    return res
  }
};

// Delete page
export const deletePage = async (id) => {
  let res;
  try {
    res = await userRequest.delete(`/page/deletepage?id=${id}`);
    return res
  } catch (err) {
    console.log(err);
    return res
  }
};