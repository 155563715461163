import React from 'react'
import { useEffect } from 'react';
import { userRequest } from '../util/requestMethod'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight,faCheck, faAngleDown, faTimes,faFileExcel} from "@fortawesome/free-solid-svg-icons"
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactPaginate from 'react-paginate';
import { getOrders, updateOrder } from '../util/api';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion'
import { updateNotificationSuccess } from '../redux/notificationRedux';
import bkash from '../img/bkash.png'
import nagad from '../img/nagad.png'
import axios from 'axios'



export function Orders() {
    const dispatch = useDispatch()
    const [orders, setorders] = useState([])
    const [dupliOrders, setdupliOrders] = useState([])
    const [loader, setLoader] = useState(false);
    const [orderPerPage, setorderPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0);
    const [searchedQuery, setsearchedQuery] = useState("");
    const pageVisited = pageNumber * orderPerPage;
    const options = {year: 'numeric', month: 'long', day: 'numeric' };
    const books = useSelector((state)=>state.book.books)
    let notification = useSelector((state)=> state.notification.notification)
    let currentUser = useSelector((state)=> state.userInfo.currentUser)
    

    const [smsGatewayInfo, setsmsGatewayInfo] = useState({})
    
    const getSMSGatewaySettings = async ()=>{
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/smsgateway/allsmsinfo`, {headers :{"token":`Bearer ${currentUser.accessToken}`}}).then((res)=>{
            setsmsGatewayInfo(res.data)
        })
    }

    useEffect(() => {
        getOrders().then((res)=>{
            setorders(res.data)
            setdupliOrders(res.data)
        })
        getSMSGatewaySettings()
    }, [dispatch])

    const handleSearch = (e) =>{
        setsearchedQuery(e.target.value)
    }

    const handleFilter = (e) =>{
        let filterStatus = e.target.value;
        let copyData = [...dupliOrders]
        if (filterStatus === "Shipped") {
            let data = copyData.filter(order=>{
                if (order.deliverystatus.includes(filterStatus)) {
                    return order
                }
            })
            setorders(data)
        }else if (filterStatus === "Pending") {
            let data = copyData.filter(order=>{
                if (order.deliverystatus.includes(filterStatus)) {
                    return order
                }
            })
            setorders(data)
        }else if (filterStatus === "Cancel") {
            let data = copyData.filter(order=>{
                if (order.deliverystatus.includes(filterStatus)) {
                    return order
                }
            })
            setorders(data);
        }else if (filterStatus === "all") {
            setorders(dupliOrders)
        }
      }

    const handleUpdateStatus = async (e, id, readerName, orderno, payment, readerPhone) => {
        setLoader(true);
        let update = {"deliverystatus": e.target.value}
        if (e.target.value === 'ReadyToShip') {
            const res = await axios.get(`https://bulksmsbd.net/api/smsapi?api_key=${smsGatewayInfo.apikey}&type=text&number=${readerPhone}&senderid=${smsGatewayInfo.sender}&message=Dear ${readerName},Your Order no ${orderno} is ready to ship. Please login to your reader accounts for further detail. Bookmark.com.bd/login`,{headers : {'Acess-Control-Allow-Origin':'*'}})
            if (res.data?.response_code === 202) {
                
            }else{
                
            }
        }else if(e.target.value === 'Shipped'){
            const res = await axios.get(`https://bulksmsbd.net/api/smsapi?api_key=${smsGatewayInfo.apikey}&type=text&number=${readerPhone}&senderid=${smsGatewayInfo.sender}&message=Dear ${readerName},Your Order no ${orderno} is delivered to the courier. Please login to your reader accounts for further detail. Bookmark.com.bd/login`,{headers : {'Acess-Control-Allow-Origin':'*'}})
            if (res.data?.response_code === 202) {
                
            }else{
                
            }
        }
        updateOrder(update, id).then((res)=>{
            if (res === undefined) {
                dispatch(updateNotificationSuccess({sms:"Order Update Failed",status:false}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                setLoader(false)
            } else {
                dispatch(updateNotificationSuccess({sms:"Order Update Success",status:true}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                getOrders().then((res)=>{
                    setorders(res.data)
                    setdupliOrders(res.data)
                })
                setLoader(false)
            }
        })
    }

    const handleTransectionStatus = async (e, id, readerName, orderno, payment, readerPhone) => {
        setLoader(true);
        let update = {"transectionstatus": e.target.value}
        if (e.target.value === 'Complete') {
            const res = await axios.get(`https://bulksmsbd.net/api/smsapi?api_key=${smsGatewayInfo.apikey}&type=text&number=${readerPhone}&senderid=${smsGatewayInfo.sender}&message=Dear ${readerName}.Your payment BDT ${payment} has been received for order no ${orderno}. Please log in to your reader account for further detail. Bookmark.com.bd/login`,{headers : {'Acess-Control-Allow-Origin':'*'}})
            if (res.data?.response_code === 202) {
                
            }else{
                
            }
        }else if(e.target.value === 'Failed'){
            const res = await axios.get(`https://bulksmsbd.net/api/smsapi?api_key=${smsGatewayInfo.apikey}&type=text&number=${readerPhone}&senderid=${smsGatewayInfo.sender}&message=Dear ${readerName}.Your payment BDT ${payment} has been failed for order no ${orderno}. Please log in to your reader account for further detail. Bookmark.com.bd/login`,{headers : {'Acess-Control-Allow-Origin':'*'}})
            if (res.data?.response_code === 202) {
                
            }else{
                
            }
        }
        updateOrder(update, id).then((res)=>{
            if (res === undefined) {
                dispatch(updateNotificationSuccess({sms:"Order Update Failed",status:false}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                setLoader(false)
            } else {
                dispatch(updateNotificationSuccess({sms:"Order Update Success",status:true}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                getOrders().then((res)=>{
                    setorders(res.data)
                    setdupliOrders(res.data)
                })
                setLoader(false)
            }
        })
    }

    const displayOrder = orders.filter(order => {
        if (searchedQuery === "") {
          return order
        }
        else if (order.reader.fullname.toLowerCase().includes(searchedQuery.toLowerCase())) {
          return order
        }
      }).slice(pageVisited, pageVisited + orderPerPage)
        .map((order, index) => {
            let date = new Date(order.createdAt);
          return (
            <Accordion.Item eventKey={index} key={order._id}>
                <Accordion.Header >
                    <div className="table-row">
                        <li>
                            {order.transaction_id}
                        </li>
                        <li>
                            {order.paymentnumber}
                        </li>
                        <li>
                            {order.paymentMode === 'nagad'? <img src={nagad} alt=""/> : order.paymentMode === 'bkash'? <img src={bkash} alt=""/> : ""}
                        </li>
                        <li>
                            {order.reader.fullname}
                        </li>
                        <li>
                            {order.amount}
                        </li>

                        <li>{date.toDateString()}</li>

                        <li>{order.address?.phone}</li>

                        <li>
                            <p className={order.transectionstatus === 'Complete'? "status success" : order.transectionstatus === 'Failed'? "status reject" : "status pending"}>{order.transectionstatus === 'Complete'? "Complete" : order.transectionstatus === 'Failed'? "Failed" : "Pending"}</p>
                        </li>

                        <li>
                            <p className={order.deliverystatus === 'Shipped'? "status success" : order.deliverystatus === 'Cancel'? "status reject" : "status pending"}>{order.deliverystatus === 'Shipped'? "Shipped" : order.deliverystatus === 'Cancel'? "Canceled" : order.deliverystatus === 'Delivered'? "Delivered" : order.deliverystatus === 'ReadyToShip'? "Ready To Ship" : "Processing"}</p>
                        </li>

                    </div>

                 </Accordion.Header>

                 <Accordion.Body>
                    <ul className='order-products'>
                        <div className='d-flex justify-content-between align-items-center'>
                            
                            <div>
                                <h5 className='mb-2'>Shipping Address:</h5>
                                <p>{`${order.address.address},${order.address.city},${order.address.state}`}</p>
                            </div>

                            <div>
                                <div className='form-group d-flex align-items-center justify-content-end mt-3 mb-3'>
                                    <label>Payment Status:</label>
                                    <select className='form-control w-auto' onChange={(e)=>handleTransectionStatus(e,order._id, order.reader.fullname, order.transaction_id, order.amount, order.address?.phone)} style={{marginLeft:"5px",appearance:"auto",borderRadius:"0"}}>
                                        {<option value={order.transectionstatus}>{order.transectionstatus}</option>}
                                        <option value="">Select Status</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Complete">Received</option>
                                        <option value="Failed">Failed</option>
                                    </select>
                                </div>
                                <div className='form-group d-flex align-items-center justify-content-end mt-3 mb-3'>
                                    <label>Delivery Status:</label>
                                    <select className='form-control w-auto' onChange={(e)=>handleUpdateStatus(e,order._id, order.reader.fullname, order.transaction_id, order.amount, order.address?.phone)} style={{marginLeft:"5px",appearance:"auto",borderRadius:"0"}}>
                                        {<option value={order.deliverystatus}>{order.deliverystatus}</option>}
                                        <option value="">Select Status</option>
                                        <option value="Pending">Processing</option>
                                        <option value="ReadyToShip">Ready To Ship</option>
                                        <option value="Shipped">Shipped</option>
                                        <option value="Delivered">Delivered</option>
                                        <option value="Cancel">Cancel</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            order.products.map((product)=>(
                                books.map((book)=>(
                                    book._id === product.productId? 
                                        <li className='single-ordered-product'>
                                            <div className='order-img'>
                                                <img src={book.cover? `${process.env.REACT_APP_BASE_URL}/api/alluploads/${book.publisherId}/${book.cover}` : `${process.env.REACT_APP_BASE_URL}/api/alluploads/default-book.png`} className="w-100" alt='' />
                                            </div>
                                            <div className='order-info'>
                                                <h5>{book.nameInBangla} - {book.authorBnName}</h5>
                                                <p>Publisher: <b>{book.publisherBnName}</b></p>
                                                <p>Quantity: <b>{product.quantity}</b></p>
                                            </div>
                                            <div className='order-price-info'>
                                                <p>Cover Price : <b>৳{book.coverPrice}</b></p>
                                                <p>Sale Price : <b>৳{product.price}</b></p>
                                                <p>Discount : <b>{Math.ceil(book.discount)}%</b></p>
                                            </div>
                                        </li>
                                    :
                                    ""
                                ))
                            ))
                        }
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
          );
        });

    const pageCount = Math.ceil(orders.length / orderPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    return (
        <>
        {notification.status === true?
        <div className='notification-bar success'>
          <FontAwesomeIcon icon={faCheck} />
          <p>{notification.sms}</p>
        </div>
        : notification.status === false?
        <div className='notification-bar failed'>
          <FontAwesomeIcon icon={faTimes} />
          <p>{notification.sms}</p>
        </div>
        :
        ""
      }
        <div className="content-area-inner list-page">
            <div className="container-fluid">
                <div className="breadcumb">
                <Link to="/">Dashboard</Link>
                <FontAwesomeIcon icon={faAngleRight} />
                <Link to="/">Order Lists</Link>
                </div>
                <div className="heading">
                <h1>Order List</h1>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="tab-btns mb-4">
                                <div className="tab-btn active">All Items ({orders.length})</div>
                                <div className="tab-btn">Drafts (0)</div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="group-btn">
                                
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-wrap">
                            <div className="theme-card">
                                <div className="theme-card-header">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="searchbar">
                                                <div className="form-group">
                                                    <input type="text" placeholder="Search" onChange={(e)=>handleSearch(e)}/>
                                                    <button type="submit" className="btn">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4523 11.4716H12.6623L12.3823 11.2016C13.5823 9.80157 14.2023 7.89157 13.8623 5.86157C13.3923 3.08157 11.0723 0.861573 8.27229 0.521573C4.04229 0.00157288 0.482286 3.56157 1.00229 7.79157C1.34229 10.5916 3.56229 12.9116 6.34229 13.3816C8.37229 13.7216 10.2823 13.1016 11.6823 11.9016L11.9523 12.1816V12.9716L16.2023 17.2216C16.6123 17.6316 17.2823 17.6316 17.6923 17.2216C18.1023 16.8116 18.1023 16.1416 17.6923 15.7316L13.4523 11.4716ZM7.45229 11.4716C4.96229 11.4716 2.95229 9.46157 2.95229 6.97157C2.95229 4.48157 4.96229 2.47157 7.45229 2.47157C9.94229 2.47157 11.9523 4.48157 11.9523 6.97157C11.9523 9.46157 9.94229 11.4716 7.45229 11.4716Z" fill="#1D1D1D"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="list-filter d-flex justify-content-end">
                                                <div className="form-group">
                                                    <select className="form-control" onChange={handleFilter}>
                                                        <option value="all">All</option>
                                                        <option value="Shipped">Shipped</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Cancel">Canceled</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <select type="number" className="form-control" onChange={(e)=>{setorderPerPage(e.target.value)}}>
                                                        <option value="10">Book per page</option>
                                                        <option value="20">20 per page</option>
                                                        <option value="30">30 per page</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loader? 
                                <BeatLoader color={"#0D99FF"} loading={loader} size={15}/>
                                :
                                <div className="theme-card-body">
                                    <div className="table order-table">
                                        <ul className="custom-table-header">
                                            <li>TRX ID</li>
                                            <li>Payment Number</li>
                                            <li>Payment Mode</li>
                                            <li>Customer Name</li>
                                            <li>Order Value</li>
                                            <li>Order Place Time</li>
                                            <li>Contact Number</li>
                                            <li>Payment Status</li>
                                            <li>Delivery Status</li>
                                        </ul>
                                        <ul className="custom-table-body">
                                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                                {displayOrder}
                                            </Accordion>
                                        </ul>
                                        <ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"pagination"}
                                            activeClassName={"actvie-page"}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="tab-wrap">
                            {/* <div className="theme-card">
                                Draft
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
