import React, {useState,useEffect} from 'react'
// import { createBrowserHistory } from "history";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes} from "@fortawesome/free-solid-svg-icons"
import MediaLibrary from "./MediaLibrary"
import { updateSetting, updateSmsGateway } from '../util/api';
import { userRequest } from '../util/requestMethod';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function SmsGateway() {
  // const history = createBrowserHistory({ forceRefresh: true });
  const [smsGatewayInfo, setsmsGatewayInfo] = useState({});
  const id = smsGatewayInfo._id;
  const navigate = useNavigate()

  const getSmsGatewayInfo = async () => {
    try {
      const res = await userRequest.get("/smsgateway/allsmsinfo");
      console.log(res.data);
      setsmsGatewayInfo(res.data);
    } catch (error) {
      const res = "Something went wrong";
    }
  };

  useEffect(() => {
    getSmsGatewayInfo();
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setsmsGatewayInfo({ ...smsGatewayInfo, [name]: value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    updateSmsGateway(id, smsGatewayInfo)
    .then(() => navigate('/smsgateway'));
    // console.log(siteInfo);
  };

  return (
    <>
    <div className="content-area-inner">
      <div className="container-fluid">
        <div className="pagination">
          <Link to="/">Dashboard</Link>
          <FontAwesomeIcon icon={faAngleRight} />
          <Link to="/">Sms Gateway</Link>
        </div>
        <div className="heading">
          <h1>Sms Gateway</h1>
        </div>
        <form className="theme-form" onSubmit={handleClick}>
          <div className="row">
            <div className="col-lg-12">
              <div className="theme-card">
                <div className="theme-card-body">
                    <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Api Key</label>
                            <input type="text" tabIndex="1" value={smsGatewayInfo.apikey} name="apikey" className="form-control" onChange={handleChange} placeholder="Input Here"/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Username</label>
                            <input type="text" tabIndex="2" value={smsGatewayInfo.username} name="username" className="form-control" onChange={handleChange} placeholder="Input Here"/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>password</label>
                            <input type="password" tabIndex="3" value={smsGatewayInfo.password} name="password" className="form-control" onChange={handleChange} placeholder="Input Here"/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Sender</label>
                            <input type="text" tabIndex="4" value={smsGatewayInfo.sender} name="sender" className="form-control" onChange={handleChange} placeholder="Input Here"/>
                          </div>
                        </div>
                    <div>
                </div>
              </div>
            </div>
              </div>
              <div className="group-btn">
                  <button className="btn theme-btn" type="submit">Update</button>
                  {/* <button className="btn theme-btn-alt">Cancel</button> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </>
  )
}
