import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes} from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from 'react-redux';
import { addMenu, getMenu, getPages } from '../util/api';
import SingleMenuItem from './SingleMenuItem';
import Submenu from './Submenu';
import OrpharSubmenuItem from './OrphanSubmenuItem';

export default function MenuSettings() {
  const dispatch = useDispatch()
  const menues = useSelector((state)=> state.menu.menues)
  const [menuItem, setmenuItem] = useState(null)
  const [submenu, setsubmenu] = useState(null)
  const [pages, setpages] = useState([])

  useEffect(() => {
    getMenu(dispatch)
    getPages().then((res)=>{
        if (res === undefined) {
            console.log("error");
        } else {
            setpages(res.data)
        }
    })
  }, [])
  
  const handleMenuAdd = (e) => {
      e.preventDefault();
      addMenu({item:menuItem, type:"mainmenu"}, dispatch)
      setmenuItem("")
  }
  const handleSubmenu = (e) => {
      e.preventDefault();
      addMenu({item:submenu, type:"submenu", isActive:true}, dispatch)
      setmenuItem("")
  }


  return (
    <div className="content-area-inner">
        <div className="container-fluid">
            <div className="pagination">
                <Link to="/">Dashboard</Link>
                <FontAwesomeIcon icon={faAngleRight} />
                <Link to="/">Menu setting</Link>
            </div>
            <div className="heading">
                <h1>Menu Settings</h1>
            </div>
            <form className="theme-form mb-5" onSubmit={handleMenuAdd}>
                <div className="row"> 
                    <div className="col-lg-6">
                    
                    </div>
                    <div className="col-lg-6">
                        <div className="form-gropup d-flex">
                            <input type="text" className="form-control" placeholder="Enter menu item" value={menuItem} onChange={(e)=>setmenuItem(e.target.value)} />
                            <button type="submit" className="btn theme-btn mb-0" style={{borderRadius:"3px"}}>Add</button>
                        </div>
                    </div>
                </div>
            </form>
            <h4 className='theme-card-heading'>Type: Main Menu</h4>
            <div className="theme-card menu-settings">
                <div className="theme-card-body">
                    <ul className="table-header">
                        <li>Status</li>
                        <li>Name</li>
                        <li>Route Type</li>
                        <li>Route</li>
                        <li>Menu type</li>
                        <li>Place</li>
                        <li>Parents Menu</li>
                        <li>Action</li>
                    </ul>
                    <ul className="table-body">
                        {menues.filter(menu => menu.type === 'mainmenu').map(menu => (
                            <li key={menu._id} className="menuItem"><SingleMenuItem menu={menu} page={pages}/></li>
                        ))}
                    </ul>
                </div>
            </div>
            <form className="theme-form mb-5" onSubmit={handleSubmenu}>
                <div className="row"> 
                    <div className="col-lg-6">
                    
                    </div>
                    <div className="col-lg-6">
                        <div className="form-gropup d-flex">
                            <input type="text" className="form-control" placeholder="Enter submenu" value={submenu} onChange={(e)=>setsubmenu(e.target.value)} />
                            <button type="submit" className="btn theme-btn mb-0" style={{borderRadius:"3px"}}>Add</button>
                        </div>
                    </div>
                </div>
            </form>
            <h4 className='theme-card-heading'>Type: Submenu</h4>
            <div className="theme-card menu-settings">
                {menues.filter(menuu => menuu.type === 'submenu').map((menuu)=>(
                    <Submenu menuu={menuu} page={pages}/>
                ))}
            </div>
        </div>
    </div>
  )
}
