import { createSlice } from "@reduxjs/toolkit";

export const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    collections: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getcollectionStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getcollectionSuccess: (state, action) => {
      state.isFetching = false;
      state.collections = action.payload;
    },
    getcollectionFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addcollectionStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addcollectionSuccess: (state, action) => {
      state.isFetching = false;
      state.collections.unshift(action.payload);
    },
    addcollectionFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updatecollectionStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updatecollectionSuccess: (state, action) => {
      state.isFetching = false;
      state.collections[
        state.collections.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateCollection;
    },
    updatecollectionFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deletecollectionStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deletecollectionSuccess: (state, action) => {
      state.isFetching = false;
      state.collections.splice(
        state.collections.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deletecollectionFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { getcollectionStart, getcollectionSuccess, getcollectionFailur, addcollectionStart, addcollectionSuccess, addcollectionFailur, updatecollectionStart, updatecollectionSuccess, updatecollectionFailure,deletecollectionStart, deletecollectionSuccess, deletecollectionFailure } =
  collectionSlice.actions;

export default collectionSlice.reducer;
