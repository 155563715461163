import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import threeDot from "../img/Frame 179.png";



const DashboardPeiChart = () => {

  const [paiChat, setPaiChat] = useState({
              
    series: [44, 55, 50, 33, 34,],     
     options: {
       labels: ["Horror", "Action", "SciFi", "Drama", "Kobita"],
       colors:["#325594", "#B3D5FF", "#0D99FF", "#EAF1FF", "#C3D9FF"],
      title:{
        text: "Category",
        opacity: 20,
        align: "right"
      },
      chart: {
        width: 380,
        type: 'donut',
      },

      plotOptions: {    
            pie:{
              expandOnClick: false,
              donut:{
                 size: "50px",
                 labels: {
                    show: true,
                 },
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: "24px",
                }
              }
            }
      },
      
      dataLabels: {
        // enabled: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: true
          }
        }

      }],
      legend: {
        position: 'right',
        offsetY: 0,
        height: 230,
      },

     
    },
  
})
  

  return (
    <div className="p-2">
    <div className="d-flex justify-content-between">
      <p className="fw-bold">Total Sales</p>

      <div className="d-flex justify-content-between">
        <button className="three-dot-button px-2 revenue-button-text">
          Weekly
        </button>

        <button className="three-dot-button px-2 revenue-button-text">
          Monthly
        </button>

        <button className="three-dot-button px-2 revenue-button-text">
          Yearly
        </button>

        <button className="three-dot-button">
          <img src={threeDot} alt="" />
        </button>
      </div>

    </div>

    <div className="">

      <div className="mt-5">
        {/* <ReactApexChart options={paiChat.options} series={paiChat.series} type="donut" width={380} /> */}
      <Chart 

      series={paiChat.series}
     
      options={paiChat.options}
      
      type="donut" width={380} 
  
      />

      </div>

    </div>
  </div>
  );

};

export default DashboardPeiChart;