import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faAngleRight, faAngleDown, faTimes, faInfo, faInfoCircle, faCheck} from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MediaLibrary from './MediaLibrary';
import { addPage } from '../util/api';
import { updateNotificationSuccess } from '../redux/notificationRedux';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';

export function AddPage(props) {
    const [description, setdescription] = useState()
    const [inputs, setInputs] = useState({});
    const [url, seturl] = useState("");
    const [imgurl, setimgUrl] = useState(null);
    const [modal, setModal] = useState(false)
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    let notification = useSelector((state)=> state.notification.notification)

    const handleChange = (e) =>{
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
        seturl(slugify(inputs.title))
    }
    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 3000);
        let page = { ...inputs};
        if (imgurl) {
            page = { ...inputs, image: imgurl, url: url, description:description};
            addPage(page)
            .then((res) => {
                if (res.response?.data?.keyPattern) {
                    if (res.response?.data?.keyPattern?.url === 1) {
                      dispatch(updateNotificationSuccess({sms:"Url already exist",status:false}))
                      setTimeout(() => {
                      dispatch(updateNotificationSuccess({sms:"",status:null}))
                      }, 2000);
                    } else {
                      dispatch(updateNotificationSuccess({sms:"Page Added Failed",status:false}))
                      setTimeout(() => {
                      dispatch(updateNotificationSuccess({sms:"",status:null}))
                      }, 2000);
                    }
                } else {
                    dispatch(updateNotificationSuccess({sms:"Page Added Success",status:true}))
                    navigate('/pagelist')
                }
            });
            
        } else {
            page = { ...inputs, image: "", url: url, description:description};
            addPage(page)
            .then((res) => {
                if (res.response?.data?.keyPattern) {
                    if (res.response?.data?.keyPattern?.url === 1) {
                      dispatch(updateNotificationSuccess({sms:"Url already exist",status:false}))
                      setTimeout(() => {
                      dispatch(updateNotificationSuccess({sms:"",status:null}))
                      }, 2000);
                    } else {
                      dispatch(updateNotificationSuccess({sms:"Page Added Failed",status:false}))
                      setTimeout(() => {
                      dispatch(updateNotificationSuccess({sms:"",status:null}))
                      }, 2000);
                    }
                } else {
                    dispatch(updateNotificationSuccess({sms:"Page Added Success",status:true}))
                    navigate('/pagelist')
                }
            });
        }
    }
    const handleRemoveImg = (e) =>{
        e.preventDefault()
        setimgUrl(null);
    }

    const uploadImg = (childData) =>{
        setModal(!modal)
        setimgUrl(childData)
    }

    return (
        <>
          {notification.status === true?
            <div className='notification-bar success'>
              <FontAwesomeIcon icon={faCheck} />
              <p>{notification.sms}</p>
            </div>
            : notification.status === false?
            <div className='notification-bar failed'>
              <FontAwesomeIcon icon={faTimes} />
              <p>{notification.sms}</p>
            </div>
            :
            ""
          }
        {modal? <div className="floating-media">
        <button className="close-modal" onClick={()=>setModal(!modal)}><FontAwesomeIcon icon={faTimes}/></button>
        <MediaLibrary className="author" uploadImg={uploadImg}/>
        </div> : ""}
        <div className="content-area-inner">
          <div className="container-fluid">
            <div className="pagination">
              <Link to="/">Dashboard</Link>
              <FontAwesomeIcon icon={faAngleRight} />
              <Link to="/">Add Page</Link>
            </div>
            <div className="heading">
              <h1>Add Page</h1>
            </div>
            <form className="theme-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-8">
                  <div className="theme-card">
                    <div className="theme-card-body">
                        <h4 className="theme-card-heading">Page Information</h4>
                        <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Title <sup style={{color:"red"}}>*</sup></label>
                                <input type="text" tabIndex="1" name="title" className="form-control" placeholder="Input Here" required onChange={handleChange} autoComplete="off"/>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Url <sup style={{color:"red"}}>*</sup></label>
                                <input type="text" tabIndex="2" name="url" value={url} className="form-control" placeholder="Input Here" required onChange={handleChange} autoComplete="off"/>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Excerpt</label>
                                <input type="text" tabIndex="1" name="excerpt" className="form-control" placeholder="Input Here" onChange={handleChange} autoComplete="off"/>
                              </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Template</label>
                                    <select name="template" tabIndex="3" className="form-control" onChange={handleChange}>
                                    <option value="">Select Template</option>
                                    <option value="default">Default</option>
                                    </select>
                                    <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Select Status</label>
                                        <select className="form-control" tabIndex="4" name="status" type="number" onChange={handleChange} required>
                                        <option >Select Status</option>
                                        <option value="true">Published</option>
                                        <option value="false">Unpublished</option>
                                    </select>
                                    <span style={{background:"#D9D9D9",border:"1px solid #D9D9D9"}}><FontAwesomeIcon icon={faAngleDown} /></span>
                                </div>
                            </div>
                        <div>
                    </div>
                  </div>
                </div>
                  </div>
                  <div className="theme-card">
                    <div className="theme-card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Description <sup style={{color:"red"}}>*</sup></label>
                            {/* <textarea placeholder="Your desccription here" className="form-control" style={{height:"200px"}}></textarea> */}
                            <CKEditor
                                editor={ ClassicEditor }
                                data=""
                                onReady={ editor => {
                                    // console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setdescription(data)
                                } }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-btn">
                      {/* <button className="btn theme-btn-alt">Submit and Add Another</button> */}
                      <button className="btn theme-btn" tabIndex="23" type="submit">Submit</button>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="theme-card">
                    <div className="theme-card-body">
                            {imgurl? <button className="btn close" onClick={handleRemoveImg}><FontAwesomeIcon icon={faTimes} /></button> : ""}
                            {imgurl? <img src={`${process.env.REACT_APP_BASE_URL}/api/alluploads/${imgurl}`} className="w-100" onClick={uploadImg} /> : <div className="form-group upload-img" onClick={uploadImg}>
                          <label>
                          <svg width="72" height="57" viewBox="0 0 72 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M68.0057 10.3334V7.00004H60.8858V10.3334H56.3326L52.7727 0.333374H19.1595L15.5995 10.3334H0.366455V57H71.5657V10.3334H68.0057ZM64.4458 50.3334H7.48638V17H20.733L24.293 7.00004H47.6392L51.1992 17H64.4458V50.3334Z" fill="#E1E1E1"/>
                          <path d="M35.9661 17C32.7977 17 29.7004 17.8797 27.066 19.528C24.4315 21.1762 22.3782 23.5189 21.1657 26.2597C19.9532 29.0006 19.636 32.0166 20.2541 34.9264C20.8722 37.8361 22.398 40.5088 24.6384 42.6066C26.8788 44.7044 29.7333 46.133 32.8408 46.7118C35.9484 47.2906 39.1694 46.9935 42.0966 45.8582C45.0239 44.7229 47.5258 42.8003 49.2861 40.3336C51.0464 37.8668 51.986 34.9667 51.986 32C51.9812 28.0231 50.2919 24.2104 47.2887 21.3983C44.2854 18.5862 40.2134 17.0044 35.9661 17ZM35.9661 40.3333C34.2059 40.3333 32.4852 39.8446 31.0216 38.9289C29.558 38.0132 28.4173 36.7117 27.7437 35.189C27.0701 33.6663 26.8938 31.9908 27.2372 30.3742C27.5806 28.7577 28.4283 27.2729 29.6729 26.1074C30.9176 24.942 32.5034 24.1483 34.2298 23.8268C35.9563 23.5052 37.7457 23.6703 39.372 24.301C40.9982 24.9317 42.3882 25.9998 43.3661 27.3702C44.3441 28.7407 44.866 30.3518 44.866 32C44.866 34.2101 43.9284 36.3298 42.2593 37.8926C40.5903 39.4554 38.3265 40.3333 35.9661 40.3333Z" fill="#E1E1E1"/>
                          </svg>
                          Click here to upload Images
                          </label>
                          <div className="preview-img">
                          <img className="w-100"/>
                          </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        </>
      )
}
