import React, { useEffect, useState } from "react";
import threeDot from "../img/Frame 179.png";
import { getOrders } from "../util/api";
import ReactPaginate from "react-paginate";

const DashboardLatestOrders = () => {
  const [orders, setorders] = useState([]);
  const [orderPerPage, setorderPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * orderPerPage;

  
  useEffect(() => {
    getOrders().then((res) => {
      setorders(res.data);
    });
  }, []);

  const displayOrder = orders
    .slice(pageVisited, pageVisited + orderPerPage)
    .map((order) => {
      let date = new Date(order.createdAt);
      return (
        <tr key={order._id}>
          <th scope="row">{order.transaction_id}</th>
          <td>{order.reader.fullname}</td>
          <td> <span className="ms-3">{order.products[0].quantity}</span> </td>
          <td>{Math.ceil(order.amount)}</td>
          <td>{date.toDateString()}</td>
          <td>{order.address.city}</td>
          <td>
            {order.deliverystatus === "Shipped" ? (
              <p className="Shipped-text-set text-center py-1 fw-bold">
                Shipped
              </p>
            ) : order.deliverystatus === "Cancel" ? (
              <p className="text-center canceled-text-set  py-1 fw-bold">
                Canceled
              </p>
            ) : order.deliverystatus === "ReadyToShip" ? (
              <p className="Shipped-text-set text-center py-1 fw-bold">
                Processing
              </p>
            ) : order.deliverystatus === "Delivered" ? (
              <p className="Shipped-text-set text-center py-1 fw-bold">
                Delivered
              </p>
            ) : (
              <p className="text-center pending-text-set py-1 fw-bold">
                Pending
              </p>
            )}
          </td>
        </tr>
      );
    });


  const pageCount = Math.ceil(orders.length / orderPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="p-2">
      <div className="">
        {/* headline */}  
        <div className="d-flex justify-content-between">
          <p className="fs-5 fw-bold"> Latest orders </p>
          <button className="three-dot-button">
            <img src={threeDot} alt="" />
          </button>
        </div>
      </div>

      {/* table */}

      <div className="mt-3 ">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Invoice</th>
              <th scope="col">Customer ID</th>
              <th scope="col">Order Units</th>
              <th scope="col">Order Value</th>
              <th scope="col">Order Place Time</th>
              <th scope="col">Address</th>
              <th scope="col">Status</th>
            </tr>
          </thead>

          <tbody className="table-text-set">
            {displayOrder}

            {/* <tr>
             <th scope="row">BK526465435</th>
             <td>JamilSUjon</td>
             <td>20</td>
             <td>2000</td>
             <td>12:00, 25 Aug 2022</td>
             <td>Mirpur 12, Dhaka...</td>
             <td>              
               <p className="Shipped-text-set text-center py-1 fw-bold">
                 Shipped
               </p>
             </td>
           </tr> */}

            {/* <tr className="event-table-row">
             <th scope="row">BK526465435</th>

             <td>JamilSUjon</td>
             <td>20</td>
             <td>2000</td>
             <td>12:00, 25 Aug 2022</td>
             <td>Mirpur 12, Dhaka...</td>
             <td>
               <p className="text-center canceled-text-set  py-1 fw-bold">
                 Canceled
               </p>
             </td>
           </tr>

           <tr>
             <th scope="row">BK526465435</th>
             <td>JamilSUjon</td>
             <td>20</td>
             <td>2000</td>
             <td>12:00, 25 Aug 2022</td>
             <td>Mirpur 12, Dhaka...</td>

             <td>
               <p className="text-center pending-text-set py-1 fw-bold">
                 Pending
               </p>
             </td>
           </tr> */}
          </tbody>
        </table>
      </div>

      <div>
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination"}
          activeClassName={"actvie-page"}
        />
      </div>

    </div>
  );
};

export default DashboardLatestOrders;
