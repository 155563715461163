import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faAngleRight, faAngleDown, faTimes, faEdit, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons"
import { deleteMenu, updateMenu } from '../util/api';

export default function SingleMenuItem({ menu, page }) {
    const dispatch = useDispatch()
    const [isEditing, setisEditing] = useState(false)
    const menues = useSelector((state) => state.menu.menues)
    const [inputs, setinputs] = useState({})
    const [singleMenu, setsingleMenu] = useState(menu)
    const [route, setroute] = useState(singleMenu.routeType)
    const categories = useSelector((state) => state.category.categories);
    const collections = useSelector((state) => state.collection.collections);

    const handleChange = (e) => {
        setsingleMenu((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        })
    }

    const handleEdit = (e) =>{
        e.preventDefault()
        updateMenu(singleMenu._id, singleMenu, dispatch)
        setisEditing(!isEditing)
    }

    const handleDelete = (id) =>{
        deleteMenu(id, dispatch)
    }

    const handleSetRoute = (e) =>{
        console.log(e.target.value);
        setroute(e.target.value);
        handleChange(e)
    }

    return (
        <form onSubmit={handleEdit}>
            <select className="form-control" onChange={handleChange} name="isActive" disabled={isEditing? false : true} required>
                {singleMenu.isActive ? <option value="true">Active</option> : <option value="false">Inactive</option>}
                <option value="true">Active</option>
                <option value="false">Inactive</option>
            </select>
            <input className="form-control" type="text" value={singleMenu.item} name="item" onChange={handleChange} disabled={isEditing? false : true} required/>
            <select className="form-control" onChange={(e) => handleSetRoute(e)} name="routeType" disabled={isEditing? false : true} required>
                <option value="page">Select Type</option>
                {singleMenu.routeType === 'page'? <option value="page">Page</option> : singleMenu.routeType === 'category'? <option value="category">Category</option> : singleMenu.routeType === 'url'? <option value="url">Url</option> : <option value="">Select Type</option>}
                <option value="page">Page</option>
                <option value="category">Category</option>
                <option value="url">Url</option>
                <option value="submenu">Submenu</option>
                <option value="collection">Collection</option>
            </select>
            {route === null? (
                <select className="form-control" name="route" onChange={handleChange} disabled={isEditing? false : true} required>
                    
                </select>
            ): route === 'page'? (
                <select className="form-control" name="route" onChange={handleChange} disabled={isEditing? false : true} required>
                    {page.map((p)=>(p._id === singleMenu.route? <option value={singleMenu.route}>{p.title}</option> : ""))}
                    <option value="">Select Page</option>
                    {page?.filter(p => p.status === true).map((p)=> (
                        <option value={`/page/${p.url}`} key={p._id}>{p.title}</option>
                    ))}
                </select>
            ) : route === 'category'? (
                <select className="form-control" name="route" onChange={handleChange} disabled={isEditing? false : true} required>
                    {categories.map((cate)=>(cate.slug === singleMenu.route? <option value={singleMenu.route}>{cate.nameinBangla}</option> : ""))}
                    <option value="">Select Category</option>
                    {categories.filter(cate => cate.status === 0 && cate.categoryType === 'book').map((cate)=> (
                        <option value={`${cate.slug? cate.slug : cate._id}`} key={cate._id}>{cate.nameinBangla}</option>
                    ))}
                </select>
            ) : route === 'submenu'? (
                <select className="form-control" name="route" onChange={handleChange} disabled={isEditing? false : true} required>
                    {menues.map((menu)=>(menu._id === singleMenu.route? <option value={singleMenu.route}>{menu.item}</option> : ""))}
                    <option value="">Select Submenu</option>
                    {menues.filter(menu => menu.isActive === true && menu.type === 'submenu').map((menu)=> (
                        <option value={menu._id} key={menu._id}>{menu.item}</option>
                    ))}
                </select>
            ) : route === 'collection'? (
                <select className="form-control" name="route" onChange={handleChange} disabled={isEditing? false : true} required>
                    {collections.map((collection)=>(collection.slug === singleMenu.route? <option value={singleMenu.route}>{collection.title}</option> : ""))}
                    <option value="">Select Collection</option>
                    {collections.filter(collection => collection.status === 0).map((collection)=> (
                        <option value={collection.slug} key={collection._id}>{collection.title}</option>
                    ))}
                </select>
            ) : (
                <input type="text" className="form-control" name="route" onChange={handleChange} placeholder="Url" disabled={isEditing? false : true} value={singleMenu.route} required/>
            )}
            <select className="form-control" name="type" onChange={handleChange} disabled={isEditing? false : true} required>
                {singleMenu.type === undefined ? <option value="">Select Type</option> : singleMenu.type === 'mainmenu'? <option value="mainmenu">Main menu</option> : singleMenu.type === 'submenu'? <option value="submenu">Sub menu</option> : <option value="submenuitem">Sub menu item</option>}
                <option value="mainmenu">Main menu</option>
                <option value="submenu">Submenu</option>
                <option value="submenuitem">Sub menu item</option>
            </select>
            {singleMenu.type === 'submenuitem'? 
                (<select className="form-control" name="place" onChange={handleChange} disabled required>
                    <option value="">Select menu place</option>
                </select>) : 
                (<select className="form-control" name="place" onChange={handleChange} disabled={isEditing? false : true} required>
                    {singleMenu.place === undefined ? <option value="">Select menu place</option> : singleMenu.place === 'topbar'? <option value="topbar">Top Bar</option> : singleMenu.place === 'footer'? <option value="footer">Footer</option> : ""}
                    <option value="topbar">Top Bar</option>
                    <option value="footer">Footer</option>
                </select>)
            }
            {singleMenu.type === 'submenuitem'? 
            (
                <select className="form-control" name="parentId" onChange={handleChange} disabled={isEditing? false : true} required>
                    {singleMenu.parentId === undefined? <option value="">Select Parent</option> : <option value={singleMenu.parentId}>{menues.filter(menu => menu._id === singleMenu.parentId).map((menu)=> menu.item)}</option>}
                    {menues.filter(menu => menu.type === 'submenu' && menu._id !== singleMenu._id).map(menu => (
                        <option value={menu._id}>{menu.item}</option>
                    ))}
                </select>
            ) : 
            (
                <select className="form-control" name="parentId" onChange={handleChange} disabled required>
                    <option value="">Select Parent</option>
                </select>
            )}
            {isEditing ? <button type='submit'><FontAwesomeIcon icon={faCheck}/></button> : <a><FontAwesomeIcon icon={faEdit} onClick={()=>setisEditing(!isEditing)} /></a>}
            <a><FontAwesomeIcon icon={faTrash} onClick={()=> handleDelete(singleMenu._id)} /></a>
        </form>
    )
}
